import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';
import Title from './Title';
import axios from 'axios';
import configData from './Config';

import { getPermission } from './Utils/permissions.js';
import './Tasks.css';

function Tasks({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [tasks, setTasks] = useState([]);
    const [search, setSearch] = useState('');

    const [draggedTask, setDraggedTask] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.tasks.filter((task) =>
            task.name.toLowerCase().includes(search.toLowerCase())
        );

        setTasks(filtered);
    }, [search, project]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddTask />, modaltype: 'small', modaltitle: 'Add Task' }));
    }

    const editButton = (task) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditTask task={task} />, modaltype: 'small', modaltitle: 'Edit Task' }));
    }

    const removeButton = (task) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveTask task={task} />, modaltype: 'small', modaltitle: 'Remove Task' }));
    }

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('index', index);
        setDraggedTask(tasks[index]);

        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.dropEffect = 'move';
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, newIndex) => {
        e.preventDefault();
        const oldIndex = e.dataTransfer.getData('index');
        const updatedTasks = [...tasks];
        updatedTasks.splice(oldIndex, 1);
        updatedTasks.splice(newIndex, 0, draggedTask);
        setTasks(updatedTasks);
        setDraggedTask(null);
    };

    function AddTask() {
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addTask = (name) => {
            console.log('Trying to add task');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Tasks', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name) {
                setResult('Missing name')
                return
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.ADDTASK, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add task data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_TASK',
                            task: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addTask(name)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditTask({ task }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(task.id);
            setName(task.name)
        }, [task]);

        const updateTask = (id, name) => {
            console.log('Trying to update task');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Tasks', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name) {
                setResult('Missing name')
                return
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATETASK, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update task data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_TASK',
                            task: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateTask(id, name)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(task)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    function RemoveTask({ task }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(task.id);
        }, [task]);

        const removeTask = (id) => {
            console.log('Trying to remove task');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Tasks', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVETASK, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove task data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_TASK',
                            taskid: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeTask(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="tasks">
            <Title text='Tasks' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>New Task</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <div className='task_taskcontainer'>
                {
                    tasks.map((task, index) => (
                        <div
                            key={'task' + index}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, index)}
                            onClick={() => editButton(task, index)}
                            className={draggedTask && draggedTask.id === task.id ? 'task_card_container dragged' : 'task_card_container'}
                        >
                            <div className="tasks_tasktext">{task.name}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default Tasks;