import React, { useState, useEffect } from 'react';
import { useStateValue } from '../StateProvider';
import axios from 'axios';
import configData from '../Config.json';
import Title from '../Title';

import { getPermission } from '../Utils/permissions.js';
import Select from '../Components/select.js';
import { transactionsremovalprotection, journalentryremovalprotection } from '../Utils/removeprotection.js'
import '../Settings.css';

function Subaccounts({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const cancelButton = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddSubaccount />, modaltype: 'large', modaltitle: 'Add Subaccount' }));
    }

    const editButton = (subaccount) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditSubaccount subaccount={subaccount} />, modaltype: 'large', modaltitle: 'Edit Subaccount' }));
    }

    const removeButton = (subaccount) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveSubaccount subaccount={subaccount} />, modaltype: 'small', modaltitle: 'Remove Subaccount' }));
    }

    function AddSubaccount() {
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addSubaccount = (name, currencyid) => {
            console.log('Trying to add subaccount');
            setResult(null);

            if (!name) {
                setResult('No name');
                return
            }
            if (!currencyid) {
                setResult('No currency');
                return
            }

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Settings', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !currencyid) {
                setResult('Missing info');
                return;
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.subaccounts.some(subaccount => subaccount.name === trimmedName);
            if (isNameDuplicate) {
                setResult('Subaccount with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: trimmedName,
                currencyid: currencyid
            }

            axios.post(configData.CONTROLLERURL + configData.ADDSUBACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add subaccount data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_SUBACCOUNT',
                            subaccount: data
                        });
                        cancelButton();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addSubaccount(name, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Add'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditSubaccount({ subaccount }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(subaccount.id);
            setName(subaccount.name);
            setCurrencyid(subaccount.currencyid);
        }, [subaccount]);

        const updateSubaccount = (id, name, currencyid) => {
            console.log('Trying to update subaccount');
            setResult(null);
            
            if (!name) {
                setResult('No name');
                return
            }
            if (!currencyid) {
                setResult('No currency');
                return
            }

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Settings', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !currencyid) {
                setResult('Missing info');
                return;
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.subaccounts.some(subaccount => subaccount.name === trimmedName && subaccount.id !== id);
            if (isNameDuplicate) {
                setResult('Subaccount with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: trimmedName,
                currencyid: currencyid
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATESUBACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update subaccount data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_SUBACCOUNT',
                            subaccount: data
                        });
                        cancelButton();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateSubaccount(id, name, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(subaccount)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveSubaccount({ subaccount }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(subaccount.id);
            setName(subaccount.name);
        }, [subaccount]);

        const removeSubaccount = (id) => {
            console.log('Trying to remove subaccount');
            setResult(null)

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Settings', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const journalEntryData = journalentryremovalprotection(project.journalentries, id, 'subaccount');
            if (journalEntryData.code) {
                setResult(journalEntryData.message);
                return
            }

            const transactionData = transactionsremovalprotection(project.transactions, id, 'subaccount');
            if (transactionData.code) {
                setResult(transactionData.message);
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVESUBACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove subaccount data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_SUBACCOUNT',
                            subaccountid: id
                        });
                        cancelButton();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeSubaccount(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='settings_subaccounts'>
            <Title text='Subaccounts' />
            <div className='buttonscontainer'>
                <button className='button' onClick={addButton}>Add Subaccount</button>
            </div>

            <table className='table'>
                <thead><tr><th></th><th>Name</th><th>Currency</th></tr></thead>
                <tbody>
                    {
                        project.subaccounts.map((subaccount, index) => {
                            let currencyName = project.currencies.find(currency => currency.id === subaccount.currencyid)?.name;

                            return (
                                <tr key={'subaccounts' + index}>
                                    <td><div className='table_button' onClick={() => editButton(subaccount)}>Edit</div></td>
                                    <td><div>{subaccount.name}</div></td>
                                    <td><div>{currencyName}</div></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Subaccounts;