import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from './StateProvider';
import { getPermission } from './Utils/permissions.js';
import axios from 'axios';
import configData from './Config';

import Header from './Header';
import CashAccounts from './CashAccounts';
import Summary from './Summary';
import Announcements from './Announcements';
import AccessControls from './AccessControls';
import Assets from './Assets';
import Tasks from './Tasks';
import ChartAccounts from './ChartAccounts';
import Inspections from './Inspections';
import Clients from './Clients';
import Collections from './Collections';
import InteraccountTransfers from './InteraccountTransfers';
import JournalEntries from './JournalEntries';
import PurchaseInvoices from './PurchaseInvoices';
import EmergencyNumbers from './EmergencyNumbers.js';
import EMSReadings from './EMSReadings.js';
import EMSTanks from './EMSTanks.js';
import EMSMeters from './EMSMeters.js';
import Reports from './Reports';
import Transactions from './Transactions';
import Reconciliations from './Reconciliations';
import Suppliers from './Suppliers';
import Contracts from './Contracts';
import Users from './Users.js';
import Settings from './Settings';

import { getHomePermission } from './Utils/permissions.js';

import './Home.css';

const smallscreen = 540;

function Home({ state, setState }) {
  const [{ project, version }, dispatch] = useStateValue();

  const [togglemenu, setTogglemenu] = useState(false);
  const [screenwidth, setScreenWidth] = useState(window.innerWidth);

  const [announcements, setAnnouncements] = useState([]);
  const [accesscontrols, setAccesscontrols] = useState([]);
  const [assets, setAssets] = useState([]);
  const [cashaccounts, setCashaccounts] = useState([]);
  const [clients, setClients] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [interaccounttransfers, setInteraccounttransfers] = useState([]);
  const [emergencynumbers, setEmergencynumbers] = useState([]);
  const [emsreadings, setEmsreadings] = useState([]);
  const [emstanks, setEmstanks] = useState([]);
  const [emsmeters, setEmsmeters] = useState([]);
  const [journalentries, setJournalentries] = useState([]);
  const [reconciliations, setReconciliations] = useState([]);
  const [purchaseinvoices, setPurchaseinvoices] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [users, setUsers] = useState([]);

  const [option, setOption] = useState(<Summary state={state} setState={setState} />);

  const homemenuRef = useRef(null);
  const navigation = useNavigate();

  useEffect(() => {
    if (project.projectid == null) navigation('/')
  }, []);

  useEffect(() => {
    setAnnouncements(project.announcements);
    setAccesscontrols(project.accesscontrols);
    setAssets(project.assets);
    setCashaccounts(project.cashaccounts);
    setClients(project.clients);
    setInspections(project.inspections);
    setReconciliations(project.reconciliations)
    setInteraccounttransfers(project.interaccounttransfers);
    setJournalentries(project.journalentries);
    setEmergencynumbers(project.emergencynumbers);
    setEmsreadings(project.readings);
    setEmstanks(project.tanks);
    setEmsmeters(project.meters)
    setPurchaseinvoices(project.purchaseinvoices);
    setSuppliers(project.suppliers);
    setContracts(project.contracts);
    setTransactions(project.transactions);
    setUsers(project.users);
  }, [project]);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCloseModal = () => {
    setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
  }

  const editProjectButton = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <EditProject />, modaltype: 'small', modaltitle: 'Edit Project' }));
  }

  const archiveProjectButton = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <ArchiveProject />, modaltype: 'small', modaltitle: 'Archive Project' }));
  }

  const unarchiveProjectButton = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <UnarchiveProject />, modaltype: 'small', modaltitle: 'Unarchive Project' }));
  }

  const removeButton = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveProject />, modaltype: 'small', modaltitle: 'Remove Project' }));
  }

  function EditProject() {
    const [name, setName] = useState('');

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    const updateProject = () => {
      console.log('Trying to update project');

      const data = {
        projectuserid: project.projectuserid,
        projectid: project.projectid,
        name: name,
        size: ''
      }

      axios.post(configData.CONTROLLERURL + configData.UPDATEPROJECT, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        }
      }).then((res) => {
        console.log('Add project data received')
        console.log(res.data)
        if (res.data instanceof Object) {
          if (res.data.code === 1) {
            dispatch({
              type: 'UPDATE_PROJECT',
              project: data
            });
            navigation('/');
          }
          else {
            setResult(res.data.data);
          }
        }
      }).catch(() => {
        console.log('Not connected')
      })
    }

    return (
      <div className='modal_body'>
        <div className='modal_printable'>
          <div className='modal_row'>
            <div className='modal_rowsection'>
              <input
                className='modal_input'
                type="text"
                placeholder='Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='modal_actions'>
          {result && <div className='modal_result'>{result}</div>}
          <div className='modal_buttons'>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={() => updateProject()} disabled={loading}>Update</button>
            </div>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const downloadBackup = () => {
    if (project) {
      project.version = version;
      const projectJSON = JSON.stringify(project);
      const blob = new Blob([projectJSON], { type: 'application/json' });

      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      const today = new Date().toLocaleDateString('en-GB');
      const formattedDate = today.replace(/\//g, '_');
      const formattedName = `${project.name.toLowerCase().replace(/\s+/g, '_')}_${formattedDate}`;
      a.download = formattedName + '.pd';
      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }

  function ArchiveProject() {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    const archiveProject = async () => {
      console.log('so first step, make sure clients disconnected,');
      console.log('make sure no collections available');
      console.log('if local inspections remove them all, or cant archive');
      console.log('then move to archive');
      console.log('Trying to archive project');

      const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Archive', 'add');
      if (!hasPermission) {
        setResult('No permission');
        return;
      }

      setLoading(true);

      const allemails = clients.flatMap(client => [{ id: client.id, type: 'client', email: client.email }, ...client.devices.map(device => ({ id: device.id, type: 'device', email: device.email }))]).filter(({ email }) => email);

      const data = {
        projectuserid: project.projectuserid,
        projectid: project.projectid,
        emails: allemails.map(({ email }) => email)
      }

      await axios.post(configData.CONTROLLERURL + configData.GETUSERSSTATUS, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        }
      }).then(async (statusRes) => {
        console.log('Clients status data received');
        console.log(statusRes.data);

        if (statusRes.data.some(status => status.connected)) {
          setResult('Some clients are still connected. Cannot archive.');
          setLoading(false);
          return;
        }
        else {
          console.log('No connected clients found')
        }

        const archiveData = {
          projectuserid: project.projectuserid,
          projectid: project.projectid,
        };

        await axios.post(configData.CONTROLLERURL + configData.ADDARCHIVE, archiveData, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            "userid": state.user.userid,
            "usertoken": state.user.usertoken
          }
        }).then((res) => {
          console.log('Archive project data received');
          console.log(res.data);
          if (res.data instanceof Object) {
            if (res.data.code === 1) {
              handleCloseModal();
              navigation('/');
            }
            else {
              setResult(res.data.data);
            }
          }
        })

      }).catch((err) => {
        setResult(err.response?.data?.message || err.message || 'Network error');
      }).finally(() => {
        setLoading(false);
      });
    }

    return (
      <div className='modal_body'>
        <div className='modal_printable'>
          <div className='modal_row'>
            <div className='modal_rowsection'>Are you sure you want to archive project ?</div>
          </div>
        </div>
        <div className='modal_actions'>
          {result && <div className='modal_result'>{result}</div>}
          <div className='modal_buttons'>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={archiveProject} disabled={loading}>{loading ? 'Loading...' : 'Archive'}</button>
            </div>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function UnarchiveProject() {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    const unarchiveProject = () => {
      const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Archive', 'remove');
      if (!hasPermission) {
        setResult('No permission');
        return;
      }

      setLoading(true);

      const data = {
        projectuserid: project.projectuserid,
        projectid: project.projectid,
      }

      axios.post(configData.CONTROLLERURL + configData.REMOVEARCHIVE, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        }
      }).then((res) => {
        console.log('Unarchive project data received')
        console.log(res.data)
        if (res.data instanceof Object) {
          if (res.data.code === 1) {
            handleCloseModal();
            navigation('/');
          }
          else {
            setResult(res.data.data)
          }
        }
        else {
          setResult('Error');
        }
      }).catch((err) => {
        setResult(err.response?.data?.message || err.message || 'Network error');
      }).finally(() => {
        setLoading(false);
      });
    }

    return (
      <div className='modal_body'>
        <div className='modal_printable'>
          <div className='modal_row'>
            <div className='modal_rowsection'>Are you sure you want to unarchive project ?</div>
          </div>
        </div>
        <div className='modal_actions'>
          {result && <div className='modal_result'>{result}</div>}
          <div className='modal_buttons'>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={unarchiveProject} disabled={loading}>{loading ? 'Loading...' : 'Unarchive'}</button>
            </div>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function RemoveProject() {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    useEffect(() => {
      if (project.users && project.users.length) {
        setLoading(true);
        setResult('Users found. Remove them first before proceeding.')
      }
    }, [project]);

    const removeProject = () => {
      if (project.transactions && project.transactions.length) {
        setLoading(true);
        setResult('Transactions found. Remove them first before proceeding.')
      }

      if (project.users.length > 0) {
        console.log('res')
        setResult('Please remove users first')
        return
      }

      console.log('Trying to remove project');

      if (project.users.length > 0) {
        setResult('Remove users from this project first')
        return
      }

      const data = {
        projectuserid: project.projectuserid,
        projectid: project.projectid
      }

      axios.post(configData.CONTROLLERURL + configData.REMOVEPROJECT, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        }
      }).then((res) => {
        console.log('Remove project data received')
        console.log(res.data)
        if (res.data.code === 1) {
          dispatch({
            type: 'REMOVE_PROJECT',
            projectid: project.id
          });
          navigation('/');
        }
      }).catch((err) => {
        console.log(err)
      })
    }
    return (
      <div className='modal_body'>
        <div className='modal_printable'>
          <div className='modal_row'>
            <div className='modal_rowsection'>Are you sure you want to remove project ?</div>
          </div>
        </div>
        <div className='modal_actions'>
          {result && <div className='modal_result'>{result}</div>}
          <div className='modal_buttons'>
            <div className='modal_buttoncontainer'>
              {!loading && <button className="modal_button" onClick={removeProject} disabled={loading}>Remove</button>}
            </div>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const homepermission = getHomePermission(project.projectuserid, project.users, state.user.userid);

  const handleClickMenuItem = (selectedOption) => {
    setOption(selectedOption);
    setTogglemenu(false);
  };

  return (
    <div className='home'>
      <Header state={state} setState={setState} />
      <div className='home_projectdetails'>
        <div className='home_projectsection'>
          <div className='home_projecttext'>
            {project.name}
          </div>
        </div>
        {homepermission['Edit Project'] && <div className='home_projectsection'><button className='home_headerbutton' onClick={editProjectButton}>Edit Project</button></div>}
        <div className='home_projectsection'>
          <div className='home_headerbutton' onClick={downloadBackup}>Backup Project</div>
        </div>
        <div className='home_projectsection'>
          {project.archived == false && <div className='home_headerbutton' onClick={archiveProjectButton}>Archive</div>}
          {project.archived == true && <div className='home_headerbutton' onClick={unarchiveProjectButton}>Unarchive</div>}
        </div>
        <div className='home_projectsection'>
          <div className='home_headerremovebutton' onClick={removeButton}>Remove</div>
        </div>
      </div>

      <div className='home_content'>
        <div className={screenwidth > smallscreen ? 'home_menu' : (togglemenu ? 'home_menu home_menusmallvisible' : 'home_menu home_menusmallinvisible')} ref={homemenuRef}>
          {homepermission['Summary'] && <div className='home_button' onClick={() => handleClickMenuItem(<Summary state={state} setState={setState} />)}>Summary</div>}
          {homepermission['Announcements'] && <div className='home_button' onClick={() => handleClickMenuItem(<Announcements state={state} setState={setState} />)}>Announcements ({announcements.length})</div>}
          {homepermission['Access Controls'] && <div className='home_button' onClick={() => handleClickMenuItem(<AccessControls state={state} setState={setState} />)}>Access Controls ({accesscontrols.length})</div>}
          {homepermission['Assets'] && <div className='home_button' onClick={() => handleClickMenuItem(<Assets state={state} setState={setState} />)}>Assets ({assets.length})</div>}
          {homepermission['Cash Accounts'] && <div className='home_button' onClick={() => handleClickMenuItem(<CashAccounts state={state} setState={setState} />)}>Cash Accounts ({cashaccounts.length})</div>}
          {homepermission['Chart of Accounts'] && <div className='home_button' onClick={() => handleClickMenuItem(<ChartAccounts state={state} setState={setState} />)}>Chart of Accounts</div>}
          {homepermission['Clients'] && <div className='home_button' onClick={() => handleClickMenuItem(<Clients state={state} setState={setState} />)}>Clients ({clients.length})</div>}
          {homepermission['Contracts'] && <div className='home_button' onClick={() => handleClickMenuItem(<Contracts state={state} setState={setState} />)}>Contracts ({contracts.length})</div>}
          {homepermission['Collections'] && <div className='home_button' onClick={() => handleClickMenuItem(<Collections state={state} setState={setState} />)}>Collections</div>}
          {homepermission['Emergency Numbers'] && <div className='home_button' onClick={() => handleClickMenuItem(<EmergencyNumbers state={state} setState={setState} />)}>Emergency Numbers ({emergencynumbers.length})</div>}
          {homepermission['EMS Readings'] && <div className='home_button' onClick={() => handleClickMenuItem(<EMSReadings state={state} setState={setState} />)}>EMS Readings ({emsreadings.length})</div>}
          {homepermission['EMS Tanks'] && <div className='home_button' onClick={() => handleClickMenuItem(<EMSTanks state={state} setState={setState} />)}>EMS Tanks ({emstanks.length})</div>}
          {homepermission['EMS Meters'] && <div className='home_button' onClick={() => handleClickMenuItem(<EMSMeters state={state} setState={setState} />)}>EMS Meters ({emsmeters.length})</div>}
          {homepermission['Inspections'] && <div className='home_button' onClick={() => handleClickMenuItem(<Inspections state={state} setState={setState} />)}>Inspections ({inspections.length})</div>}
          {homepermission['Interaccount Transfers'] && <div className='home_button' onClick={() => handleClickMenuItem(<InteraccountTransfers state={state} setState={setState} />)}>Interaccount Transfers ({interaccounttransfers.length})</div>}
          {homepermission['Journal Entries'] && <div className='home_button' onClick={() => handleClickMenuItem(<JournalEntries state={state} setState={setState} />)}>Journal Entries ({journalentries.length})</div>}
          {homepermission['Purchase Invoices'] && <div className='home_button' onClick={() => handleClickMenuItem(<PurchaseInvoices state={state} setState={setState} />)}>Purchase Invoices ({purchaseinvoices.length})</div>}
          {homepermission['Reconciliations'] && <div className='home_button' onClick={() => handleClickMenuItem(<Reconciliations state={state} setState={setState} />)}>Reconciliations ({reconciliations.length})</div>}
          {homepermission['Reports'] && <div className='home_button' onClick={() => handleClickMenuItem(<Reports state={state} setState={setState} />)}>Reports</div>}
          {homepermission['Suppliers'] && <div className='home_button' onClick={() => handleClickMenuItem(<Suppliers state={state} setState={setState} />)}>Suppliers ({suppliers.length})</div>}
          {homepermission['Tasks'] && <div className='home_button' onClick={() => handleClickMenuItem(<Tasks state={state} setState={setState} />)}>Tasks</div>}
          {homepermission['Transactions'] && <div className='home_button' onClick={() => handleClickMenuItem(<Transactions state={state} setState={setState} />)}>Transactions ({transactions.length})</div>}
          {homepermission['Settings'] && <div className='home_button' onClick={() => handleClickMenuItem(<Settings state={state} setState={setState} />)}>Settings</div>}
          {(state.user.usertype == 1 || state.user.usertype == 2) && <div className='home_button' onClick={() => handleClickMenuItem(<Users state={state} setState={setState} />)}>Users ({users.length})</div>}
        </div>

        <div className='home_panel'>
          {option}
        </div>

        {
          screenwidth <= smallscreen &&
          <div className="home_floatingbutton" onClick={() => setTogglemenu(!togglemenu)}>
            <span className="home_floatingbuttonicon">+</span>
          </div>
        }
      </div>
    </div>
  );
}

export default Home;