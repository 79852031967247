export const initialState = {
    projects: [],
    project: {
        archived: false,
        accesscontrols: [],
        announcements: [],
        assets: [],
        basecurrency: null,
        cashaccounts: [],
        clients: [],
        contracts: [],
        currencies: [],
        exchangerates: [],
        expenseaccounts: [],
        expensegroups: [],
        incomeaccounts: [],
        incomegroups: [],
        inspections: [],
        interaccounttransfers: [],
        journalentries: [],
        name: '',
        lastmodified: 0,
        meters: [],
        projectdetails: null,
        projectdetailsimage: null,
        projectid: null,
        projectuserid: null,
        purchaseinvoices: [],
        readings: [],
        reconciliations: [],
        size: '',
        startdate: '',
        subaccounts: [],
        suppliers: [],
        tanks: [],
        tasks: [],
        transactions: [],
        users: []
    },
}

const reducer = (state, action) => {
    switch (action.type) {
        case "ADD_USERNAME":
            return {
                ...state,
                user: {
                    ...state.user,
                    username: action.username
                }
            };
        case "SET_PROJECTS":
            return {
                ...state,
                projects: action.projects
            }
        case "SET_PROJECT":
            return {
                ...state,
                project: action.project
            }
        case "UNSET_PROJECT":
            return {
                ...state,
                project: initialState.project
            }
        case "ADD_PROJECT":
            return {
                ...state,
                projects: [...state.projects, action.project],
            };
        case "UPDATE_PROJECT":
            return {
                ...state,
                projects: state.projects.map((project) => project.id === action.project.projectid ? action.project : project
                ),
            };
        case "REMOVE_PROJECT":
            return {
                ...state,
                projects: state.projects.filter((project) => project.id !== action.projectid),
            };
        case "ADD_ANNOUNCEMENT":
            return {
                ...state,
                project: {
                    ...state.project,
                    announcements: [...state.project.announcements, action.announcement]
                }
            };
        case "UPDATE_ANNOUNCEMENT":
            return {
                ...state,
                project: {
                    ...state.project,
                    announcements: state.project.announcements.map(announcement =>
                        announcement.id === action.announcement.id ? action.announcement : announcement
                    )
                }
            };
        case "REMOVE_ANNOUNCEMENT":
            return {
                ...state,
                project: {
                    ...state.project,
                    announcements: state.project.announcements.filter(announcement => announcement.id !== action.announcementid)
                }
            };
        case "ADD_ACCESSCONTROL":
            return {
                ...state,
                project: {
                    ...state.project,
                    accesscontrol: [...state.project.accesscontrol, action.accesscontrol]
                }
            };
        case "UPDATE_ACCESSCONTROL":
            return {
                ...state,
                project: {
                    ...state.project,
                    accesscontrol: state.project.accesscontrol.map(ac =>
                        ac.id === action.accesscontrol.id ? action.accesscontrol : ac
                    )
                }
            };
        case "REMOVE_ACCESSCONTROL":
            return {
                ...state,
                project: {
                    ...state.project,
                    accesscontrol: state.project.accesscontrol.filter(acc => acc.id !== action.accesscontrolid)
                }
            };
        case "ADD_ASSET":
            return {
                ...state,
                project: {
                    ...state.project,
                    assets: [...state.project.assets, action.asset]
                }
            };
        case "UPDATE_ASSET":
            return {
                ...state,
                project: {
                    ...state.project,
                    assets: state.project.assets.map(asset =>
                        asset.id === action.asset.id ? action.asset : asset
                    )
                }
            };
        case "REMOVE_ASSET":
            return {
                ...state,
                project: {
                    ...state.project,
                    assets: state.project.assets.filter(asset => asset.id !== action.assetid)
                }
            };
        case "MAKE_DEFAULT_CURRENCY":
            return {
                ...state,
                project: {
                    ...state.project,
                    basecurrency: action.currencyid
                }
            };
        case "ADD_CASHACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    cashaccounts: [...state.project.cashaccounts, action.cashaccount]
                }
            };
        case "UPDATE_CASHACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    cashaccounts: state.project.cashaccounts.map(item =>
                        item.id === action.cashaccount.id ? { ...item, ...action.cashaccount } : item
                    )
                }
            };
        case "REMOVE_CASHACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    cashaccounts: state.project.cashaccounts.filter(item => item.id !== action.cashaccountid)
                }
            };
        case "ADD_CLIENT":
            return {
                ...state,
                project: {
                    ...state.project,
                    clients: [...state.project.clients, action.client]
                }
            };
        case "UPDATE_CLIENT":
            return {
                ...state,
                project: {
                    ...state.project,
                    clients: state.project.clients.map(client =>
                        client.id === action.client.id ? action.client : client
                    )
                }
            };
        case "REMOVE_CLIENT":
            return {
                ...state,
                project: {
                    ...state.project,
                    clients: state.project.clients.filter(client => client.id !== action.id)
                }
            };
        case "ADD_CONTRACT":
            return {
                ...state,
                project: {
                    ...state.project,
                    contracts: [...state.project.contracts, action.contract]
                }
            };
        case "UPDATE_CONTRACT":
            return {
                ...state,
                project: {
                    ...state.project,
                    contracts: state.project.contracts.map(contract =>
                        contract.id === action.contract.id ? action.contract : contract
                    )
                }
            };
        case "REMOVE_CONTRACT":
            return {
                ...state,
                project: {
                    ...state.project,
                    contracts: state.project.contracts.filter(contract => contract.id !== action.contractid)
                }
            };
        case "ADD_EMERGENCYNUMBER":
            return {
                ...state,
                project: {
                    ...state.project,
                    emergencynumbers: [...state.project.emergencynumbers, action.emergencynumber]
                }
            };
        case "UPDATE_EMERGENCYNUMBER":
            return {
                ...state,
                project: {
                    ...state.project,
                    emergencynumbers: state.project.emergencynumbers.map(emergencynumber =>
                        emergencynumber.id === action.emergencynumber.id ? action.emergencynumber : emergencynumber
                    )
                }
            };
        case "REMOVE_EMERGENCYNUMBER":
            return {
                ...state,
                project: {
                    ...state.project,
                    emergencynumbers: state.project.emergencynumbers.filter(emergencynumber => emergencynumber.id !== action.id)
                }
            };
        case "ADD_CURRENCY":
            return {
                ...state,
                project: {
                    ...state.project,
                    currencies: [...state.project.currencies, action.currency]
                }
            };
        case "UPDATE_CURRENCY":
            return {
                ...state,
                project: {
                    ...state.project,
                    currencies: state.project.currencies.map(item =>
                        item.id === action.currency.id ? { ...item, ...action.currency } : item
                    )
                }
            };
        case "REMOVE_CURRENCY":
            return {
                ...state,
                project: {
                    ...state.project,
                    currencies: state.project.currencies.filter(item => item.id !== action.currencyid)
                }
            };
        case "ADD_EXCHANGERATE":
            return {
                ...state,
                project: {
                    ...state.project,
                    exchangerates: [...state.project.exchangerates, action.exchangerate]
                }
            };
        case "UPDATE_EXCHANGERATE":
            return {
                ...state,
                project: {
                    ...state.project,
                    exchangerates: state.project.exchangerates.map(rate =>
                        rate.id === action.exchangerate.id ? action.exchangerate : rate
                    )
                }
            };
        case "REMOVE_EXCHANGERATE":
            return {
                ...state,
                project: {
                    ...state.project,
                    exchangerates: state.project.exchangerates.filter(rate => rate.id !== action.exchangerateid)
                }
            };
        case "ADD_EXPENSEACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    expenseaccounts: [...state.project.expenseaccounts, action.expenseaccount]
                }
            };
        case "UPDATE_EXPENSEACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    expenseaccounts: state.project.expenseaccounts.map(account =>
                        account.id === action.expenseaccount.id ? action.expenseaccount : account
                    )
                }
            };
        case "REMOVE_EXPENSEACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    expenseaccounts: state.project.expenseaccounts.filter(account => account.id !== action.expenseaccountid)
                }
            };
        case "ADD_EXPENSEGROUP":
            return {
                ...state,
                project: {
                    ...state.project,
                    expensegroups: [...state.project.expensegroups, action.expensegroup]
                }
            };
        case "UPDATE_EXPENSEGROUP":
            return {
                ...state,
                project: {
                    ...state.project,
                    expensegroups: state.project.expensegroups.map(group =>
                        group.id === action.expensegroup.id ? action.expensegroup : group
                    )
                }
            };
        case "REMOVE_EXPENSEGROUP":
            return {
                ...state,
                project: {
                    ...state.project,
                    expensegroups: state.project.expensegroups.filter(group => group.id !== action.expensegroupid)
                }
            };
        case "ADD_INCOMEACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    incomeaccounts: [...state.project.incomeaccounts, action.incomeaccount]
                }
            };
        case "UPDATE_INCOMEACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    incomeaccounts: state.project.incomeaccounts.map(account =>
                        account.id === action.incomeaccount.id ? action.incomeaccount : account
                    )
                }
            };
        case "REMOVE_INCOMEACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    incomeaccounts: state.project.incomeaccounts.filter(account => account.id !== action.incomeaccountid)
                }
            };
        case "ADD_INCOMEGROUP":
            return {
                ...state,
                project: {
                    ...state.project,
                    incomegroups: [...state.project.incomegroups, action.incomegroup]
                }
            };
        case "UPDATE_INCOMEGROUP":
            return {
                ...state,
                project: {
                    ...state.project,
                    incomegroups: state.project.incomegroups.map(group =>
                        group.id === action.incomegroup.id ? action.incomegroup : group
                    )
                }
            };
        case "REMOVE_INCOMEGROUP":
            return {
                ...state,
                project: {
                    ...state.project,
                    incomegroups: state.project.incomegroups.filter(group => group.id !== action.incomegroupid)
                }
            };
        case "ADD_INSPECTION":
            return {
                ...state,
                project: {
                    ...state.project,
                    inspections: [...state.project.inspections, action.inspection]
                }
            };
        case "UPDATE_INSPECTION":
            return {
                ...state,
                project: {
                    ...state.project,
                    inspections: state.project.inspections.map(inspection =>
                        inspection.id === action.inspection.id ? action.inspection : inspection
                    )
                }
            };
        case "REMOVE_INSPECTION":
            return {
                ...state,
                project: {
                    ...state.project,
                    inspections: state.project.inspections.filter(inspection => inspection.id !== action.inspectionid)
                }
            };
        case "ADD_INTERACCOUNTTRANSFER":
            return {
                ...state,
                project: {
                    ...state.project,
                    interaccounttransfers: [...state.project.interaccounttransfers, action.interaccounttransfer]
                }
            };
        case "UPDATE_INTERACCOUNTTRANSFER":
            return {
                ...state,
                project: {
                    ...state.project,
                    interaccounttransfers: state.project.interaccounttransfers.map(interaccounttransfer =>
                        interaccounttransfer.id === action.interaccounttransfer.id ? action.interaccounttransfer : interaccounttransfer
                    )
                }
            };
        case "REMOVE_INTERACCOUNTTRANSFER":
            return {
                ...state,
                project: {
                    ...state.project,
                    interaccounttransfers: state.project.interaccounttransfers.filter(interaccounttransfer => interaccounttransfer.id !== action.interaccounttransferid)
                }
            };
        case "ADD_JOURNALENTRY":
            return {
                ...state,
                project: {
                    ...state.project,
                    journalentries: [...state.project.journalentries, action.journalentry]
                }
            };
        case "UPDATE_JOURNALENTRY":
            return {
                ...state,
                project: {
                    ...state.project,
                    journalentries: state.project.journalentries.map(journal =>
                        journal.id === action.journalentry.id ? action.journalentry : journal
                    )
                }
            };
        case "REMOVE_JOURNALENTRY":
            return {
                ...state,
                project: {
                    ...state.project,
                    journalentries: state.project.journalentries.filter(journal => journal.id !== action.id)
                }
            };
        case "UPDATE_PROJECTKEYS":
            const updatedState = { ...state.project };
            action.keys.forEach(key => {
                updatedState[key] = action.data[key];
            });
            return {
                ...state,
                project: updatedState
            };
        case "ADD_PROJECTDETAILS":
            return {
                ...state,
                project: {
                    ...state.project,
                    projectdetails: action.projectdetails,
                    projectdetailsimage: action.projectdetailsimage
                }
            };
        case "REMOVE_PROJECTDETAILSIMAGE":
            return {
                ...state,
                project: {
                    ...state.project,
                    projectdetailsimage: ''
                }
            };
        case "ADD_STARTDATE":
            return {
                ...state,
                project: {
                    ...state.project,
                    startdate: action.startdate
                }
            };
        case "ADD_PURCHASEINVOICE":
            return {
                ...state,
                project: {
                    ...state.project,
                    purchaseinvoices: [...state.project.purchaseinvoices, action.purchaseinvoice]
                }
            };
        case "UPDATE_PURCHASEINVOICE":
            return {
                ...state,
                project: {
                    ...state.project,
                    purchaseinvoices: state.project.purchaseinvoices.map(invoice =>
                        invoice.id === action.purchaseinvoice.id ? action.purchaseinvoice : invoice
                    )
                }
            };
        case "REMOVE_PURCHASEINVOICE":
            return {
                ...state,
                project: {
                    ...state.project,
                    purchaseinvoices: state.project.purchaseinvoices.filter(invoice => invoice.id !== action.purchaseinvoiceid)
                }
            };
        case "ADD_READING":
            return {
                ...state,
                project: {
                    ...state.project,
                    readings: [...state.project.readings, action.reading]
                }
            };
        case "UPDATE_READING":
            return {
                ...state,
                project: {
                    ...state.project,
                    readings: state.project.readings.map(reading =>
                        reading.id === action.reading.id ? action.reading : reading
                    )
                }
            };
        case "REMOVE_READING":
            return {
                ...state,
                project: {
                    ...state.project,
                    readings: state.project.readings.filter(reading => reading.id !== action.readingid)
                }
            };
        case "ADD_TANK":
            return {
                ...state,
                project: {
                    ...state.project,
                    tanks: [...state.project.tanks, action.tank]
                }
            };
        case "UPDATE_TANK":
            return {
                ...state,
                project: {
                    ...state.project,
                    tanks: state.project.tanks.map(tank =>
                        tank.id === action.tank.id ? action.tank : tank
                    )
                }
            };
        case "REMOVE_TANK":
            return {
                ...state,
                project: {
                    ...state.project,
                    tanks: state.project.tanks.filter(tank => tank.id !== action.tankid)
                }
            };
        case "ADD_METER":
            return {
                ...state,
                project: {
                    ...state.project,
                    meters: [...state.project.meters, action.meter]
                }
            };
        case "UPDATE_METER":
            return {
                ...state,
                project: {
                    ...state.project,
                    meters: state.project.meters.map(meter =>
                        meter.id === action.meter.id ? action.meter : meter
                    )
                }
            };
        case "REMOVE_METER":
            return {
                ...state,
                project: {
                    ...state.project,
                    meters: state.project.meters.filter(meter => meter.id !== action.meterid)
                }
            };
        case "ADD_RECONCILIATION":
            return {
                ...state,
                project: {
                    ...state.project,
                    reconciliations: [...state.project.reconciliations, action.reconciliation]
                }
            };
        case "UPDATE_RECONCILIATION":
            return {
                ...state,
                project: {
                    ...state.project,
                    reconciliations: state.project.reconciliations.map(reconciliation =>
                        reconciliation.id === action.reconciliation.id ? action.reconciliation : reconciliation
                    )
                }
            };
        case "REMOVE_RECONCILIATION":
            return {
                ...state,
                project: {
                    ...state.project,
                    reconciliations: state.project.reconciliations.filter(reconciliation => reconciliation.id !== action.reconciliationid)
                }
            };
        case "ADD_SUBACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    subaccounts: [...state.project.subaccounts, action.subaccount]
                }
            };
        case "UPDATE_SUBACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    subaccounts: state.project.subaccounts.map(subaccount =>
                        subaccount.id === action.subaccount.id ? action.subaccount : subaccount
                    )
                }
            };
        case "REMOVE_SUBACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    subaccounts: state.project.subaccounts.filter(subaccount => subaccount.id !== action.subaccountid)
                }
            };
        case "ADD_SUPPLIER":
            return {
                ...state,
                project: {
                    ...state.project,
                    suppliers: [...state.project.suppliers, action.supplier]
                }
            };
        case "UPDATE_SUPPLIER":
            return {
                ...state,
                project: {
                    ...state.project,
                    suppliers: state.project.suppliers.map(supplier =>
                        supplier.id === action.supplier.id ? action.supplier : supplier
                    )
                }
            };
        case "REMOVE_SUPPLIER":
            return {
                ...state,
                project: {
                    ...state.project,
                    suppliers: state.project.suppliers.filter(supplier => supplier.id !== action.supplierid)
                }
            };
        case "ADD_TASK":
            return {
                ...state,
                project: {
                    ...state.project,
                    tasks: [...state.project.tasks, action.task]
                }
            };
        case "UPDATE_TASK":
            return {
                ...state,
                project: {
                    ...state.project,
                    tasks: state.project.tasks.map(task =>
                        task.id === action.task.id ? action.task : task
                    )
                }
            };
        case "REMOVE_TASK":
            return {
                ...state,
                project: {
                    ...state.project,
                    tasks: state.project.tasks.filter(task => task.id !== action.taskid)
                }
            };
        case "ADD_TRANSACTION":
            return {
                ...state,
                project: {
                    ...state.project,
                    transactions: [...state.project.transactions, action.transaction]
                }
            };
        case "UPDATE_TRANSACTION":
            return {
                ...state,
                project: {
                    ...state.project,
                    transactions: state.project.transactions.map(transaction =>
                        transaction.id === action.transaction.id ? action.transaction : transaction
                    )
                }
            };
        case "REMOVE_TRANSACTION":
            return {
                ...state,
                project: {
                    ...state.project,
                    transactions: state.project.transactions.filter(transaction => transaction.id !== action.id)
                }
            };
        case "ADD_USER":
            return {
                ...state,
                project: {
                    ...state.project,
                    users: [...state.project.users, action.user]
                }
            };
        case "UPDATE_USER":
            return {
                ...state,
                project: {
                    ...state.project,
                    users: state.project.users.map(user =>
                        user.id === action.user.id ? action.user : user
                    )
                }
            };
        case "REMOVE_USER":
            return {
                ...state,
                project: {
                    ...state.project,
                    users: state.project.users.filter(user => user.id !== action.userid)
                }
            };
        case "IMPORT_JOURNALENTRIES":
            return {
                ...state,
                project: {
                    ...state.project,
                    journalentries: [...state.project.journalentries, ...action.journalentries]
                }
            };
        case "IMPORT_PURCHASEINVOICES":
            return {
                ...state,
                project: {
                    ...state.project,
                    purchaseinvoices: [...state.project.purchaseinvoices, ...action.purchaseinvoices]
                }
            };
        case "IMPORT_INTERACCOUNTTRANSFERS":
            return {
                ...state,
                project: {
                    ...state.project,
                    interaccounttransfers: [...state.project.interaccounttransfers, ...action.interaccounttransfers]
                }
            };
        case "IMPORT_TRANSACTIONS":
            return {
                ...state,
                project: {
                    ...state.project,
                    transactions: [...state.project.transactions, ...action.transactions]
                }
            };
        case "IMPORT_CLIENTS":
            return {
                ...state,
                project: {
                    ...state.project,
                    clients: [...state.project.clients, ...action.clients]
                }
            };
        case "IMPORT_SUPPLIERS":
            return {
                ...state,
                project: {
                    ...state.project,
                    suppliers: [...state.project.suppliers, ...action.suppliers]
                }
            };
        case "IMPORT_ACCESSCONTROLS":
            return {
                ...state,
                project: {
                    ...state.project,
                    accesscontrols: [...state.project.accesscontrols, ...action.accesscontrols]
                }
            };
        case "IMPORT_ASSETS":
            return {
                ...state,
                project: {
                    ...state.project,
                    assets: [...state.project.assets, ...action.assets]
                }
            };
        case "IMPORT_CHARTACCOUNTS":
            return {
                ...state,
                project: {
                    ...state.project,
                    incomegroups: [...state.project.incomegroups, ...action.incomegroups],
                    incomeaccounts: [...state.project.incomeaccounts, ...action.incomeaccounts],
                    expensegroups: [...state.project.expensegroups, ...action.expensegroups],
                    expenseaccounts: [...state.project.expenseaccounts, ...action.expenseaccounts]
                }
            };
        case "IMPORT_CURRENCIES":
            return {
                ...state,
                project: {
                    ...state.project,
                    currencies: [...state.project.currencies, ...action.currencies]
                }
            };
        case "IMPORT_CONTRACTS":
            return {
                ...state,
                project: {
                    ...state.project,
                    contracts: [...state.project.contracts, ...action.contracts]
                }
            };
        case "IMPORT_EMERGENCYNUMBERS":
            return {
                ...state,
                project: {
                    ...state.project,
                    emergencynumbers: [...state.project.emergencynumbers, ...action.emergencynumbers]
                }
            };
        case "IMPORT_USERS":
            return {
                ...state,
                project: {
                    ...state.project,
                    users: action.users
                }
            };
        case 'UPDATE_KEYVALUE':
            return {
                ...state,
                project: {
                    ...state.project,
                    [action.key]: action.value
                }
            };
        default:
            return state;
    }
}

export default reducer;