import { findNearestExchangeRate } from './currency.js';

export function getSupplierAccounts(suppliers, transactions, purchaseinvoices, journalentries, exchangerates, currencies, basecurrency) { //20240108
    const data = [];
    const currencySymbol = currencies.find(item => item.id === basecurrency)?.symbol || '';

    suppliers.forEach((supplier) => {
        let totalAmount = 0;

        purchaseinvoices.forEach((invoice) => {
            if (invoice.supplierid === supplier.id) {
                if (invoice.currencyid === basecurrency) {
                    totalAmount += parseFloat(invoice.amount)
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, invoice.ts, invoice.currencyid, basecurrency);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    totalAmount += parseFloat(invoice.amount) * exchangerate;
                }
            }
        });

        transactions.forEach((transaction) => {
            if (transaction.beneficiaryid === supplier.id) {
                if (transaction.currencyid === basecurrency) {
                    totalAmount -= parseFloat(transaction.amount)
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    totalAmount -= parseFloat(transaction.amount) * exchangerate;
                }
            }
        });

        journalentries.forEach((entry) => {
            if (entry.rows) {
                entry.rows.forEach((row) => {
                    if (row.accountid === supplier.id) {
                        if (entry.currencyid === basecurrency) {
                            totalAmount += parseFloat(row.debit - row.credit)
                        }
                        else {
                            const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, basecurrency);
                            const exchangerate = NearestExchangeRate.exchangerate;
                            totalAmount += parseFloat(row.debit - row.credit) * exchangerate;;
                        }
                    }
                });
            }
        });

        data.push({
            id: supplier.id,
            name: supplier.name,
            currency: currencySymbol,
            balance: totalAmount.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(totalAmount) ? 0 : 2, maximumFractionDigits: 2 })
        });
    });

    data.sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name, 'en', { numeric: true });
        if (nameComparison === 0) {
            return parseFloat(a.balance) - parseFloat(b.balance);
        }
        return nameComparison;
    });

    return data;
}
