import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';

import { getChartAccounts, getExpenseBudget } from './Utils/chartaccounts.js';
import Title from './Title.js';
import { transactionsremovalprotection, journalentryremovalprotection } from './Utils/removeprotection.js';
import { getPermission } from './Utils/permissions.js';
import { registerArabicFont } from "./Utils/registerarabicfont.js";
import ReactToPrint from 'react-to-print';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import Select from './Components/select.js';

import './ChartAccounts.css';

function ChartAccounts({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const chartaccountsdata = getChartAccounts(project.incomegroups, project.incomeaccounts, project.expensegroups, project.expenseaccounts, project.currencies);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButtonGroup = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddGroup />, modaltype: 'small', modaltitle: 'Add Group' }));
    }

    const editButtonGroup = (group) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditGroup group={group} />, modaltype: 'small', modaltitle: 'Edit Group' }));
    }

    const removeButtonGroup = (group) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveGroup group={group} />, modaltype: 'small', modaltitle: 'Remove Group' }));
    }

    const addButtonAccount = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddAccount />, modaltype: 'small', modaltitle: 'Add Account' }));
    }

    const editButtonAccount = (account) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditAccount account={account} />, modaltype: 'small', modaltitle: 'Edit Account' }));
    }

    const removeButtonAccount = (account) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveAccount account={account} />, modaltype: 'small', modaltitle: 'Remove Account' }));
    }

    const viewExpensesBudget = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewExpensesBudget />, modaltype: 'large', modaltitle: 'View Expenses Budget' }));
    }

    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Import' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            const dataToCopy = {
                incomegroups: project.incomegroups,
                incomeaccounts: project.incomeaccounts,
                expensegroups: project.expensegroups,
                expenseaccounts: project.expenseaccounts
            };

            const dataString = JSON.stringify(dataToCopy, null, 2);

            navigator.clipboard.writeText(dataString)
                .then(() => setResult('Data copied to clipboard'))
                .catch(err => setResult('Error copying data to clipboard: ', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');

        const [newincomegroups, setNewincomegroups] = useState([]);
        const [newexpensegroups, setNewexpensegroups] = useState([]);
        const [newincomeaccounts, setNewincomeaccounts] = useState([]);
        const [newexpenseaccounts, setNewexpenseaccounts] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewincomegroups([]);
                setNewexpensegroups([]);
                setNewincomeaccounts([]);
                setNewexpenseaccounts([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            const validateEntries = (entries, requiredAttributes) =>
                entries.every(entry => requiredAttributes.every(attr => entry.hasOwnProperty(attr)));

            const groupAttributes = ["id", "name", "orderid"];
            const accountAttributes = ["id", "name", "budget", "currencyid", "groupid", "orderid"];

            if (isJSON) {
                const validIncomeGroups = validateEntries(data.incomegroups, groupAttributes);
                const validExpenseGroups = validateEntries(data.expensegroups, groupAttributes);
                const validIncomeAccounts = validateEntries(data.incomeaccounts, accountAttributes);
                const validExpenseAccounts = validateEntries(data.expenseaccounts, accountAttributes);

                if (validIncomeGroups && validExpenseGroups && validIncomeAccounts && validExpenseAccounts) {
                    const newincomegroups = data.incomegroups.filter(entry => !project.incomegroups.some(group => group.id === entry.id));
                    const newexpensegroups = data.expensegroups.filter(entry => !project.expensegroups.some(group => group.id === entry.id));
                    const newincomeaccounts = data.incomeaccounts.filter(entry => !project.incomeaccounts.some(account => account.id === entry.id));
                    const newexpenseaccounts = data.expenseaccounts.filter(entry => !project.expenseaccounts.some(account => account.id === entry.id));

                    const totalNewEntries = newincomegroups.length + newexpensegroups.length + newincomeaccounts.length + newexpenseaccounts.length;
                    const totalEntriesCount = Object.values(data).reduce((sum, group) => sum + (Array.isArray(group) ? group.length : 0), 0);

                    if (totalNewEntries > 0) {
                        setNewincomegroups(newincomegroups);
                        setNewexpensegroups(newexpensegroups);
                        setNewincomeaccounts(newincomeaccounts);
                        setNewexpenseaccounts(newexpenseaccounts);
                        setResult('Data is valid. New entries found: ' + totalNewEntries);
                    }
                    else {
                        setResult(totalEntriesCount + " entries found. No new data.");
                    }
                } else {
                    setResult('Invalid data: Missing required attributes.');
                }
            }
            else {
                setResult('Invalid format');
            }
        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');
            
            const totalNewEntries = newincomegroups.length + newincomeaccounts.length + newexpensegroups.length + newexpenseaccounts.length;
            if (totalNewEntries === 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true);
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                incomegroups: newincomegroups,
                incomeaccounts: newincomeaccounts,
                expensegroups: newexpensegroups,
                expenseaccounts: newexpenseaccounts,
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTCHARTACCOUNTS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import chart data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_CHARTACCOUNTS',
                            incomegroups: newincomegroups,
                            incomeaccounts: newincomeaccounts,
                            expensegroups: newexpensegroups,
                            expenseaccounts: newexpenseaccounts,
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

            setResult('Data imported successfully.');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function AddGroup() {
        const [type, setType] = useState('incomegroup');
        const [name, setName] = useState('');
        const [orderid, setOrderid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addAccountGroup = (type, name, orderid) => {
            console.log('Trying to add group');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Chart of Accounts', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name) {
                setResult('Missing info')
                return;
            }

            const trimmedName = name.trim();

            if (type === 'incomegroup') {
                const isGroupDuplicate = project.incomegroups.some(group => group.name === trimmedName);
                if (isGroupDuplicate) {
                    setResult('Group with the same name already exists');
                    return;
                }
            }
            else if (type === 'expensegroup') {
                const isGroupDuplicate = project.expensegroups.some(group => group.name === trimmedName);
                if (isGroupDuplicate) {
                    setResult('Group with the same name already exists');
                    return;
                }
            }

            if (type === 'incomegroup') {
                const isOrderDuplicate = project.incomegroups.some(group =>
                    orderid !== '' &&
                    group.orderid == orderid
                );
                if (isOrderDuplicate) {
                    setResult('Group with the same order already exists');
                    return;
                }
            }
            else if (type === 'expensegroup') {
                const isOrderDuplicate = project.expensegroups.some(group =>
                    orderid !== '' &&
                    group.orderid == orderid
                );
                if (isOrderDuplicate) {
                    setResult('Group with the same order already exists');
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                type: type,
                name: trimmedName,
                orderid: parseInt(orderid)
            }

            axios.post(configData.CONTROLLERURL + configData.ADDGROUP, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add group data received')

                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        if (type === 'incomegroup') {
                            dispatch({
                                type: 'ADD_INCOMEGROUP',
                                incomegroup: data
                            });
                        }
                        else if (type === 'expensegroup') {
                            dispatch({
                                type: 'ADD_EXPENSEGROUP',
                                expensegroup: data
                            });
                        }
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Group Type</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <select
                                className='modal_select'
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option value="incomegroup">Income Group</option>
                                <option value="expensegroup">Expense Group</option>
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Group Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Order ID</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                value={orderid}
                                onChange={(e) => setOrderid(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addAccountGroup(type, name, orderid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditGroup({ group }) {
        const [id, setId] = useState('');

        const [type, setType] = useState('');
        const [name, setName] = useState('');
        const [orderid, setOrderid] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(group.id);
            setType(group.type);
            setName(group.name);
            setOrderid(group.orderid);
        }, [group]);

        const updateGroup = (id, type, name, orderid) => {
            console.log('Trying to update group');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Chart of Accounts', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name) {
                setResult('Missing info')
                return;
            }

            const trimmedName = name.trim();

            if (type === 'incomegroup') {
                const isNameDuplicate = project.incomegroups.some(group => group.name === trimmedName && group.id !== id);
                if (isNameDuplicate) {
                    setResult('Group with the same name already exists');
                    return;
                }
            }
            else if (type === 'expensegroup') {
                const isNameDuplicate = project.expensegroups.some(group => group.name === trimmedName && group.id !== id);
                if (isNameDuplicate) {
                    setResult('Group with the same name already exists');
                    return;
                }
            }

            if (type === 'incomegroup') {
                const isOrderDuplicate = project.incomegroups.some(group =>
                    orderid !== '' &&
                    group.orderid == orderid &&
                    group.id !== id
                );
                if (isOrderDuplicate) {
                    setResult('Group with the same order already exists');
                    return;
                }
            }
            else if (type === 'expensegroup') {
                console.log(project.expensegroups)
                const isOrderDuplicate = project.expensegroups.some(group =>
                    orderid !== '' &&
                    group.orderid == orderid &&
                    group.id !== id
                );
                if (isOrderDuplicate) {
                    console.log(id)
                    setResult('Group with the same order already exists');
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type,
                name: trimmedName,
                orderid: parseInt(orderid),
            }

            console.log(data)
            axios.post(configData.CONTROLLERURL + configData.UPDATEGROUP, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update group data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        if (type === 'incomegroup') {
                            dispatch({
                                type: 'UPDATE_INCOMEGROUP',
                                incomegroup: data
                            });
                        }
                        else if (type === 'expensegroup') {
                            dispatch({
                                type: 'UPDATE_EXPENSEGROUP',
                                expensegroup: data
                            });
                        }
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Group Type</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <select
                                className='modal_select'
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                disabled
                            >
                                <option value="incomegroup">Income Group</option>
                                <option value="expensegroup">Expense Group</option>
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Group Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Order ID</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                value={orderid}
                                onChange={(e) => setOrderid(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateGroup(id, type, name, orderid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButtonGroup(group)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveGroup({ group }) {
        const [id, setId] = useState('');
        const [type, setType] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(group.id)
            setType(group.type)
        }, [group]);

        const removeGroup = (id, type) => {
            console.log('Trying to remove group');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Chart of Accounts', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (type === 'incomegroup') {
                const hasAssociatedAccounts = project.incomeaccounts.some(account => account.groupid === id);
                if (hasAssociatedAccounts) {
                    setResult('Cannot delete group because there are accounts associated with it');
                    return;
                }
            }
            else if (type === 'expensegroup') {
                const hasAssociatedAccounts = project.expenseaccounts.some(account => account.groupid === id);
                if (hasAssociatedAccounts) {
                    setResult('Cannot delete group because there are accounts associated with it');
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEGROUP, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove group data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        if (type === 'incomegroup') {
                            dispatch({
                                type: 'REMOVE_INCOMEGROUP',
                                incomegroupid: id
                            });
                        }
                        else if (type === 'expensegroup') {
                            dispatch({
                                type: 'REMOVE_EXPENSEGROUP',
                                expensegroupid: id
                            });
                        }
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_actions'>
                        {result && <div className='modal_result'>{result}</div>}
                        <div className='modal_buttons'>
                            <div className='modal_buttoncontainer'>
                                <button className="modal_button" onClick={() => removeGroup(id, type)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            </div>
                            <div className='modal_buttoncontainer'>
                                <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function AddAccount() {
        const [type, setType] = useState('incomeaccount');
        const [name, setName] = useState('');
        const [budget, setBudget] = useState(0);
        const [currencyid, setCurrencyid] = useState('');
        const [groupid, setGroupid] = useState('');
        const [orderid, setOrderid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addAccount = (type, name, budget, currencyid, groupid, orderid) => {
            console.log('Trying to add account');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Chart of Accounts', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !currencyid) {
                setResult('Missing info')
                return;
            }

            if (isNaN(parseFloat(budget))) {
                setResult('Invalid budget amount');
                return;
            }

            const trimmedName = name.trim();

            if (type === 'incomeaccount') {
                const isNameDuplicate = project.incomeaccounts.some(account => account.name === trimmedName);
                if (isNameDuplicate) {
                    setResult('Account with the same name already exists');
                    return;
                }
            }
            else if (type === 'expenseaccount') {
                const isNameDuplicate = project.expenseaccounts.some(account => account.name === trimmedName);
                if (isNameDuplicate) {
                    setResult('Account with the same name already exists');
                    return;
                }
            }

            if (type === 'incomeaccount') {
                const isOrderDuplicate = project.incomeaccounts.some(account =>
                    orderid !== '' &&
                    account.orderid == orderid &&
                    account.groupid === groupid
                );
                if (isOrderDuplicate) {
                    setResult('Account with the same order already exists within the same group');
                    return;
                }
            }
            else if (type === 'expenseaccount') {
                const isOrderDuplicate = project.expenseaccounts.some(account =>
                    orderid !== '' &&
                    account.orderid == orderid &&
                    account.groupid === groupid
                );
                if (isOrderDuplicate) {
                    setResult('Account with the same order already exists within the same group');
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                type: type,
                name: trimmedName,
                budget: budget,
                currencyid: currencyid,
                groupid: groupid,
                orderid: orderid
            }

            axios.post(configData.CONTROLLERURL + configData.ADDACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        if (type === 'incomeaccount') {
                            dispatch({
                                type: 'ADD_INCOMEACCOUNT',
                                incomeaccount: data
                            });
                        }
                        else if (type === 'expenseaccount') {
                            dispatch({
                                type: 'ADD_EXPENSEACCOUNT',
                                expenseaccount: data
                            });
                        }
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Acount Type</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <select
                                className='modal_select'
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option value="incomeaccount">Income Account</option>
                                <option value="expenseaccount">Expense Account</option>
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Acount Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Budget</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Budget"
                                value={budget}
                                onChange={(e) => setBudget(Number(e.target.value) || 0)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Group Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <select
                                className='modal_select'
                                value={groupid}
                                onChange={(e) => setGroupid(e.target.value)}
                            >
                                <option value="">Select Group</option>
                                {type === 'incomeaccount' && project.incomegroups.map(group => (
                                    <option key={group.id} value={group.id}>{group.name}</option>
                                ))}
                                {type === 'expenseaccount' && project.expensegroups.map(group => (
                                    <option key={group.id} value={group.id}>{group.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Order ID</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder=""
                                value={orderid}
                                onChange={(e) => setOrderid(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addAccount(type, name, budget, currencyid, groupid, orderid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditAccount({ account }) {
        const [id, setId] = useState('');

        const [type, setType] = useState('');
        const [name, setName] = useState('');
        const [budget, setBudget] = useState(0);
        const [currencyid, setCurrencyid] = useState('');
        const [groupid, setGroupid] = useState('');
        const [orderid, setOrderid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(account.id);
            setType(account.type);
            setName(account.name);
            setBudget(account.budget);
            setCurrencyid(account.currencyid);
            setGroupid(account.groupid);
            setOrderid(account.orderid);
        }, [account]);

        const updateAccount = (id, type, name, budget, currencyid, groupid, orderid) => {
            console.log('Trying to update account');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Chart of Accounts', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !currencyid) {
                setResult('Missing info')
                return;
            }

            if (isNaN(parseFloat(budget))) {
                setResult('Invalid budget amount');
                return;
            }

            const trimmedName = name.trim();

            if (type === 'incomeaccount') {
                const isNameDuplicate = project.incomeaccounts.some(account => account.name === trimmedName && account.id !== id);
                if (isNameDuplicate) {
                    setResult('Account with the same name already exists');
                    return;
                }
            }
            else if (type === 'expenseaccount') {
                const isNameDuplicate = project.expenseaccounts.some(account => account.name === trimmedName && account.id !== id);
                if (isNameDuplicate) {
                    setResult('Account with the same name already exists');
                    return;
                }
            }

            if (type === 'incomeaccount') {
                const isOrderDuplicate = project.incomeaccounts.some(account =>
                    orderid !== '' &&
                    account.orderid == orderid &&
                    account.groupid === groupid &&
                    account.id !== id
                );
                if (isOrderDuplicate) {
                    setResult('Account with the same order already exists within the same group');
                    return;
                }
            } else if (type === 'expenseaccount') {
                const isOrderDuplicate = project.expenseaccounts.some(account =>
                    orderid !== '' &&
                    account.orderid == orderid &&
                    account.groupid === groupid &&
                    account.id !== id
                );
                if (isOrderDuplicate) {
                    setResult('Account with the same order already exists within the same group');
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type,
                name: trimmedName,
                budget: budget,
                currencyid: currencyid,
                groupid: groupid,
                orderid: orderid,
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        if (type === 'incomeaccount') {
                            dispatch({
                                type: 'UPDATE_INCOMEACCOUNT',
                                incomeaccount: data
                            });
                        }
                        else if (type === 'expenseaccount') {
                            dispatch({
                                type: 'UPDATE_EXPENSEACCOUNT',
                                expenseaccount: data
                            });
                        }
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Acount Type</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <select
                                className='modal_select'
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                disabled
                            >
                                <option value="incomeaccount">Income Account</option>
                                <option value="expenseaccount">Expense Account</option>
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Acount Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Budget</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder={0}
                                value={budget}
                                onChange={(e) => setBudget(Number(e.target.value) || 0)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Group Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <select
                                className='modal_select'
                                value={groupid}
                                onChange={(e) => setGroupid(e.target.value)}
                            >
                                <option value="">Select Group</option>
                                {type === 'incomeaccount' && project.incomegroups.map(group => (
                                    <option key={group.id} value={group.id}>{group.name}</option>
                                ))}
                                {type === 'expenseaccount' && project.expensegroups.map(group => (
                                    <option key={group.id} value={group.id}>{group.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Order ID</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder=""
                                value={orderid}
                                onChange={(e) => setOrderid(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateAccount(id, type, name, budget, currencyid, groupid, orderid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButtonAccount(account)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveAccount({ account }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [type, setType] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(account.id)
            setName(account.name)
            setType(account.type)
        }, [account]);

        const removeAccount = (id, type) => {
            console.log('Trying to remove account');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Chart of Accounts', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const journalentryData = journalentryremovalprotection(project.journalentries, id, 'chartofaccounts');
            if (journalentryData.code) {
                setResult(journalentryData.message);
                return
            }

            if (type === 'expenseaccount') {
                const transactionData = transactionsremovalprotection(project.transactions, id, 'chartofaccounts');
                if (transactionData.code) {
                    setResult(transactionData.message);
                    return
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        if (type === 'incomeaccount') {
                            dispatch({
                                type: 'REMOVE_INCOMEACCOUNT',
                                incomeaccountid: id
                            });
                        }
                        else if (type === 'expenseaccount') {
                            dispatch({
                                type: 'REMOVE_EXPENSEACCOUNT',
                                expenseaccountid: id
                            });
                        }
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_actions'>
                        {result && <div className='modal_result'>{result}</div>}
                        <div className='modal_buttons'>
                            <div className='modal_buttoncontainer'>
                                <button className="modal_button" onClick={() => removeAccount(id, type)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            </div>
                            <div className='modal_buttoncontainer'>
                                <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewExpensesBudget() {
        const [loadingpdf, setLoadingpdf] = useState(false);
        const [resultpdf, setResultpdf] = useState('');

        const printRef = useRef();

        const expensebudget = getExpenseBudget(
            project.expensegroups,
            project.expenseaccounts,
            project.currencies
        );

        const saveAsPDF = async () => {
            setLoadingpdf(true);
            try {
                const projectName = project.name;

                const doc = new jsPDF();
                registerArabicFont(doc);

                const pageWidth = doc.internal.pageSize.getWidth();
                let currentY = 10;

                currentY += 10;
                doc.setFontSize(24);
                const title = 'Expenses Budget';
                const titleWidth = doc.getTextWidth(title);
                doc.text(title, (pageWidth - titleWidth) / 2, currentY);

                currentY += 10;
                doc.setFontSize(10);
                doc.text('Project Name: ' + projectName, 10, currentY);

                currentY += 10;
                const tableColumn = ['Name', 'Budget'];

                const tableData = expensebudget.map((data) => {
                    if (data.type === "expensegroup") {
                        return [
                            { content: data.name, colSpan: 2, styles: { fontStyle: 'bold' } }
                        ];
                    }
                    if (data.type === "expenseaccount") {
                        return [
                            { content: '    ' + data.name, styles: { fontStyle: 'normal' } },
                            { content: data.currencysymbol + ' ' + data.budget.toLocaleString('en-US'), styles: { fontStyle: 'normal' } },
                        ];
                    }
                });

                doc.autoTable({
                    head: [tableColumn],
                    body: tableData,
                    startY: currentY,
                    theme: 'grid',
                    headStyles: {
                        fillColor: [200, 200, 200],
                        textColor: [0, 0, 0],
                        fontStyle: 'bold',
                    },
                    bodyStyles: {
                        fontSize: 10,
                        font: 'notoarabic',
                    },
                    columnStyles: {
                        0: { halign: 'left' },
                        1: { halign: 'left' },
                    },
                    margin: { top: 10, left: 10, right: 10 },
                });

                const fileName = 'Expenses Budget ' + projectName + '.pdf';
                doc.save(fileName.replace(/\s+/g, '_').toLowerCase());
                setResultpdf("Saved Successfully");
            } catch (error) {
                console.error('Error generating PDF:', error);
                setResultpdf("Error Saving PDF");
            } finally {
                setLoadingpdf(false);
            }
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>

                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className="reports_table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Budget</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        expensebudget.map((data, index) => {
                                            if (data.type === "expensegroup") {
                                                return (
                                                    <tr key={"data" + index}>
                                                        <td colSpan={4}>
                                                            <div className="reports_tablegroup">{data.name}</div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            if (data.type === "expenseaccount") {
                                                return (
                                                    <tr key={"data" + index} className="reports_tableaccount">
                                                        <td>
                                                            <div>{data.name}</div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {data.currencysymbol + ' ' + data.budget.toLocaleString('en-US')}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={saveAsPDF}>
                                Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
                            </button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='chartaccounts'>
            <Title text='Chart of Accounts' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButtonGroup}>Add Group</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={addButtonAccount}>Add Account</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={viewExpensesBudget}>View Expenses Budget</button>
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th>Order ID</th><th>Name</th><th>Budget</th></tr></thead>
                <tbody>
                    {
                        chartaccountsdata.map((item, index) => {
                            if (item.type === 'title') {
                                return (
                                    <tr key={'title' + index}>
                                        <td colSpan={4}><div className='chartaccounts_accounttitle'>{item.name}</div></td>
                                    </tr>
                                );
                            }
                            if (item.type === 'incomegroup' || item.type === 'expensegroup') {
                                return (
                                    <tr key={'group' + index}>
                                        <td><div className='table_button' onClick={() => editButtonGroup(item)}>Edit</div></td>
                                        <td><div className='chartaccounts_group'>{item.orderid}</div></td>
                                        <td><div className='chartaccounts_group'>{item.name}</div></td>
                                        <td></td>
                                    </tr>
                                )
                            }
                            if (item.type === 'incomeaccount' || item.type === 'expenseaccount') {
                                return (
                                    <tr key={'account' + index}>
                                        <td><div className='table_button' onClick={() => editButtonAccount(item)}>Edit</div></td>
                                        <td><div>{item.orderid}</div></td>
                                        <td><div>{item.name}</div></td>
                                        <td><div>{item.currencysymbol} {item.budget.toLocaleString('en-US')}</div></td>
                                    </tr>
                                )
                            }
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default ChartAccounts;