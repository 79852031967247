export const permissionResources = [
    'Archive',
    'Project',
    'Summary',
    'Announcements',
    'Access Controls',
    'Assets',
    'Cash Accounts',
    'Chart of Accounts',
    'Clients',
    'Contracts',
    'Collections',
    'Emergency Numbers',
    'EMS Readings',
    'EMS Tanks',
    'EMS Meters',
    'Inspections',
    'Interaccount Transfers',
    'Journal Entries',
    'Purchase Invoices',
    'Reconciliations',
    'Reports',
    'Suppliers',
    'Tasks',
    'Transactions',
    'Settings',
    'Users',
    'Statement of Account',
];

export function getPermission(projectuserid, users, userid, resource, option) {
    if (projectuserid === userid) {
        return true;
    }

    let user = users.find(user => user.id === userid);

    if (!user) {
        user = users.find(user =>
            user.devices && user.devices.some(device => device.id === userid)
        );
    }

    if (!user) {
        return false;
    }

    const permissions = user.permissions[resource];
    if (!permissions) {
        return false;
    }

    switch (option) {
        case 'open':
            return !!permissions.open;
        case 'add':
            return !!permissions.add;
        case 'update':
            return !!permissions.update;
        case 'remove':
            return !!permissions.remove;
        default:
            return false;
    }
}

export function getHomePermission(projectuserid, users, userid) {
    let homePermissions = {};
    if (projectuserid === userid) {
        permissionResources.forEach(item => {
            homePermissions[item] = true;
        });
    }
    else {
        let user = users.find(user => user.id === userid) ||
            users.find(user => user.devices?.some(device => device.id === userid));

        if (user && user.permissions) {
            permissionResources.forEach(item => {
                homePermissions[item] = user.permissions[item]?.open ?? false;
            });
        }
    }

    return homePermissions;
}