import React, { useState } from 'react';
import Header from './Header.js';

import configData from './Config';
import { Helmet } from 'react-helmet';

import homepage_section1 from './Assets/homepage_section1.jpg';
import homepage_section2 from './Assets/homepage_section2.jpg';
import homepage_section3 from './Assets/homepage_section3.jpg';
import homepage_facebookicon from './Assets/homepage_facebookicon.png';
import homepage_linkedinicon from './Assets/homepage_linkedinicon.png';
import homepage_instagramicon from './Assets/homepage_instagramicon.png';
import homepage_android from './Assets/homepage_android.png';
import homepage_apple from './Assets/homepage_apple.png';

import './HomePage.css';

function Homepage({ state, setState }) {
    const [planoption, setPlanoption] = useState(1);
    const [paymentoption, setPaymentoption] = useState(1);

    const handleClose = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const handlePlan = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Plan state={state} setState={setState} />, modaltype: 'small', modaltitle: 'Plan' }))
    }

    const handleDemo = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Demo state={state} setState={setState} />, modaltype: 'small', modaltitle: 'Demo' }))
    }

    const handleApple = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AppStore state={state} setState={setState} />, modaltype: 'small', modaltitle: 'App Store' }))
    }

    function Plan() {
        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>We are only accepting Cash Payments at the moment.</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className='modal_button' onClick={handleClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function Demo() {
        const startDemo = () => {
            setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
            setState(state => ({
                ...state,
                user: {
                    userid: '669b5d6facee2',
                    usertoken: '1fdd91bb65550a8b03306c8f1e43b59e',
                    useremail: 'demouser@demo.com',
                    username: 'demo_user',
                    usertype: '0',
                }
            }))
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>Building XYZ 1344 Demo. Press Start Demo to start.</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className='modal_button' onClick={startDemo}>Start Demo</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function AppStore() {
        const handleCloseAppStore = () => {
            setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>IOS App is still under development.</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className='modal_button' onClick={handleCloseAppStore}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='homepage'>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content="Our software offers comprehensive solutions for managing properties and facilities in the cloud. Streamline property management tasks, optimize facility operations, and enhance resident experiences." />
                <meta name="keywords" content="property management, facilities management, cloud software, smart building solutions, IoT technologies" />
                <meta name="author" content="Property Dynamics" />
                <meta property="og:title" content="Property and Facilities Management Software" />
                <meta property="og:description" content="Our software offers comprehensive solutions for managing properties and facilities in the cloud." />
                <meta property="og:image" content={homepage_section1} />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Property and Facilities Management Software" />
                <meta name="twitter:description" content="Our software offers comprehensive solutions for managing properties and facilities in the cloud." />
                <meta name="twitter:image" content={homepage_section1} />
                <title>Property and Facilities Management Software</title>
            </Helmet>

            <Header state={state} setState={setState} />

            <div className='homepage_section'>
                <div className='homepage_sectionleft'>
                    <h1 className='homepage_title'>Property and Facilities Management Software</h1>
                    <p className='homepage_description'>
                        Our software offers comprehensive solutions for managing properties and facilities in the cloud.
                        With our platform, you can streamline property management tasks, optimize facility operations,
                        and enhance resident experiences.
                    </p>
                </div>
                <div className='homepage_sectionright'>
                    <div className='homepage_paymentplans'>
                        <div className="homepage_paymentplan">
                            <h3>Plan</h3>
                            <select className='homepage_paymentplanselect' value={planoption} onChange={(e) => setPlanoption(e.target.value)}>
                                <option value={1}>Basic Plan</option>
                                <option value={2}>Pro Plan</option>
                            </select>
                            <h3>Option</h3>
                            <select className='homepage_paymentplanselect' value={paymentoption} onChange={(e) => setPaymentoption(e.target.value)}>
                                <option value={1}>Monthly</option>
                                <option value={12}>Yearly</option>
                            </select>
                            {planoption == 1 && paymentoption == 1 && <p className="homepage_paymentplanprice">$4/Month</p>}
                            {planoption == 1 && paymentoption == 12 && <p className="homepage_paymentplanprice">$50/Year</p>}
                            {planoption == 2 && paymentoption == 1 && <p className="homepage_paymentplanprice">$10/Month</p>}
                            {planoption == 2 && paymentoption == 12 && <p className="homepage_paymentplanprice">$110/Year</p>}
                            {planoption == 1 &&
                                <ul className='homepage_paymentplanfeatures'>
                                    <li>Limited to 1 Building</li>
                                    <li>Multi-user Access</li>
                                    <li>User Permissions</li>
                                    <li>Property Management Accounting</li>
                                    <li>Facilities Management</li>
                                </ul>
                            }
                            {planoption == 2 &&
                                <ul className='homepage_paymentplanfeatures'>
                                    <li>Multiple Buildings</li>
                                    <li>Multi-user Access</li>
                                    <li>User Permissions</li>
                                    <li>Property Management Accounting</li>
                                    <li>Facilities Management</li>
                                </ul>
                            }
                            <button className='homepage_planbutton' onClick={handlePlan}>Start Plan</button>
                            <button className='homepage_demobutton' onClick={handleDemo}>Demo Building XYZ</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='homepage_section'>
                <div className='homepage_sectionleft'>
                    <h1 className='homepage_title'>Transform Your Facilities with Smart Solutions</h1>
                    <p className='homepage_description'>Empower your buildings with cutting-edge automation, IoT technologies, and expert facilities management services.</p>
                    <h1 className='homepage_title'>Pioneers in Intelligent Building Solutions</h1>
                    <p className='homepage_description'>With years of experience, we specialize in creating smarter, safer, and more efficient environments.</p>
                </div>
                <div className='homepage_sectionright'>
                    <img className='homepage_image' src={homepage_section1} alt="IOT" />
                </div>
            </div>

            <div className='homepage_section'>
                <div className='homepage_sectionleft'>
                    <img className='homepage_image' src={homepage_section2} alt="Building Automation" />
                </div>
                <div className='homepage_sectionright'>
                    <h1 className='homepage_title'>Building Automation</h1>
                    <p className='homepage_description'>Seamlessly integrate HVAC, lighting, and security systems.</p>
                    <h1 className='homepage_title'>IoT Transformation</h1>
                    <p className='homepage_description'>Deploy smart sensors for predictive maintenance and AI-driven decisions.</p>
                    <h1 className='homepage_title'>Facilities Management</h1>
                    <p className='homepage_description'>Streamline operations with modern tools and ensure regulatory compliance.</p>
                </div>
            </div>

            <div className='homepage_section'>
                <div className='homepage_sectionleft'>
                    <h1 className='homepage_title'>Industries We Serve</h1>
                    <ul>
                        <li>Corporate Offices: Enhance employee comfort and reduce energy costs.</li>
                        <li>Healthcare Facilities: Secure and clean environments.</li>
                        <li>Residential Complexes: Improve tenant experiences with smart homes.</li>
                        <li>Industrial Sites: Optimize production and reduce inefficiencies.</li>
                    </ul>
                </div>
                <div className='homepage_sectionright'>
                    <img className='homepage_image' src={homepage_section3} alt="Industries We Serve" />
                </div>
            </div>

            <div className='homepage_footer'>
                <div className='homepage_footercolumn'>
                    <div className='homepage_footertitle'>Contact Us</div>
                    <div className='homepage_footercontact'>Email: propertydynamicsfm@gmail.com</div>
                </div>
                <div className='homepage_footercolumn'>
                    <div className='homepage_socialicon' title="Facebook">
                        <a href={configData.FACEBOOKLINK} target="_blank" rel="noopener noreferrer">
                            <img className='homepage_facebookicon' src={homepage_facebookicon} alt="Facebook Icon" />
                        </a>
                    </div>
                    <div className='homepage_socialicon' title="LinkedIn">
                        <a href={configData.LINKEDINLINK} target="_blank" rel="noopener noreferrer">
                            <img className='homepage_linkedinicon' src={homepage_linkedinicon} alt="LinkedIn Icon" />
                        </a>
                    </div>
                    <div className='homepage_socialicon' title="Instagram">
                        <a href={configData.INSTAGRAMLINK} target="_blank" rel="noopener noreferrer">
                            <img className='homepage_instagramicon' src={homepage_instagramicon} alt="Instagram Icon" />
                        </a>
                    </div>
                </div>
                <div className='homepage_footercolumn'>
                    <div className='homepage_appicon' title="Play Store">
                        <a href={configData.ANDROIDLINK} target="_blank" rel="noopener noreferrer">
                            <img className='homepage_android' src={homepage_android} alt="Android Icon" />
                        </a>
                    </div>
                    <div className='homepage_appicon' title="Apple App">
                        <div onClick={handleApple}>
                            <img className='homepage_apple' src={homepage_apple} alt="Apple Icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Homepage;