import React, { useRef, useEffect, useState } from 'react';
import { useStateValue } from './StateProvider';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import html3pdf from 'html3pdf';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { getClientAccountSOA, getSupplierAccountSOA, getIncomeAccountSOA, getExpenseAccountSOA, getCashAccountSOA, getReceiptsSOA } from './Utils/statementaccount.js';
import { registerArabicFont } from "./Utils/registerarabicfont.js";

import './StatementAccount.css';

function StatementAccount({ state, setState, type, account }) {
    const [{ project }, dispatch] = useStateValue();

    const printRef = useRef();

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    function ClientAccountSOA() {
        const [accountid, setAccountid] = useState(account.id);
        const [accountname, setAccountname] = useState(account.name);
        const [from, setFrom] = useState(account.from);
        const [to, setTo] = useState(account.to);
        const [selectedsubaccount, setSelectedsubaccount] = useState(account.subaccount);
        const [selectedcurrency, setSelectedcurrency] = useState(account.currencyid);
        const [statementdata, setStatementdata] = useState([]);
        const [statementsummary, setStatementsummary] = useState({ currency: '', totaldebit: '', totalcredit: '', closingbalance: '' });
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const clientaccountsoa = getClientAccountSOA(
                accountid,
                project.transactions,
                project.journalentries,
                project.exchangerates,
                project.currencies,
                from,
                to,
                selectedsubaccount,
                selectedcurrency
            );
            setStatementdata(clientaccountsoa.data);
            setStatementsummary(clientaccountsoa.summary);
        }, [accountid, project.transactions, project.journalentries, project.exchangerates, project.currencies, from, to, selectedsubaccount, selectedcurrency]);

        const saveAsPDF = () => {
            setLoading(true);
            try {
                const accountName = accountname.replace(/\s+/g, '_').toLowerCase();
                const currentDate = moment().format('DD_MM_YYYY');
                const currencyName = project.currencies.find(cur => cur.id == selectedcurrency)?.name;
                const subaccountName = (project.subaccounts.find(sub => sub.id == selectedsubaccount)?.name || 'All');

                const doc = new jsPDF();
                registerArabicFont(doc);

                let currentY = 10;

                currentY += 10;
                doc.setFontSize(24);
                const pageWidth = doc.internal.pageSize.getWidth();
                const title = 'Statement of Account';
                const titleWidth = doc.getTextWidth(title);
                doc.text(title, (pageWidth - titleWidth) / 2, currentY);

                currentY += 10;
                doc.setFontSize(10);
                doc.text('Project Name: ' + project.name, 10, currentY);

                currentY += 5;
                doc.text('Account Name: ' + accountname, 10, currentY);

                currentY += 5;
                const formattedFrom = moment.unix(from).format('D/MMM/YYYY');
                const formattedTo = moment.unix(to).format('D/MMM/YYYY');
                doc.text('From: ' + formattedFrom, 10, currentY);

                currentY += 5;
                doc.text('To: ' + formattedTo, 10, currentY);

                currentY += 5;
                doc.text('Subaccount: ' + subaccountName, 10, currentY);

                currentY += 5;
                doc.text('Currency: ' + currencyName, 10, currentY);

                currentY += 5;
                const body = statementdata.map(entry => [
                    entry.date,
                    entry.type,
                    entry.id,
                    entry.name,
                    entry.debitcurrency + ' ' + entry.debitamount.toLocaleString('en-US'),
                    entry.creditcurrency + ' ' + entry.creditamount.toLocaleString('en-US'),
                    entry.balancecurrency + ' ' + entry.balanceamount.toLocaleString('en-US'),
                ]);

                body.push(
                    [
                        { content: 'Total Debit', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.totaldebit.toLocaleString('en-US'),
                    ],
                    [
                        { content: 'Total Credit', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.totalcredit.toLocaleString('en-US'),
                    ],
                    [
                        { content: 'Closing Balance', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.closingbalance.toLocaleString('en-US'),
                    ]
                );

                doc.autoTable({
                    head: [['Date', 'Type', 'ID', 'Name', 'Debit', 'Credit', 'Balance']],
                    body,
                    startY: currentY,
                    theme: 'grid',
                    headStyles: {
                        fillColor: [200, 200, 200],
                        textColor: [0, 0, 0],
                        fontStyle: 'bold',
                        halign: 'center',
                    },
                    bodyStyles: { fontSize: 8 },
                    margin: { top: 10, left: 10, right: 10 },
                });

                doc.save('soa_' + accountName + '_' + currentDate + '.pdf');
            } finally {
                setLoading(false);
            }
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Account Name</div>
                        </div>
                        <div className='modal_rowsection'>{accountname}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(account.from).format('D/MMM/YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(account.to).format('D/MMM/YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Subaccount
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedsubaccount} onChange={(e) => setSelectedsubaccount(e.target.value)}>
                                <option value={0}>All</option>
                                {
                                    project.subaccounts.map((subaccount) => (
                                        <option key={subaccount.id} value={subaccount.id}>
                                            {subaccount.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Currency
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedcurrency} onChange={(e) => setSelectedcurrency(e.target.value)}>
                                {
                                    project.currencies.map((currency) => (
                                        <option key={currency.id} value={currency.id}>
                                            {currency.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        statementdata.map((entry, index) => {
                                            return (
                                                <tr key={'entry' + index} style={{ pageBreakInside: 'avoid' }}>
                                                    <td><div>{entry.date}</div></td>
                                                    <td><div>{entry.type}</div></td>
                                                    <td><div>{entry.id}</div></td>
                                                    <td><div>{entry.name}</div></td>
                                                    <td><div>{entry.debitcurrency + ' ' + entry.debitamount.toLocaleString('en-US')}</div></td>
                                                    <td><div>{entry.creditcurrency + ' ' + entry.creditamount.toLocaleString('en-US')}</div></td>
                                                    <td><div className='statementaccount_balance'>{entry.balancecurrency + ' ' + entry.balanceamount.toLocaleString('en-US')}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot className='soa_tablefooter'>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Total Debit</td><td>{statementsummary.currency + ' ' + statementsummary.totaldebit.toLocaleString('en-US')}</td></tr>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Total Credit</td><td>{statementsummary.currency + ' ' + statementsummary.totalcredit.toLocaleString('en-US')}</td></tr>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Closing Balance</td><td>{statementsummary.currency + ' ' + statementsummary.closingbalance.toLocaleString('en-US')}</td></tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <ReactToPrint
                            trigger={() => (<button className="modal_button">Print</button>)}
                            content={() => printRef.current}
                        />
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className='modal_button' onClick={() => saveAsPDF()} disabled={loading}>{loading ? "Saving..." : "Save as PDF"}</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className='modal_button' onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function SupplierAccountSOA() {
        const [accountid, setAccountid] = useState(account.id);
        const [accountname, setAccountname] = useState(account.name);
        const [from, setFrom] = useState(account.from);
        const [to, setTo] = useState(account.to);
        const [selectedsubaccount, setSelectedsubaccount] = useState(account.subaccount);
        const [selectedcurrency, setSelectedcurrency] = useState(account.currencyid);
        const [statementdata, setStatementdata] = useState([]);
        const [statementsummary, setStatementsummary] = useState({ currency: '', totaldebit: '', totalcredit: '', closingbalance: '' });
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const supplieraccountsoa = getSupplierAccountSOA(
                accountid,
                project.transactions,
                project.journalentries,
                project.purchaseinvoices,
                project.exchangerates,
                project.currencies,
                from,
                to,
                selectedsubaccount,
                selectedcurrency
            );
            setStatementdata(supplieraccountsoa.data);
            setStatementsummary(supplieraccountsoa.summary)
        }, [accountid, project.transactions, project.journalentries, project.purchaseinvoices, project.exchangerates, project.currencies, from, to, selectedsubaccount, selectedcurrency]);

        const saveAsPDF = async () => {
            setLoading(true);
            try {
                const accountName = accountname.replace(/\s+/g, '_').toLowerCase();
                const currentDate = moment().format('DD_MM_YYYY');
                const currencyName = project.currencies.find(cur => cur.id == selectedcurrency)?.name;
                const subaccountName = (project.subaccounts.find(sub => sub.id == selectedsubaccount)?.name || 'All');

                const doc = new jsPDF();
                registerArabicFont(doc);

                let currentY = 10;

                currentY += 10;
                doc.setFontSize(24);
                const pageWidth = doc.internal.pageSize.getWidth();
                const title = 'Statement of Account';
                const titleWidth = doc.getTextWidth(title);
                doc.text(title, (pageWidth - titleWidth) / 2, currentY);

                currentY += 10;
                doc.setFontSize(10);
                doc.text('Project Name: ' + project.name, 10, currentY);

                currentY += 5;
                doc.text('Account Name: ' + accountname, 10, currentY);

                currentY += 5;
                const formattedFrom = moment.unix(from).format('D/MMM/YYYY');
                const formattedTo = moment.unix(to).format('D/MMM/YYYY');
                doc.text('From: ' + formattedFrom, 10, currentY);

                currentY += 5;
                doc.text('To: ' + formattedTo, 10, currentY);

                currentY += 5;
                doc.text('Subaccount: ' + subaccountName, 10, currentY);

                currentY += 5;
                doc.text('Currency: ' + currencyName, 10, currentY);

                currentY += 5;
                const body = statementdata.map(entry => [
                    entry.date,
                    entry.type,
                    entry.id,
                    entry.name,
                    entry.debitcurrency + ' ' + entry.debitamount.toLocaleString('en-US'),
                    entry.creditcurrency + ' ' + entry.creditamount.toLocaleString('en-US'),
                    entry.balancecurrency + ' ' + entry.balanceamount.toLocaleString('en-US'),
                ]);

                body.push(
                    [
                        { content: 'Total Debit', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.totaldebit.toLocaleString('en-US'),
                    ],
                    [
                        { content: 'Total Credit', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.totalcredit.toLocaleString('en-US'),
                    ],
                    [
                        { content: 'Closing Balance', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.closingbalance.toLocaleString('en-US'),
                    ]
                );

                doc.autoTable({
                    head: [['Date', 'Type', 'ID', 'Name', 'Debit', 'Credit', 'Balance']],
                    body,
                    startY: currentY,
                    theme: 'grid',
                    headStyles: {
                        fillColor: [200, 200, 200],
                        textColor: [0, 0, 0],
                        fontStyle: 'bold',
                        halign: 'center',
                    },
                    bodyStyles: { fontSize: 8 },
                    margin: { top: 10, left: 10, right: 10 },
                });

                doc.save('soa_' + accountName + '_' + currentDate + '.pdf');
            } finally {
                setLoading(false);
            }
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Account Name</div>
                        </div>
                        <div className='modal_rowsection'>{accountname}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(account.from).format('D/MMM/YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(account.to).format('D/MMM/YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Subaccount
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedsubaccount} onChange={(e) => setSelectedsubaccount(e.target.value)}>
                                <option value={0}>All</option>
                                {
                                    project.subaccounts.map((subaccount) => (
                                        <option key={subaccount.id} value={subaccount.id}>
                                            {subaccount.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Currency
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedcurrency} onChange={(e) => setSelectedcurrency(e.target.value)}>
                                {
                                    project.currencies.map((currency) => (
                                        <option key={currency.id} value={currency.id}>
                                            {currency.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        statementdata.map((entry, index) => {
                                            return (
                                                <tr key={'entry' + index}>
                                                    <td><div>{entry.date}</div></td>
                                                    <td><div>{entry.type}</div></td>
                                                    <td><div>{entry.id}</div></td>
                                                    <td><div>{entry.name}</div></td>
                                                    <td><div>{entry.debitcurrency + ' ' + entry.debitamount.toLocaleString('en-US')}</div></td>
                                                    <td><div>{entry.creditcurrency + ' ' + entry.creditamount.toLocaleString('en-US')}</div></td>
                                                    <td><div className='statementaccount_balance'>{entry.balancecurrency + ' ' + entry.balanceamount.toLocaleString('en-US')}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot className='soa_tablefooter'>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Total Debit</td><td>{statementsummary.currency + ' ' + statementsummary.totaldebit.toLocaleString('en-US')}</td></tr>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Total Credit</td><td>{statementsummary.currency + ' ' + statementsummary.totalcredit.toLocaleString('en-US')}</td></tr>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Closing Balance</td><td>{statementsummary.currency + ' ' + statementsummary.closingbalance.toLocaleString('en-US')}</td></tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <ReactToPrint
                            trigger={() => (<button className="modal_button">Print</button>)}
                            content={() => printRef.current}
                        />
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className='modal_button' onClick={() => saveAsPDF()} disabled={loading}>{loading ? "Saving..." : "Save as PDF"}</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className='modal_button' onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div >
        )
    }

    function IncomeAccountSOA() {
        const [accountid, setAccountid] = useState(account.id);
        const [accountname, setAccountname] = useState(account.name);
        const [from, setFrom] = useState(account.from);
        const [to, setTo] = useState(account.to);
        const [selectedsubaccount, setSelectedsubaccount] = useState(account.subaccount);
        const [selectedcurrency, setSelectedcurrency] = useState(account.currencyid);
        const [statementdata, setStatementdata] = useState([]);
        const [statementsummary, setStatementsummary] = useState({ currency: '', totaldebit: '', totalcredit: '', closingbalance: '' });
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const incomeaccountsoa = getIncomeAccountSOA(
                accountid,
                project.journalentries,
                project.exchangerates,
                project.currencies,
                from,
                to,
                selectedsubaccount,
                selectedcurrency
            );
            setStatementdata(incomeaccountsoa.data);
            setStatementsummary(incomeaccountsoa.summary);
        }, [accountid, project.journalentries, project.exchangerates, project.currencies, from, to, selectedsubaccount, selectedcurrency]);

        const saveAsPDF = async () => {
            setLoading(true);
            try {
                const accountName = accountname.replace(/\s+/g, '_').toLowerCase();
                const currentDate = moment().format('DD_MM_YYYY');
                const currencyName = project.currencies.find(cur => cur.id == selectedcurrency)?.name;

                const doc = new jsPDF();
                registerArabicFont(doc);

                let currentY = 10;

                currentY += 10;
                doc.setFontSize(24);
                const pageWidth = doc.internal.pageSize.getWidth();
                const title = 'Statement of Account';
                const titleWidth = doc.getTextWidth(title);
                doc.text(title, (pageWidth - titleWidth) / 2, currentY);

                currentY += 10;
                doc.setFontSize(10);
                doc.text('Project Name: ' + project.name, 10, currentY);

                currentY += 5;
                doc.text('Account Name: ' + accountName, 10, currentY);

                currentY += 5;
                const formattedFrom = moment.unix(from).format('D/MMM/YYYY');
                const formattedTo = moment.unix(to).format('D/MMM/YYYY');
                doc.text('From: ' + formattedFrom, 10, currentY);

                currentY += 5;
                doc.text('To: ' + formattedTo, 10, currentY);

                currentY += 5;
                doc.text('Currency: ' + currencyName, 10, currentY);

                currentY += 5;
                const body = statementdata.map(entry => [
                    entry.date,
                    entry.type,
                    entry.id,
                    entry.name,
                    entry.debitcurrency + ' ' + entry.debitamount.toLocaleString('en-US'),
                    entry.creditcurrency + ' ' + entry.creditamount.toLocaleString('en-US'),
                    entry.balancecurrency + ' ' + entry.balanceamount.toLocaleString('en-US'),
                ]);

                body.push(
                    [
                        { content: 'Total Debit', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.totaldebit.toLocaleString('en-US'),
                    ],
                    [
                        { content: 'Total Credit', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.totalcredit.toLocaleString('en-US'),
                    ],
                    [
                        { content: 'Closing Balance', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.closingbalance.toLocaleString('en-US'),
                    ]
                );

                doc.autoTable({
                    head: [['Date', 'Type', 'ID', 'Name', 'Debit', 'Credit', 'Balance']],
                    body,
                    startY: currentY,
                    theme: 'grid',
                    headStyles: {
                        fillColor: [200, 200, 200],
                        textColor: [0, 0, 0],
                        fontStyle: 'bold',
                        halign: 'center',
                    },
                    bodyStyles: { fontSize: 8 },
                    margin: { top: 10, left: 10, right: 10 },
                });

                doc.save('soa_' + accountName + '_' + currentDate + '.pdf');
            } finally {
                setLoading(false);
            }
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Account Name</div>
                        </div>
                        <div className='modal_rowsection'>{accountname}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(account.from).format('D/MMM/YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(account.to).format('D/MMM/YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Subaccount
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedsubaccount} onChange={(e) => setSelectedsubaccount(e.target.value)}>
                                <option value={0}>All</option>
                                {
                                    project.subaccounts.map((subaccount) => (
                                        <option key={subaccount.id} value={subaccount.id}>
                                            {subaccount.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Currency
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedcurrency} onChange={(e) => setSelectedcurrency(e.target.value)}>
                                {
                                    project.currencies.map((currency) => (
                                        <option key={currency.id} value={currency.id}>
                                            {currency.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        statementdata.map((entry, index) => {
                                            return (
                                                <tr key={'entry' + index}>
                                                    <td><div>{entry.date}</div></td>
                                                    <td><div>{entry.type}</div></td>
                                                    <td><div>{entry.id}</div></td>
                                                    <td><div>{entry.name}</div></td>
                                                    <td><div>{entry.debitcurrency + ' ' + entry.debitamount.toLocaleString('en-US')}</div></td>
                                                    <td><div>{entry.creditcurrency + ' ' + entry.creditamount.toLocaleString('en-US')}</div></td>
                                                    <td><div className='statementaccount_balance'>{entry.balancecurrency + ' ' + entry.balanceamount.toLocaleString('en-US')}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot className='soa_tablefooter'>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Total Debit</td><td>{statementsummary.currency + ' ' + statementsummary.totaldebit.toLocaleString('en-US')}</td></tr>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Total Credit</td><td>{statementsummary.currency + ' ' + statementsummary.totalcredit.toLocaleString('en-US')}</td></tr>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Closing Balance</td><td>{statementsummary.currency + ' ' + statementsummary.closingbalance.toLocaleString('en-US')}</td></tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <ReactToPrint
                            trigger={() => (<button className="modal_button">Print</button>)}
                            content={() => printRef.current}
                        />
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className='modal_button' onClick={() => saveAsPDF()} disabled={loading}>{loading ? "Saving..." : "Save as PDF"}</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className='modal_button' onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function ExpenseAccountSOA() {
        const [accountid, setAccountid] = useState(account.id);
        const [accountname, setAccountname] = useState(account.name);
        const [from, setFrom] = useState(account.from);
        const [to, setTo] = useState(account.to);
        const [selectedcashaccount, setSelectedcashaccount] = useState(account.cashaccount);
        const [selectedsubaccount, setSelectedsubaccount] = useState(account.subaccount);
        const [selectedcurrency, setSelectedcurrency] = useState(account.currencyid);
        const [statementdata, setStatementdata] = useState([]);
        const [statementsummary, setStatementsummary] = useState({ currency: '', totaldebit: '', totalcredit: '', closingbalance: '' });
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const expenseaccountsoa = getExpenseAccountSOA(
                accountid,
                project.transactions,
                project.journalentries,
                project.exchangerates,
                project.currencies,
                from,
                to,
                selectedcashaccount,
                selectedsubaccount,
                selectedcurrency
            );
            setStatementdata(expenseaccountsoa.data);
            setStatementsummary(expenseaccountsoa.summary);
        }, [accountid, project.transactions, project.journalentries, project.exchangerates, project.currencies, from, to, selectedcashaccount, selectedsubaccount, selectedcurrency]);

        const saveAsPDF = async () => {
            setLoading(true);
            try {
                const accountName = accountname.replace(/\s+/g, '_').toLowerCase();
                const currentDate = moment().format('DD_MM_YYYY');
                const currencyName = project.currencies.find(cur => cur.id == selectedcurrency)?.name;
                const cashaccountName = (project.cashaccounts.find(sub => sub.id == selectedcashaccount)?.name || 'All');
                const subaccountName = (project.subaccounts.find(sub => sub.id == selectedsubaccount)?.name || 'All');

                const doc = new jsPDF();
                registerArabicFont(doc);

                let currentY = 10;

                currentY += 10;
                doc.setFontSize(24);
                const pageWidth = doc.internal.pageSize.getWidth();
                const title = 'Statement of Account';
                const titleWidth = doc.getTextWidth(title);
                doc.text(title, (pageWidth - titleWidth) / 2, currentY);

                currentY += 10;
                doc.setFontSize(10);
                doc.text('Project Name: ' + project.name, 10, currentY);

                currentY += 5;
                doc.text('Account Name: ' + accountname, 10, currentY);

                currentY += 5;
                const formattedFrom = moment.unix(from).format('D/MMM/YYYY');
                const formattedTo = moment.unix(to).format('D/MMM/YYYY');
                doc.text('From: ' + formattedFrom, 10, currentY);

                currentY += 5;
                doc.text('To: ' + formattedTo, 10, currentY);

                currentY += 5;
                doc.text('Cash Account: ' + cashaccountName, 10, currentY);

                currentY += 5;
                doc.text('Subaccount: ' + subaccountName, 10, currentY);

                currentY += 5;
                doc.text('Currency: ' + currencyName, 10, currentY);

                currentY += 5;
                const body = statementdata.map(entry => [
                    entry.date,
                    entry.type,
                    entry.id,
                    entry.name,
                    entry.debitcurrency + ' ' + entry.debitamount.toLocaleString('en-US'),
                    entry.creditcurrency + ' ' + entry.creditamount.toLocaleString('en-US'),
                    entry.balancecurrency + ' ' + entry.balanceamount.toLocaleString('en-US'),
                ]);

                body.push(
                    [
                        { content: 'Total Debit', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.totaldebit.toLocaleString('en-US'),
                    ],
                    [
                        { content: 'Total Credit', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.totalcredit.toLocaleString('en-US'),
                    ],
                    [
                        { content: 'Closing Balance', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.closingbalance.toLocaleString('en-US'),
                    ]
                );

                doc.autoTable({
                    head: [['Date', 'Type', 'ID', 'Name', 'Debit', 'Credit', 'Balance']],
                    body,
                    startY: currentY,
                    theme: 'grid',
                    headStyles: {
                        fillColor: [200, 200, 200],
                        textColor: [0, 0, 0],
                        fontStyle: 'bold',
                        halign: 'center',
                    },
                    bodyStyles: { fontSize: 8 },
                    margin: { top: 10, left: 10, right: 10 },
                });

                doc.save('soa_' + accountName + '_' + currentDate + '.pdf');
            } finally {
                setLoading(false);
            }
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Account Name</div>
                        </div>
                        <div className='modal_rowsection'>{accountname}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(account.from).format('D/MMM/YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(account.to).format('D/MMM/YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Cash Account
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedcashaccount} onChange={(e) => setSelectedcashaccount(e.target.value)}>
                                <option value={0}>All</option>
                                {
                                    project.cashaccounts.map((account) => (
                                        <option key={account.id} value={account.id}>
                                            {account.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Subaccount
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedsubaccount} onChange={(e) => setSelectedsubaccount(e.target.value)}>
                                <option value={0}>All</option>
                                {
                                    project.subaccounts.map((subaccount) => (
                                        <option key={subaccount.id} value={subaccount.id}>
                                            {subaccount.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Currency
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedcurrency} onChange={(e) => setSelectedcurrency(e.target.value)}>
                                {
                                    project.currencies.map((currency) => (
                                        <option key={currency.id} value={currency.id}>
                                            {currency.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        statementdata.map((entry, index) => {
                                            return (
                                                <tr key={'entry' + index}>
                                                    <td><div>{entry.date}</div></td>
                                                    <td><div>{entry.type}</div></td>
                                                    <td><div>{entry.id}</div></td>
                                                    <td><div>{entry.name}</div></td>
                                                    <td><div>{entry.debitcurrency + ' ' + entry.debitamount.toLocaleString('en-US')}</div></td>
                                                    <td><div>{entry.creditcurrency + ' ' + entry.creditamount.toLocaleString('en-US')}</div></td>
                                                    <td><div className='statementaccount_balance'>{entry.balancecurrency + ' ' + entry.balanceamount.toLocaleString('en-US')}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot className='soa_tablefooter'>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Total Debit</td><td>{statementsummary.currency + ' ' + statementsummary.totaldebit.toLocaleString('en-US')}</td></tr>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Total Credit</td><td>{statementsummary.currency + ' ' + statementsummary.totalcredit.toLocaleString('en-US')}</td></tr>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Closing Balance</td><td>{statementsummary.currency + ' ' + statementsummary.closingbalance.toLocaleString('en-US')}</td></tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <ReactToPrint
                            trigger={() => (<button className="modal_button">Print</button>)}
                            content={() => printRef.current}
                        />
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className='modal_button' onClick={() => saveAsPDF()} disabled={loading}>{loading ? "Saving..." : "Save as PDF"}</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className='modal_button' onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function CashAccountSOA() {
        const [accountid, setAccountid] = useState(account.id);
        const [accountname, setAccountname] = useState(account.name);
        const [accountcurrencyid, setAccountcurrencyid] = useState(account.currencyid);
        const [openingbalance, setOpeningbalance] = useState(account.openingbalance);
        const [from, setFrom] = useState(account.from);
        const [to, setTo] = useState(account.to);
        const [selectedcurrency, setSelectedcurrency] = useState(account.currencyid);
        const [statementdata, setStatementdata] = useState([]);
        const [statementsummary, setStatementsummary] = useState({ currency: '', totaldebit: '', totalcredit: '', closingbalance: '' });
        const [reconciliation, setReconciliation] = useState(false);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const cashaccountsoa = getCashAccountSOA(
                accountid,
                accountcurrencyid,
                openingbalance,
                project.transactions,
                project.interaccounttransfers,
                project.currencies,
                project.reconciliations,
                project.exchangerates,
                from,
                to,
                selectedcurrency
            );
            setStatementdata(cashaccountsoa.data);
            setStatementsummary(cashaccountsoa.summary);
        }, [accountid, openingbalance, project.transactions, project.interaccounttransfers, project.currencies, project.reconciliations, project.exchangerates, from, to, selectedcurrency]);

        const saveAsPDF = async () => {
            setLoading(true);
            try {
                const accountName = accountname.replace(/\s+/g, '_').toLowerCase();
                const currentDate = moment().format('DD_MM_YYYY');
                const currencyName = project.currencies.find(cur => cur.id == selectedcurrency)?.name;

                const doc = new jsPDF();
                registerArabicFont(doc);

                let currentY = 10;

                currentY += 10;
                doc.setFontSize(24);
                const pageWidth = doc.internal.pageSize.getWidth();
                const title = 'Statement of Account';
                const titleWidth = doc.getTextWidth(title);
                doc.text(title, (pageWidth - titleWidth) / 2, currentY);

                currentY += 10;
                doc.setFontSize(10);
                doc.text('Project Name: ' + project.name, 10, currentY);

                currentY += 5;
                doc.text('Account Name: ' + accountname, 10, currentY);

                currentY += 5;
                const formattedFrom = moment.unix(from).format('D/MMM/YYYY');
                const formattedTo = moment.unix(to).format('D/MMM/YYYY');
                doc.text('From: ' + formattedFrom, 10, currentY);

                currentY += 5;
                doc.text('To: ' + formattedTo, 10, currentY);

                currentY += 5;
                doc.text('Currency: ' + currencyName, 10, currentY);

                currentY += 5;
                const body = statementdata.map(entry => [
                    entry.date,
                    entry.type,
                    entry.id,
                    entry.name,
                    entry.debitcurrency + ' ' + entry.debitamount.toLocaleString('en-US'),
                    entry.creditcurrency + ' ' + entry.creditamount.toLocaleString('en-US'),
                    entry.balancecurrency + ' ' + entry.balanceamount.toLocaleString('en-US'),
                ]);

                body.push(
                    [
                        { content: 'Total Debit', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.totaldebit.toLocaleString('en-US'),
                    ],
                    [
                        { content: 'Total Credit', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.totalcredit.toLocaleString('en-US'),
                    ],
                    [
                        { content: 'Closing Balance', colSpan: 6, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.closingbalance.toLocaleString('en-US'),
                    ]
                );

                doc.autoTable({
                    head: [['Date', 'Type', 'ID', 'Name', 'Debit', 'Credit', 'Balance']],
                    body,
                    startY: currentY,
                    theme: 'grid',
                    headStyles: {
                        fillColor: [200, 200, 200],
                        textColor: [0, 0, 0],
                        fontStyle: 'bold',
                        halign: 'center',
                    },
                    bodyStyles: { fontSize: 8 },
                    margin: { top: 10, left: 10, right: 10 },
                });

                doc.save('soa_' + accountName + '_' + currentDate + '.pdf');
            } finally {
                setLoading(false);
            }
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Account Name</div>
                        </div>
                        <div className='modal_rowsection'>{accountname}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(account.from).format('D/MMM/YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(account.to).format('D/MMM/YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Currency
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedcurrency} onChange={(e) => setSelectedcurrency(e.target.value)}>
                                {
                                    project.currencies.map((currency) => (
                                        <option key={currency.id} value={currency.id}>
                                            {currency.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <label className='modal_label' htmlFor="reconciliation">
                                Reconciliation
                            </label>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                type="checkbox"
                                id="reconciliation"
                                checked={reconciliation}
                                onChange={(e) => setReconciliation(e.target.checked)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                        {reconciliation && <th>Reconciliation</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        statementdata.map((entry, index) => {
                                            return (
                                                <tr key={'entry' + index}>
                                                    <td><div>{entry.date}</div></td>
                                                    <td><div>{entry.type}</div></td>
                                                    <td><div>{entry.id}</div></td>
                                                    <td><div>{entry.name}</div></td>
                                                    <td><div>{entry.debitcurrency + ' ' + entry.debitamount.toLocaleString('en-US')}</div></td>
                                                    <td><div>{entry.creditcurrency + ' ' + entry.creditamount.toLocaleString('en-US')}</div></td>
                                                    <td><div className='statementaccount_balance'>{entry.balancecurrency + ' ' + entry.balanceamount.toLocaleString('en-US')}</div></td>
                                                    {reconciliation && <td><div>{entry.reconciliationstatus}</div></td>}
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot className='soa_tablefooter'>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Total Debit</td><td>{statementsummary.currency + ' ' + statementsummary.totaldebit.toLocaleString('en-US')}</td>{reconciliation && <td></td>}</tr>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Total Credit</td><td>{statementsummary.currency + ' ' + statementsummary.totalcredit.toLocaleString('en-US')}</td>{reconciliation && <td></td>}</tr>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Closing Balance</td><td>{statementsummary.currency + ' ' + statementsummary.closingbalance.toLocaleString('en-US')}</td>{reconciliation && <td></td>}</tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <ReactToPrint
                            trigger={() => (<button className="modal_button">Print</button>)}
                            content={() => printRef.current}
                        />
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className='modal_button' onClick={() => saveAsPDF()} disabled={loading}>{loading ? "Saving..." : "Save as PDF"}</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className='modal_button' onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function ReceivablesSOA() {
        const [accountname, setAccountname] = useState(account.name);
        const [from, setFrom] = useState(account.from);
        const [to, setTo] = useState(account.to);
        const [selectedcashaccount, setSelectedcashaccount] = useState(account.cashaccount);
        const [selectedsubaccount, setSelectedsubaccount] = useState(account.subaccount);
        const [selectedcurrency, setSelectedcurrency] = useState(account.currencyid);
        const [statementdata, setStatementdata] = useState([]);
        const [statementsummary, setStatementsummary] = useState({ currency: '', totaldebit: '', totalcredit: '', closingbalance: '' });
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const receiptsdata = getReceiptsSOA(
                project.clients,
                project.transactions,
                project.cashaccounts,
                project.subaccounts,
                project.exchangerates,
                project.currencies,
                from,
                to,
                selectedcashaccount,
                selectedsubaccount,
                selectedcurrency
            );
            setStatementdata(receiptsdata.data);
            setStatementsummary(receiptsdata.summary);
        }, [project.clients, project.transactions, project.cashaccounts, project.subaccounts, project.exchangerates, project.currencies, from, to, selectedcashaccount, selectedsubaccount, selectedcurrency]);

        const saveAsPDF = () => {
            setLoading(true);
            try {
                const accountName = accountname.replace(/\s+/g, '_').toLowerCase();
                const currentDate = moment().format('DD_MM_YYYY');
                const currencyName = project.currencies.find(cur => cur.id == selectedcurrency)?.name;
                const cashaccountName = (project.cashaccounts.find(sub => sub.id == selectedcashaccount)?.name || 'All');
                const subaccountName = (project.subaccounts.find(sub => sub.id == selectedsubaccount)?.name || 'All');

                const doc = new jsPDF();
                registerArabicFont(doc);

                let currentY = 10;

                currentY += 10;
                doc.setFontSize(24);
                const pageWidth = doc.internal.pageSize.getWidth();
                const title = 'Statement of Account';
                const titleWidth = doc.getTextWidth(title);
                doc.text(title, (pageWidth - titleWidth) / 2, currentY);

                currentY += 10;
                doc.setFontSize(10);
                doc.text('Project Name: ' + project.name, 10, currentY);

                currentY += 5;
                doc.text('Account Name: ' + accountname, 10, currentY);

                currentY += 5;
                const formattedFrom = moment.unix(from).format('D/MMM/YYYY');
                const formattedTo = moment.unix(to).format('D/MMM/YYYY');
                doc.text('From: ' + formattedFrom, 10, currentY);

                currentY += 5;
                doc.text('To: ' + formattedTo, 10, currentY);

                currentY += 5;
                doc.text('Cash Account: ' + cashaccountName, 10, currentY);

                currentY += 5;
                doc.text('Subaccount: ' + subaccountName, 10, currentY);

                currentY += 5;
                doc.text('Currency: ' + currencyName, 10, currentY);

                currentY += 5;
                const body = statementdata.map(entry => [
                    entry.date,
                    entry.type,
                    entry.id,
                    entry.clientname,
                    entry.name,
                    entry.debitcurrency + ' ' + entry.debitamount.toLocaleString('en-US'),
                    entry.creditcurrency + ' ' + entry.creditamount.toLocaleString('en-US'),
                    entry.balancecurrency + ' ' + entry.balanceamount.toLocaleString('en-US'),
                ]);

                body.push(
                    [
                        { content: 'Total Debit', colSpan: 7, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.totaldebit.toLocaleString('en-US'),
                    ],
                    [
                        { content: 'Total Credit', colSpan: 7, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.totalcredit.toLocaleString('en-US'),
                    ],
                    [
                        { content: 'Closing Balance', colSpan: 7, styles: { halign: 'right', fontStyle: 'bold' } },
                        statementsummary.currency + ' ' + statementsummary.closingbalance.toLocaleString('en-US'),
                    ]
                );

                doc.autoTable({
                    head: [['Date', 'Type', 'ID', 'From', 'Name', 'Debit', 'Credit', 'Balance']],
                    body,
                    startY: currentY,
                    theme: 'grid',
                    headStyles: {
                        fillColor: [200, 200, 200],
                        textColor: [0, 0, 0],
                        fontStyle: 'bold',
                        halign: 'center',
                    },
                    bodyStyles: { fontSize: 8 },
                    margin: { top: 10, left: 10, right: 10 },
                });

                doc.save('soa_' + accountName + '_' + currentDate + '.pdf');
            } finally {
                setLoading(false);
            }
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Account Name</div>
                        </div>
                        <div className='modal_rowsection'>{accountname}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(account.from).format('D/MMM/YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(account.to).format('D/MMM/YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Cash Account
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedcashaccount} onChange={(e) => setSelectedcashaccount(e.target.value)}>
                                <option value={0}>All</option>
                                {
                                    project.cashaccounts.map((account) => (
                                        <option key={account.id} value={account.id}>
                                            {account.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Subaccount
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedsubaccount} onChange={(e) => setSelectedsubaccount(e.target.value)}>
                                <option value={0}>All</option>
                                {
                                    project.subaccounts.map((subaccount) => (
                                        <option key={subaccount.id} value={subaccount.id}>
                                            {subaccount.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Currency
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <select className='modal_select' value={selectedcurrency} onChange={(e) => setSelectedcurrency(e.target.value)}>
                                {
                                    project.currencies.map((currency) => (
                                        <option key={currency.id} value={currency.id}>
                                            {currency.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>ID</th>
                                        <th>From</th>
                                        <th>Name</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        statementdata.map((entry, index) => {
                                            return (
                                                <tr key={'entry' + index} style={{ pageBreakInside: 'avoid' }}>
                                                    <td><div>{entry.date}</div></td>
                                                    <td><div>{entry.type}</div></td>
                                                    <td><div>{entry.id}</div></td>
                                                    <td><div>{entry.clientname}</div></td>
                                                    <td><div>{entry.name}</div></td>
                                                    <td><div>{entry.debitcurrency + ' ' + entry.debitamount.toLocaleString('en-US')}</div></td>
                                                    <td><div>{entry.creditcurrency + ' ' + entry.creditamount.toLocaleString('en-US')}</div></td>
                                                    <td><div className='statementaccount_balance'>{entry.balancecurrency + ' ' + entry.balanceamount.toLocaleString('en-US')}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot className='soa_tablefooter'>
                                    <tr><td colSpan={5}></td><td colSpan={2}>Total Debit</td><td>{statementsummary.currency + ' ' + statementsummary.totaldebit.toLocaleString('en-US')}</td></tr>
                                    <tr><td colSpan={5}></td><td colSpan={2}>Total Credit</td><td>{statementsummary.currency + ' ' + statementsummary.totalcredit.toLocaleString('en-US')}</td></tr>
                                    <tr><td colSpan={5}></td><td colSpan={2}>Closing Balance</td><td>{statementsummary.currency + ' ' + statementsummary.closingbalance.toLocaleString('en-US')}</td></tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <ReactToPrint
                            trigger={() => (<button className="modal_button">Print</button>)}
                            content={() => printRef.current}
                        />
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className='modal_button' onClick={() => saveAsPDF()} disabled={loading}>{loading ? "Saving..." : "Save as PDF"}</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className='modal_button' onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {type === 'clients' && <ClientAccountSOA />}
            {type === 'suppliers' && <SupplierAccountSOA />}
            {type === 'incomeaccounts' && <IncomeAccountSOA />}
            {type === 'expenseaccounts' && <ExpenseAccountSOA />}
            {type === 'cashaccounts' && <CashAccountSOA />}
            {type === 'receivables' && <ReceivablesSOA />}
        </>
    );
}

export default StatementAccount;