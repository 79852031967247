import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import html3pdf from 'html3pdf';

import { getPermission } from './Utils/permissions.js';
import { isRTL } from './Utils/textparse.js';
import Select from './Components/select.js';
import Title from './Title.js';

const localInspectionItems = [
    { id: 1, name: 'Assets check', description: 'Check assets if any missing, wear and tear, require maintenance.', remarks: [] },
    { id: 2, name: 'Clients feedback', description: 'Check for complaints from clients (if any).', remarks: [] },
    { id: 3, name: 'Cleaning', description: 'Items found on stairs, parking lots, lobbies, restrooms. Check cleaning of stairs, lobbies, landscape.', remarks: [] },
    { id: 4, name: 'Compliance', description: 'Regulations and compliance to rules. Keep update to new regulations and rules.', remarks: [] },
    { id: 5, name: 'Documentation', description: 'Get all inspections, invoices or receipts from doormen.', remarks: [] },
    { id: 6, name: 'Emergency ready', description: 'Ensure that all staff are aware of emergency plans and drills in case of emergency.', remarks: [] },
    { id: 7, name: 'Environmental', description: 'Solar energy, CO2 emissions, energy wasted, recycling programs, leakages.', remarks: [] },
    { id: 8, name: 'Maintenance', description: 'Adding a check for maintenance schedules or preventive maintenance plans might ensure issues are caught early before becoming critical.', remarks: [] },
    { id: 9, name: 'Safety & Security', description: 'Check CCTV if working, check access control, check firealarm systems, check UPS systems.', remarks: [] },
    { id: 10, name: 'Staff', description: 'Doormen, cleaners, security officers, Uniforms and grooming standards.', remarks: [] }
]

function InspectionsLocalV2() {
    const [currentPage, setCurrentPage] = useState(0);
    const [remarks, setRemarks] = useState(localInspectionItems.map(() => [[]]));
    const [submitted, setSubmitted] = useState(false);

    const handleNext = () => {
        if (currentPage < localInspectionItems.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleBack = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleAddRemark = () => {
        const updatedRemarks = [...remarks];
        updatedRemarks[currentPage - 1].push('');
        setRemarks(updatedRemarks);
    };

    const handleRemarkChange = (remarkIndex, value) => {
        const updatedRemarks = [...remarks];
        updatedRemarks[currentPage - 1][remarkIndex] = value;
        setRemarks(updatedRemarks);
    };

    const handleSubmit = () => {
        setSubmitted(true);
        console.log('Submitted data:', remarks);
    };

    if (submitted) {
        return (
            <div>
                <h2>Thank you!</h2>
                <p>Your inspection has been submitted successfully.</p>
            </div>
        );
    }

    if (currentPage === 0) {
        return (
            <div>
                <h1>Welcome to the Inspection Flow</h1>
                <p>Click the Start button to begin the inspection process.</p>
                <button onClick={handleNext}>Start</button>
            </div>
        );
    }

    if (currentPage > localInspectionItems.length) {
        return (
            <div>
                <h2>Summary</h2>
                <ul>
                    {localInspectionItems.map((item, index) => (
                        <li key={item.id}>
                            <strong>{item.name}:</strong>
                            <ul>
                                {remarks[index].map((remark, remarkIndex) => (
                                    <li key={remarkIndex}>{remark || '(No remark provided)'}</li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
                <button onClick={handleBack}>Back</button>
                <button onClick={handleSubmit}>Submit</button>
            </div>
        );
    }

    const currentItem = localInspectionItems[currentPage - 1];

    return (
        <div className='modal_body'>
            <div className='modal_printable'>
                <h2>{currentItem.name}</h2>
                <p>{currentItem.description}</p>
                {remarks[currentPage - 1].map((remark, index) => (
                    <textarea
                        key={index}
                        value={remark}
                        onChange={(e) => handleRemarkChange(index, e.target.value)}
                        placeholder={`Remark ${index + 1}`}
                        style={{ display: 'block', marginBottom: '10px', width: '100%' }}
                    ></textarea>
                ))}
                <button
                    onClick={handleAddRemark}
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        backgroundColor: '#007BFF',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        width: '50px',
                        height: '50px',
                        fontSize: '20px',
                        cursor: 'pointer',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    +
                </button>
            </div>
            <div className='modal_actions'>
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <button onClick={handleBack} disabled={currentPage === 1}>Back</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button onClick={handleNext}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InspectionsLocalV2;