import React, { useState, useEffect } from 'react';
import { useStateValue } from '../StateProvider';
import axios from 'axios';
import configData from '../Config.json';
import Title from '../Title';

import {
    transactionsremovalprotection, journalentryremovalprotection, purchaseinvoicesremovalprotection,
    cashaccountsremovalprotection, subaccountsremovalprotection, exchangeratesremovalprotection,
    incomeaccountsremovalprotection, expenseaccountsremovalprotection, contractsremovalprotection
} from '../Utils/removeprotection.js'

import { getPermission } from '../Utils/permissions.js';

import '../Settings.css';

function Currency({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [result, setResult] = useState(null);

    const [makedefaultresult, setMakedefaultresult] = useState(null);
    const [makedefaultloading, setMakedefaultloading] = useState(false);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddCurrency />, modaltype: 'small', modaltitle: 'Add Currency' }));
    }

    const editButton = (currency) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditCurrency currency={currency} />, modaltype: 'small', modaltitle: 'Edit Currency' }));
    }

    const removeButton = (currency) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveCurrency currency={currency} />, modaltype: 'small', modaltitle: 'Remove Currency' }));
    }

    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Import' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!project || !project.currencies || project.currencies.length === 0) {
                setResult('No data to export');
                return;
            }

            const importedContent = JSON.stringify(project.currencies, null, 2);

            navigator.clipboard.writeText(importedContent)
                .then(() => setResult('Data copied to clipboard'))
                .catch(err => setResult('Error copying data to clipboard: ', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [newEntries, setNewEntries] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewEntries([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            if (isJSON) {
                const requiredAttributes = ["id", "name", "symbol"];
                const isValidData = data.every(entry =>
                    requiredAttributes.every(attr => entry.hasOwnProperty(attr))
                );

                if (isValidData) {
                    const newEntries = data.filter(entry => {
                        return !project.currencies.some(currency => 
                            currency.id === entry.id || 
                            currency.name === entry.name || 
                            currency.symbol === entry.symbol
                        );
                    });

                    if (newEntries.length > 0) {
                        setNewEntries(newEntries);
                        setResult('Data is valid. New entries found: ' + newEntries.length);
                    }
                    else {
                        setResult(data.length + " entries found. No new data.");
                    }
                }
                else {
                    setResult('Invalid data: Missing required attributes.');
                }
            }

            if (!isJSON) {
                setResult('Invalid format');
            }
        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');

            if (newEntries.length == 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true);
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                currencies: newEntries
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTCURRENCIES, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import chart data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_CURRENCIES',
                            currencies: newEntries
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

            setResult('Data imported successfully.');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const makeDefaultCurrency = (currencyid) => {
        console.log('Trying to make default currency');

        const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Settings', 'update');
        if (!hasPermission) {
            setResult('No permission');
            return;
        }

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            id: currencyid,
        }

        setMakedefaultloading(true);

        axios.post(configData.CONTROLLERURL + configData.DEFAULTCURRENCY, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Default currency data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    dispatch({
                        type: 'MAKE_DEFAULT_CURRENCY',
                        currencyid: currencyid
                    });
                }
                else {
                    setMakedefaultresult(res.data.data)
                }
            }
            else {
                setMakedefaultresult('Error');
            }
        }).catch((err) => {
            setMakedefaultresult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setMakedefaultloading(false);
        });
    }

    function AddCurrency() {
        const [name, setName] = useState('');
        const [symbol, setSymbol] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addCurrency = (name, symbol) => {
            console.log('Trying to add currency');

            if (!name) {
                setResult('No name');
                return
            }

            if (!symbol) {
                setResult('No symbol');
                return
            }

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Settings', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const isNameDuplicate = project.currencies.some(currency => currency.name === name);
            if (isNameDuplicate) {
                setResult('Currency with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: name,
                symbol: symbol,
            }

            axios.post(configData.CONTROLLERURL + configData.ADDCURRENCY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add currency data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_CURRENCY',
                            currency: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Name
                            </div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Symbol
                            </div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Symbol"
                                value={symbol}
                                onChange={(e) => setSymbol(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addCurrency(name, symbol)} disabled={loading}>{loading ? 'Loading...' : 'Add'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditCurrency({ currency }) {
        const [id, setId] = useState(currency.id);
        const [name, setName] = useState(currency.name);
        const [symbol, setSymbol] = useState(currency.symbol);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const updateCurrency = (id, name, symbol) => {
            console.log('Trying to update currency');

            if (!name) {
                setResult('No name');
                return
            }

            if (!symbol) {
                setResult('No symbol');
                return
            }

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Settings', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const isNameDuplicate = project.currencies.some(currency => currency.name === name && currency.id !== id);
            if (isNameDuplicate) {
                setResult('Currency with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: name,
                symbol: symbol,
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATECURRENCY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update currency data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_CURRENCY',
                            currency: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Name
                            </div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                Symbol
                            </div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Symbol"
                                value={symbol}
                                onChange={(e) => setSymbol(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateCurrency(id, name, symbol)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(currency)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveCurrency({ currency }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(currency.id)
            setName(currency.name)
        }, [currency]);

        const removeCurrency = (id) => {
            console.log('Trying to remove currency');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Settings', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const contractsData = contractsremovalprotection(project.contracts, id, 'currency');
            if (contractsData.code) {
                setResult(contractsData.message);
                return
            }

            const journalEntryData = journalentryremovalprotection(project.journalentries, id, 'currency');
            if (journalEntryData.code) {
                setResult(journalEntryData.message);
                return
            }

            const transactionData = transactionsremovalprotection(project.transactions, id, 'currency');
            if (transactionData.code) {
                setResult(transactionData.message);
                return
            }

            const purchaseinvoiceData = purchaseinvoicesremovalprotection(project.purchaseinvoices, id, 'currency');
            if (purchaseinvoiceData.code) {
                setResult(purchaseinvoiceData.message);
                return
            }

            const cashaccountData = cashaccountsremovalprotection(project.cashaccounts, id, 'currency');
            if (cashaccountData.code) {
                setResult(cashaccountData.message);
                return
            }

            const exchangerateData = exchangeratesremovalprotection(project.exchangerates, id, 'currency');
            if (exchangerateData.code) {
                setResult(exchangerateData.message);
                return
            }

            const incomeaccountData = incomeaccountsremovalprotection(project.incomeaccounts, id, 'currency');
            if (incomeaccountData.code) {
                setResult(incomeaccountData.message);
                return
            }

            const expenseaccountData = expenseaccountsremovalprotection(project.expenseaccounts, id, 'currency');
            if (expenseaccountData.code) {
                setResult(expenseaccountData.message);
                return
            }

            const subaccountData = subaccountsremovalprotection(project.subaccounts, id, 'currency');
            if (subaccountData.code) {
                setResult(subaccountData.message);
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECURRENCY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove currency data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_CURRENCY',
                            currencyid: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeCurrency(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='settings_currency'>
            <Title text='Currencies' />

            <div className='buttonscontainer'>
                <button className='button' onClick={addButton}>Add Currency</button>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th>Name</th><th>Symbol</th><th>Base</th></tr></thead>
                <tbody>
                    {
                        project.currencies.map((currency, index) => (
                            <tr key={'currency' + index}>
                                <td><div className='table_button' onClick={() => editButton(currency)}>Edit</div></td>
                                <td><div>{currency.name}</div></td>
                                <td><div>{currency.symbol}</div></td>
                                <td>
                                    <div>
                                        {
                                            project.basecurrency === currency.id ?
                                                'Default currency'
                                                :
                                                <button className="table_button" onClick={() => makeDefaultCurrency(currency.id)} disabled={makedefaultloading}>
                                                    {makedefaultloading ? 'Loading...' : 'Make default'}  {makedefaultresult && <div className='result'>{makedefaultresult}</div>}
                                                </button>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Currency;