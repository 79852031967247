import { findNearestExchangeRate } from './currency.js';

export function getCashAccounts(cashaccounts, transactions, interaccounttransfers, currencies, exchangerates) { //20240108
    const data = [];

    cashaccounts.forEach((account) => {
        const currencySymbol = currencies.find((currency) => currency.id == account.currencyid)?.symbol;

        let sum = parseFloat(account.openingbalance);

        transactions.forEach((transaction) => {
            if (transaction.to == account.id) {
                if (transaction.currencyid == account.currencyid) {
                    sum += parseFloat(transaction.amount);
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, account.currencyid);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    sum += parseFloat(transaction.amount) * exchangerate;
                }
            }
            if (transaction.from == account.id) {
                if (transaction.currencyid == account.currencyid) {
                    sum -= parseFloat(transaction.amount);
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, account.currencyid);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    sum -= parseFloat(transaction.amount) * exchangerate;
                }
            }
        });

        interaccounttransfers.forEach((transfer) => {
            if (transfer.to == account.id) {
                if (transfer.tocurrencyid == account.currencyid) {
                    sum += parseFloat(transfer.toamount);
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, transfer.ts, transfer.tocurrencyid, account.currencyid);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    sum += parseFloat(transfer.toamount) * exchangerate;
                }
            }
            if (transfer.from == account.id) {
                if (transfer.fromcurrencyid == account.currencyid) {
                    sum -= parseFloat(transfer.fromamount);
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, transfer.ts, transfer.fromcurrencyid, account.currencyid);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    sum -= parseFloat(transfer.fromamount) * exchangerate;
                }
            }
        });

        data.push({
            id: account.id,
            name: account.name,
            currencyid: account.currencyid,
            currency: currencySymbol,
            openingbalance: account.openingbalance,
            balance: sum,
        });
    });

    data.sort((a, b) => a.name.localeCompare(b.name));

    return data;
}