import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { getPermission } from './Utils/permissions.js';
import Title from './Title';
import Select from './Components/select.js';
import { parseAmount } from './Utils/textparse.js';

import './InteraccountTransfers.css';

function InteraccountTransfers({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [internaltransfers, setInternaltransfers] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const sortedTransfers = [...project.interaccounttransfers].sort((a, b) => b.ts - a.ts);
        setInternaltransfers(sortedTransfers);
    }, [project.interaccounttransfers]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addInterButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddInteraccountTransfer />, modaltype: 'large', modaltitle: 'Add Interaccount Transfer' }));
    }

    const editInterButton = (transfer) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditInteraccountTransfer transfer={transfer} />, modaltype: 'large', modaltitle: 'Edit Interaccount Transfer' }));
    }

    const removeInterButton = (transfer) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveInteraccountTransfer transfer={transfer} />, modaltype: 'small', modaltitle: 'Remove Interaccount Transfer' }));
    }

    const viewInterButton = (transfer) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewIneraccountTransfer transfer={transfer} />, modaltype: 'small', modaltitle: 'View Interaccount Transfer' }));
    }
    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!project || !project.interaccounttransfers || project.interaccounttransfers.length === 0) {
                setResult('No data to export');
                return;
            }

            const importedContent = JSON.stringify(project.interaccounttransfers, null, 2);

            navigator.clipboard.writeText(importedContent)
                .then(() => setResult('Data copied to clipboard'))
                .catch(err => setResult('Error copying data to clipboard: ', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [newEntries, setNewEntries] = useState([]);
        const [importType, setImporttype] = useState('');

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewEntries([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            if (isJSON) {
                setResult('Invalid Format')
            }

            if (!isJSON) {
                setImporttype('tsv');
                const requiredAttributes = ["Date", "From", "From Amount", "To", "To Amount", "Name"];
                let rows = importText.split('\n').map(row => row.split('\t'));

                const headers = rows.shift().map(header => header ? header.trim() : '');

                const missingHeaders = requiredAttributes.filter(attr =>
                    !headers.some(header => header.toLowerCase() === attr.toLowerCase())
                );

                if (missingHeaders.length > 0) {
                    setResult('Invalid headers detected. Missing headers: ' + missingHeaders.join(', '));
                    return;
                }

                if (rows.length === 0) {
                    setResult('No data rows detected.');
                    return;
                }

                data = rows.map(row => {
                    let entry = {};
                    headers.forEach((header, index) => {
                        entry[header] = row[index]?.trim() || '';
                    });
                    return entry;
                });

                const convertedEntries = [];

                for (let i = 0; i < data.length; i++) {
                    const entry = data[i];
                    let convertedEntry = {};

                    const fromparsedAmount = parseAmount(entry['From Amount']);
                    if (!fromparsedAmount.currency) {
                        setResult('Currency not found in amount: "' + entry['From Amount'] + '" at row ' + (i + 1));
                        return;
                    }
                    const frommatchingCurrency = project.currencies.find(currency =>
                        currency.name.toLowerCase() === fromparsedAmount.currency.toLowerCase() ||
                        currency.symbol.toLowerCase() === fromparsedAmount.currency.toLowerCase()
                    );
                    if (!frommatchingCurrency) {
                        setResult('Currency not found for "' + fromparsedAmount.currency + '" at row ' + (i + 1));
                        return;
                    }

                    const toparsedAmount = parseAmount(entry['To Amount']);
                    if (!toparsedAmount.currency) {
                        setResult('Currency not found in amount: "' + entry['To Amount'] + '" at row ' + (i + 1));
                        return;
                    }
                    const tomatchingCurrency = project.currencies.find(currency =>
                        currency.name.toLowerCase() === toparsedAmount.currency.toLowerCase() ||
                        currency.symbol.toLowerCase() === toparsedAmount.currency.toLowerCase()
                    );
                    if (!tomatchingCurrency) {
                        setResult('Currency not found for "' + toparsedAmount.currency + '" at row ' + (i + 1));
                        return;
                    }

                    convertedEntry = {
                        ts: Math.floor(new Date(entry['Date']).getTime() / 1000),
                        name: entry['Name'],
                        from: entry['From'],
                        fromamount: fromparsedAmount.amount,
                        currencyid: fromparsedAmount.currencyid,
                        to: entry['To'],
                        toamount: toparsedAmount.amount,
                        tocurrencyid: toparsedAmount.currencyid,
                    };

                    convertedEntries.push(convertedEntry);
                }

                if (convertedEntries.length > 0) {
                    setNewEntries(convertedEntries);
                    setResult('Data is valid. New entries found: ' + convertedEntries.length);
                }
                else {
                    setResult('No new data found');
                }
            }
        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');
            
            if (newEntries.length == 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true);
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                interaccounttransfers: newEntries
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTINTERACCOUNTTRANSFERS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_INTERACCOUNTTRANSFERS',
                            interaccounttransfers: newEntries
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

            setResult('Data imported successfully.');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function AddInteraccountTransfer() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [name, setName] = useState('');

        const [fromcashaccounts, setFromcashaccounts] = useState([]);
        const [fromselectedcashaccount, setFromselectedcashaccount] = useState('');
        const [fromamount, setFromamount] = useState(0);
        const [fromcurrencyid, setFromcurrencyid] = useState('');

        const [tocashaccounts, setTocashaccounts] = useState([]);
        const [toselectedcashaccount, setToselectedcashaccount] = useState('');
        const [toamount, setToamount] = useState(0);
        const [tocurrencyid, setTocurrencyid] = useState('');

        const [rate, setRate] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setFromcashaccounts(project.cashaccounts)
            setTocashaccounts(project.cashaccounts)
        }, []);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            if (fromamount && toamount) {
                const exchangeGainLoss = (toamount / fromamount).toFixed(2);
                const formattedExchangeGainLoss = parseFloat(exchangeGainLoss).toLocaleString('en-US');
                setRate(formattedExchangeGainLoss);
            } else {
                setRate('0');
            }
        }, [fromamount, toamount]);

        const addInterAccount = (ts, name, from, fromamount, fromcurrencyid, to, toamount, tocurrencyid) => {
            console.log('Trying to add interaccount');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Interaccount Transfers', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !fromselectedcashaccount || !toselectedcashaccount || isNaN(parseFloat(fromamount)) || isNaN(parseFloat(toamount)) || parseFloat(fromamount) <= 0 || parseFloat(toamount) <= 0 || !fromcurrencyid || !tocurrencyid) {
                setResult('Invalid data');
                return;
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                name: trimmedname,
                from: from,
                fromamount: fromamount,
                fromcurrencyid: fromcurrencyid,
                to: to,
                toamount: toamount,
                tocurrencyid: tocurrencyid
            }

            axios.post(configData.CONTROLLERURL + configData.ADDINTERACCOUNTTRANSFER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add interaccount data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_INTERACCOUNTTRANSFER',
                            interaccounttransfer: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={fromcashaccounts} itemid={fromselectedcashaccount} setItemid={setFromselectedcashaccount} />
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={fromamount}
                                onChange={(e) => setFromamount(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={fromcurrencyid} setItemid={setFromcurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={tocashaccounts} itemid={toselectedcashaccount} setItemid={setToselectedcashaccount} />
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={toamount}
                                onChange={(e) => setToamount(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={tocurrencyid} setItemid={setTocurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Rate</div>
                        </div>
                        <div className='modal_rowsection'>{rate}</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addInterAccount(timestamp, name, fromselectedcashaccount, fromamount, fromcurrencyid, toselectedcashaccount, toamount, tocurrencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditInteraccountTransfer({ transfer }) {
        const [id, setId] = useState('');
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [name, setName] = useState('');

        const [fromcashaccounts, setFromcashaccounts] = useState([]);
        const [fromselectedcashaccount, setFromselectedcashaccount] = useState('');
        const [fromamount, setFromamount] = useState(0);
        const [fromcurrencyid, setFromcurrencyid] = useState('');

        const [tocashaccounts, setTocashaccounts] = useState([]);
        const [toselectedcashaccount, setToselectedcashaccount] = useState('');
        const [toamount, setToamount] = useState(0);
        const [tocurrencyid, setTocurrencyid] = useState('');
        const [rate, setRate] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setFromcashaccounts(project.cashaccounts);
            setTocashaccounts(project.cashaccounts);
        }, []);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setId(transfer.id);
            setSelecteddate(new Date(transfer.ts * 1000));
            setName(transfer.name);
            setFromselectedcashaccount(transfer.from);
            setToselectedcashaccount(transfer.to);
            setFromamount(transfer.fromamount)
            setToamount(transfer.toamount)
            setFromcurrencyid(transfer.fromcurrencyid)
            setTocurrencyid(transfer.tocurrencyid);
        }, [transfer]);

        useEffect(() => {
            if (fromamount && toamount) {
                const exchangeGainLoss = (toamount / fromamount).toFixed(2);
                const formattedExchangeGainLoss = parseFloat(exchangeGainLoss).toLocaleString('en-US');
                setRate(formattedExchangeGainLoss);
            } else {
                setRate('0');
            }
        }, [fromamount, toamount]);

        const updateInterAccount = (id, ts, name, from, fromamount, fromcurrencyid, to, toamount, tocurrencyid) => {
            console.log('Trying to update interaccount');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Interaccount Transfers', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !fromselectedcashaccount || !toselectedcashaccount || isNaN(parseFloat(fromamount)) || isNaN(parseFloat(toamount)) || parseFloat(fromamount) <= 0 || parseFloat(toamount) <= 0 || !fromcurrencyid || !tocurrencyid) {
                setResult('Invalid data');
                return;
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: ts,
                name: trimmedname,
                from: from,
                to: to,
                fromamount: fromamount,
                toamount: toamount,
                fromcurrencyid: fromcurrencyid,
                tocurrencyid: tocurrencyid
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEINTERACCOUNTTRANSFER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update interaccount data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_INTERACCOUNTTRANSFER',
                            interaccounttransfer: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <input
                            className='modal_input'
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={fromcashaccounts} itemid={fromselectedcashaccount} setItemid={setFromselectedcashaccount} />
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={fromamount}
                                onChange={(e) => setFromamount(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={fromcurrencyid} setItemid={setFromcurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={tocashaccounts} itemid={toselectedcashaccount} setItemid={setToselectedcashaccount} />
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="To Amount"
                                value={toamount}
                                onChange={(e) => setToamount(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={tocurrencyid} setItemid={setTocurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Rate</div>
                        </div>
                        <div className='modal_rowsection'>{rate}</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateInterAccount(id, timestamp, name, fromselectedcashaccount, fromamount, fromcurrencyid, toselectedcashaccount, toamount, tocurrencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeInterButton(transfer)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveInteraccountTransfer({ transfer }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(transfer.id)
        }, [transfer]);

        const removeInterAccount = (id) => {
            console.log('Trying to remove interaccount');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Interaccount Transfers', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEINTERACCOUNTTRANSFER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove inter accountdata received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_INTERACCOUNTTRANSFER',
                            interaccounttransferid: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeInterAccount(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ViewIneraccountTransfer = ({ transfer }) => {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [rate, setRate] = useState('');

        const printRef = useRef();

        useEffect(() => {
            setId(transfer.id);
            setName(transfer.name);
            const exchangeGainLoss = (transfer.toamount / transfer.fromamount).toFixed(2);
            const formattedExchangeGainLoss = parseFloat(exchangeGainLoss).toLocaleString('en-US');
            setRate(formattedExchangeGainLoss);
        }, [transfer]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(transfer.ts).format('DD MMMM YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                        <div className='modal_rowsection'>{name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From</div>
                        </div>
                        <div className='modal_rowsection'>{project.cashaccounts.find(account => account.id === transfer.from)?.name || 'Account not found'}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From amount</div>
                        </div>
                        <div className='modal_rowsection'>{parseFloat(transfer.fromamount).toLocaleString('en-US')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To</div>
                        </div>
                        <div className='modal_rowsection'>{project.cashaccounts.find(account => account.id === transfer.to)?.name || 'Account not found'}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To Amount</div>
                        </div>
                        <div className='modal_rowsection'>{parseFloat(transfer.toamount).toLocaleString('en-US')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Rate</div>
                        </div>
                        <div className='modal_rowsection'>{rate}</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='interaccountstransfer'>
            <Title text='Interaccount Transfers' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addInterButton}>Add Interaccount Transfer</button>
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>From</th>
                        <th>FromAmount</th>
                        <th>To</th>
                        <th>ToAmount</th>
                        <th>Rate</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        internaltransfers.map((transfer, index) => {
                            const { cashaccounts, currencies } = project;

                            const fromAccount = cashaccounts.find(item => item.id === transfer.from) || { name: 'Account not found' };
                            const toAccount = cashaccounts.find(item => item.id === transfer.to) || { name: 'Account not found' };

                            const fromCurrencySymbol = currencies.find(currency => currency.id === transfer.fromcurrencyid)?.symbol || '';
                            const toCurrencySymbol = currencies.find(currency => currency.id === transfer.tocurrencyid)?.symbol || '';

                            const exchangeGainLoss = (transfer.toamount / transfer.fromamount).toFixed(2);
                            const formattedExchangeGainLoss = parseFloat(exchangeGainLoss).toLocaleString('en-US');

                            return (
                                <tr key={'transfer' + index}>
                                    <td><div className='table_button' onClick={() => editInterButton(transfer)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewInterButton(transfer)}>View</div></td>
                                    <td><div>{moment.unix(transfer.ts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{transfer.name}</div></td>
                                    <td><div>{fromAccount.name}</div></td>
                                    <td><div>{fromCurrencySymbol} {Number(transfer.fromamount).toLocaleString('en-US')}</div></td>
                                    <td><div>{toAccount.name}</div></td>
                                    <td><div>{toCurrencySymbol} {Number(transfer.toamount).toLocaleString('en-US')}</div></td>
                                    <td><div>{formattedExchangeGainLoss}</div></td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default InteraccountTransfers;