import { findNearestExchangeRate } from './currency.js';
import {toTwoDecimalFloat} from './textparse.js';
import moment from 'moment';

export function getClientAccountSOA(accountid, transactions, journalentries, exchangerates, currencies, from, to, subaccount, basecurrency) {
    const statementData = { data: [], summary: {} };
    const data = [];
    const summary = { totalcredit: 0, totaldebit: 0, closingbalance: 0 };

    const balancecurrencyid = basecurrency;
    const balancecurrencysymbol = currencies.find((currency) => currency.id === basecurrency)?.symbol;

    transactions.forEach((transaction) => {
        if (transaction.from === accountid && transaction.ts >= from && transaction.ts <= to) {
            if (subaccount == 0 || transaction.subaccount === subaccount) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                const debit = 0;
                const credit = toTwoDecimalFloat(parseFloat(transaction.amount))

                data.push({
                    ts: transaction.ts,
                    type: 'RCPT',
                    id: transaction.id,
                    date: date,
                    name: transaction.name,
                    debitcurrencyid: transaction.currencyid,
                    debitamount: debit,
                    creditcurrencyid: transaction.currencyid,
                    creditamount: credit
                });

            }
        }
    });

    journalentries.forEach((entry) => {
        if (entry.ts >= from && entry.ts <= to && (subaccount == 0 || entry.subaccount === subaccount)) {
            entry.rows.forEach((row) => {
                if (row.accountid === accountid) {
                    const date = moment.unix(entry.ts).format('D/MMM/YYYY');
                    const debit = toTwoDecimalFloat(parseFloat(row.debit));
                    const credit = toTwoDecimalFloat(parseFloat(row.credit))

                    data.push({
                        ts: entry.ts,
                        type: 'JV',
                        id: entry.id,
                        date: date,
                        name: row.name ? row.name : entry.name,
                        debitcurrencyid: entry.currencyid,
                        debitamount: debit,
                        creditcurrencyid: entry.currencyid,
                        creditamount: credit
                    });

                }
            });
        }
    });

    data.sort((a, b) => {
        if (a.ts === b.ts) {
            return a.ts - (b.ts + 1);
        }
        return a.ts - b.ts;
    });

    let balance = 0;
    data.forEach((entry) => {
        if (entry.debitcurrencyid !== balancecurrencyid) {
            const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.debitcurrencyid, basecurrency);
            const exchangerate = NearestExchangeRate.exchangerate;

            const debitAmount = toTwoDecimalFloat(parseFloat(entry.debitamount) * exchangerate);
            const creditAmount = toTwoDecimalFloat(parseFloat(entry.creditamount) * exchangerate);

            entry.balanceamount = balance + debitAmount - creditAmount;

            summary.totaldebit += debitAmount;
            summary.totalcredit += creditAmount;
        }
        else {
            entry.balanceamount = balance + entry.debitamount - entry.creditamount;
            summary.totaldebit += entry.debitamount;
            summary.totalcredit += entry.creditamount;
        }

        entry.debitcurrency = currencies.find((currency) => currency.id === entry.debitcurrencyid)?.symbol;
        entry.creditcurrency = currencies.find((currency) => currency.id === entry.creditcurrencyid)?.symbol;
        entry.balancecurrency = balancecurrencysymbol;
        balance = entry.balanceamount;
    });

    summary.closingbalance = summary.totaldebit - summary.totalcredit;
    summary.currency = balancecurrencysymbol;
    statementData.data = data;
    statementData.summary = summary;

    return statementData;
}

export function getSupplierAccountSOA(accountid, transactions, journalentries, purchaseinvoices, exchangerates, currencies, from, to, subaccount, basecurrency) {
    const statementData = { data: [], summary: {} };
    const data = [];
    const summary = { currency: '', totaldebit: 0, totalcredit: 0, closingbalance: 0 };

    const currency = currencies.find((currency) => currency.id === basecurrency)?.symbol || '';
    summary.currency = currency;

    transactions.forEach((transaction) => {
        if (transaction.beneficiaryid == accountid && transaction.ts >= from && transaction.ts <= to && (subaccount == 0 || transaction.subaccount === subaccount)) {
            const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
            let debit = 0;
            let credit = 0;

            if (transaction.currencyid === basecurrency) {
                credit = toTwoDecimalFloat(parseFloat(transaction.amount));
            }
            else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
                const exchangerate = NearestExchangeRate.exchangerate;
                credit = toTwoDecimalFloat(parseFloat(transaction.amount) * exchangerate);
            }

            data.push({
                ts: transaction.ts,
                date: date,
                type: 'PYMT',
                id: transaction.id,
                name: transaction.name,
                debitcurrency: currency,
                debitamount: debit,
                creditcurrency: currency,
                creditamount: credit
            });

            summary.totaldebit += debit;
            summary.totalcredit += credit;
        }
    });

    journalentries.forEach((entry) => {
        if (entry.ts >= from && entry.ts <= to && (subaccount == 0 || entry.subaccount === subaccount)) {
            entry.rows.forEach((row) => {
                if (row.accountid == accountid) {
                    const date = moment.unix(entry.ts).format('D/MMM/YYYY');
                    let debit = 0;
                    let credit = 0;

                    if (entry.currencyid === basecurrency) {
                        debit = toTwoDecimalFloat(parseFloat(row.debit));
                        credit = toTwoDecimalFloat(parseFloat(row.credit));
                    }
                    else {
                        const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, row.currencyid, basecurrency)
                        const exchangerate = NearestExchangeRate.exchangerate;
                        debit = toTwoDecimalFloat(parseFloat(row.debit) * exchangerate);
                        credit = toTwoDecimalFloat(parseFloat(row.credit) * exchangerate);
                    }

                    data.push({
                        ts: entry.ts,
                        date: date,
                        type: 'JV',
                        id: entry.id,
                        name: row.name ? row.name : entry.name,
                        debitcurrency: currency,
                        debitamount: debit,
                        creditcurrency: currency,
                        creditamount: credit
                    });

                    summary.totaldebit += debit;
                    summary.totalcredit += credit;
                }
            });
        }
    });

    purchaseinvoices.forEach((invoice) => {
        if (invoice.supplierid === accountid && invoice.ts >= from && invoice.ts <= to && (subaccount == 0 || invoice.subaccount === subaccount)) {
            const date = moment.unix(invoice.ts).format('D/MMM/YYYY');
            let debit = 0;
            let credit = 0;
            if (invoice.currencyid == basecurrency) {
                debit = toTwoDecimalFloat(parseFloat(invoice.amount));
            }
            else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, invoice.ts, invoice.currencyid, basecurrency)
                const exchangerate = NearestExchangeRate.exchangerate;
                debit = toTwoDecimalFloat(parseFloat(invoice.amount) * exchangerate)
            }

            data.push({
                ts: invoice.ts,
                date: date,
                type: 'PI',
                id: invoice.id,
                name: invoice.name,
                debitcurrency: currency,
                debitamount: debit,
                creditcurrency: currency,
                creditamount: credit
            });

            summary.totaldebit += debit;
            summary.totalcredit += credit;
        }
    });

    data.sort((a, b) => {
        return a.ts - b.ts;
    });

    let balance = 0;
    data.forEach((entry) => {
        entry.balancecurrency = entry.creditcurrency;
        entry.balanceamount = toTwoDecimalFloat(balance + entry.debitamount - entry.creditamount);
        balance = entry.balanceamount;
    });

    summary.closingbalance = balance;

    statementData.data = data;
    statementData.summary = summary;

    return statementData;
}

export function getIncomeAccountSOA(accountid, journalentries, exchangerates, currencies, from, to, subaccount, basecurrency) {
    const statementData = { data: [], summary: {} };
    const data = [];
    const summary = { currency: '', totaldebit: 0, totalcredit: 0, closingbalance: 0 };

    const currencysymbol = currencies.find((currency) => currency.id === basecurrency)?.symbol;
    summary.currency = currencysymbol;

    journalentries.forEach((entry) => {
        if (entry.ts >= from && entry.ts <= to) {
            if (subaccount == 0 || entry.subaccount === subaccount) {
                entry.rows.forEach((row) => {
                    if (row.accountid === accountid) {
                        const date = moment.unix(entry.ts).format('D/MMM/YYYY');
                        let debit = 0;
                        let credit = 0;

                        if (entry.currencyid === basecurrency) {
                            debit = toTwoDecimalFloat(parseFloat(row.debit));
                            credit = toTwoDecimalFloat(parseFloat(row.credit));
                        }
                        else {
                            const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, basecurrency);
                            const exchangerate = NearestExchangeRate.exchangerate;
                            debit = toTwoDecimalFloat(parseFloat(row.debit) * exchangerate);
                            credit = toTwoDecimalFloat(parseFloat(row.credit) * exchangerate);
                        }

                        data.push({
                            ts: entry.ts,
                            id: entry.id,
                            date: date,
                            type: 'JV',
                            name: row.name ? row.name : entry.name,
                            debitcurrency: currencysymbol,
                            debitamount: debit,
                            creditcurrency: currencysymbol,
                            creditamount: credit,
                        });

                        summary.totaldebit += debit;
                        summary.totalcredit += credit;
                    }
                });
            }
        }
    });

    data.sort((a, b) => a.ts - b.ts);

    let balance = 0;
    data.forEach((entry) => {
        entry.balancecurrency = currencysymbol;
        balance = toTwoDecimalFloat(balance + entry.creditamount - entry.debitamount);
        entry.balanceamount = balance;
    });

    summary.closingbalance = balance;

    statementData.data = data;
    statementData.summary = summary;

    return statementData;
}

export function getExpenseAccountSOA(accountid, transactions, journalentries, exchangerates, currencies, from, to, cashaccount, subaccount, basecurrency) {
    const statementData = { data: [], summary: {} };
    const data = [];
    const summary = { currency: '', totaldebit: 0, totalcredit: 0, closingbalance: 0 };

    const currencysymbol = currencies.find((currency) => currency.id === basecurrency)?.symbol;
    summary.currency = currencysymbol;

    transactions.forEach((transaction) => {
        if (transaction.to === accountid && transaction.ts >= from && transaction.ts <= to) {
            if (cashaccount == 0 || transaction.from == cashaccount) {
                if (subaccount == 0 || transaction.subaccount == subaccount) {
                    const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                    let debit = 0;
                    let credit = 0;

                    if (transaction.currencyid == basecurrency) {
                        credit = toTwoDecimalFloat(parseFloat(transaction.amount));
                    }
                    else {
                        const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
                        const exchangerate = NearestExchangeRate.exchangerate;
                        credit = toTwoDecimalFloat(parseFloat(transaction.amount) * exchangerate);
                    }

                    data.push({
                        ts: transaction.ts,
                        id: transaction.id,
                        date: date,
                        type: 'PYMT',
                        name: transaction.name,
                        debitcurrency: currencysymbol,
                        debitamount: debit,
                        creditcurrency: currencysymbol,
                        creditamount: credit
                    });

                    summary.totalcredit += credit;
                }
            }
        }
    });

    journalentries.forEach((entry) => {
        if (entry.ts >= from && entry.ts <= to) {
            if (subaccount == 0 || entry.subaccount === subaccount) {
                entry.rows.forEach((row) => {
                    if (row.accountid === accountid) {
                        const date = moment.unix(entry.ts).format('D/MMM/YYYY');
                        let debit = 0;
                        let credit = 0;

                        if (basecurrency === entry.currencyid) {
                            debit = toTwoDecimalFloat(parseFloat(row.debit));
                            credit = toTwoDecimalFloat(parseFloat(row.credit));
                        }
                        else {
                            const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, basecurrency);
                            console.log(NearestExchangeRate)
                            const exchangerate = NearestExchangeRate.exchangerate;
                            debit = toTwoDecimalFloat(parseFloat(row.debit) * exchangerate);
                            credit = toTwoDecimalFloat(parseFloat(row.credit) * exchangerate);
                        }

                        data.push({
                            ts: entry.ts,
                            id: entry.id,
                            date: date,
                            type: 'JV',
                            name: row.name ? row.name : entry.name,
                            debitcurrency: currencysymbol,
                            debitamount: credit,
                            creditcurrency: currencysymbol,
                            creditamount: debit
                        });

                        summary.totaldebit += debit;
                        summary.totalcredit += credit;
                    }
                });
            }
        }
    });

    data.sort((a, b) => {
        if (a.ts === b.ts) {
            return a.ts - (b.ts + 1);
        }
        return a.ts - b.ts;
    });

    let balance = 0;
    data.forEach((entry) => {
        entry.balancecurrency = currencysymbol;
        entry.balanceamount = toTwoDecimalFloat(balance + entry.creditamount - entry.debitamount);
        balance = entry.balanceamount;
    });

    summary.closingbalance = balance;

    statementData.data = data;
    statementData.summary = summary;

    return statementData;
}

export function getCashAccountSOA(accountid, accountcurrencyid, openingbalance, transactions, interaccounttransfers, currencies, reconciliations, exchangerates, from, to, basecurrency) { //20240524
    const statementData = { data: [], summary: {} };
    const dataArray = [];
    const summary = { currency: '', totaldebit: 0, totalcredit: 0, closingbalance: 0 };

    const currency = currencies.find((currency) => currency.id === basecurrency)?.symbol || '';
    summary.currency = currency;

    let openingBalance = parseFloat(openingbalance);

    if (openingBalance !== 0) {
        const date = moment.unix(from).format('D/MMM/YYYY');
        const isDebit = openingBalance < 0;
        if (accountcurrencyid !== basecurrency) {
            const NearestExchangeRate = findNearestExchangeRate(exchangerates, 0, accountcurrencyid, basecurrency);
            const exchangerate = NearestExchangeRate.exchangerate;
            openingBalance = toTwoDecimalFloat(exchangerate * openingBalance);
        }
        dataArray.push({
            ts: from,
            date: date,
            type: 'OPEN',
            id: '',
            name: 'Opening Balance',
            debitcurrency: currency,
            debitamount: isDebit ? 0 : openingBalance,
            creditcurrency: currency,
            creditamount: isDebit ? Math.abs(openingBalance) : 0
        });
        summary.totaldebit += isDebit ? 0 : openingBalance;
        summary.totalcredit += isDebit ? Math.abs(openingBalance) : 0;
    }

    transactions.forEach((transaction) => {
        if (transaction.ts >= from && transaction.ts <= to) {
            const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
            let debit = 0;
            let credit = 0;
            if (transaction.from == accountid) {
                if (transaction.currencyid === basecurrency) {
                    credit = toTwoDecimalFloat(parseFloat(transaction.amount));
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    credit = toTwoDecimalFloat(exchangerate * parseFloat(transaction.amount))
                }
                dataArray.push({
                    ts: transaction.ts,
                    date: date,
                    type: 'RCPT',
                    id: transaction.id,
                    name: transaction.name,
                    debitcurrency: currency,
                    debitamount: debit,
                    creditcurrency: currency,
                    creditamount: credit
                });
                summary.totaldebit += debit;
                summary.totalcredit += credit;
            }
            if (transaction.to == accountid) {
                if (transaction.currencyid === basecurrency) {
                    debit = toTwoDecimalFloat(parseFloat(transaction.amount));
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    debit = toTwoDecimalFloat(exchangerate * parseFloat(transaction.amount));
                }
                dataArray.push({
                    ts: transaction.ts,
                    date: date,
                    type: 'PYMT',
                    id: transaction.id,
                    name: transaction.name,
                    debitcurrency: currency,
                    debitamount: debit,
                    creditcurrency: currency,
                    creditamount: credit
                });
                summary.totaldebit += debit;
                summary.totalcredit += credit;
            }
        }
    });

    interaccounttransfers.forEach((transfer) => {
        const date = moment.unix(transfer.ts).format('D/MMM/YYYY');
        let debit = 0;
        let credit = 0;

        if (transfer.to === accountid) {
            if (transfer.tocurrencyid === basecurrency) {
                debit = toTwoDecimalFloat(parseFloat(transfer.toamount));
            }
            else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, transfer.ts, transfer.tocurrencyid, basecurrency);
                const exchangerate = NearestExchangeRate.exchangerate;
                debit = toTwoDecimalFloat(parseFloat(transfer.toamount) * exchangerate);
            }
        }

        if (transfer.from === accountid) {
            if (transfer.fromcurrencyid === basecurrency) {
                credit = toTwoDecimalFloat(parseFloat(transfer.fromamount));
            }
            else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, transfer.ts, transfer.fromcurrencyid, basecurrency);
                const exchangerate = NearestExchangeRate.exchangerate;
                credit = toTwoDecimalFloat(parseFloat(transfer.fromamount) * exchangerate);
            }
        }


        if (debit !== 0 || credit !== 0) {
            dataArray.push({
                ts: transfer.ts,
                date: date,
                type: 'TRNSFR',
                id: transfer.id,
                name: transfer.name,
                debitcurrency: currency,
                debitamount: debit,
                creditcurrency: currency,
                creditamount: credit
            });
            summary.totaldebit += debit;
            summary.totalcredit += credit;
        }
    });

    dataArray.sort((a, b) => {
        if (a.ts === b.ts) {
            return a.ts - (b.ts + 1);
        }
        return a.ts - b.ts;
    });

    let balance = 0;
    dataArray.forEach((entry) => {
        entry.balancecurrency = entry.creditcurrency;
        entry.balanceamount = toTwoDecimalFloat(balance - entry.creditamount + entry.debitamount);
        balance = entry.balanceamount;
    });

    summary.closingbalance = balance;

    const relevantReconciliations = reconciliations.filter(reconciliation => reconciliation.cashaccountid === accountid);
    relevantReconciliations.sort((a, b) => a.ts - b.ts);

    let reconciled = true;
    dataArray.forEach((entry) => {
        let matchingReconciliation = relevantReconciliations.find((reconciliation) => {
            return moment.unix(entry.ts).startOf('day').isSame(moment.unix(reconciliation.ts).startOf('day'));
        });

        if (matchingReconciliation) {
            if (parseFloat(entry.balanceamount) !== parseFloat(matchingReconciliation.balance)) {
                let foundMatchingBalance = dataArray.some((otherEntry) => {
                    return moment.unix(otherEntry.ts).startOf('day').isSame(moment.unix(entry.ts).startOf('day')) &&
                        parseFloat(otherEntry.balanceamount) === parseFloat(matchingReconciliation.balance);
                });

                if (!foundMatchingBalance) {
                    reconciled = false;
                }
            }
        }

        if (reconciled) {
            entry.reconciliationstatus = "Reconciled";
            if (matchingReconciliation) entry.reconciliationstatus = entry.reconciliationstatus + ' ' + matchingReconciliation.balance + entry.creditcurrency;
        }
        if (!reconciled) {
            entry.reconciliationstatus = "Not Reconciled";
            if (matchingReconciliation) entry.reconciliationstatus = entry.reconciliationstatus + ' ' + matchingReconciliation.balance + entry.creditcurrency;
        }
    });

    statementData.data = dataArray;
    statementData.summary = summary;

    return statementData;
}

export function getReceiptsSOA(clients, transactions, cashaccounts, subaccounts, exchangerates, currencies, fromts, tots, cashaccount, subaccount, basecurrency) {
    const statementData = { data: [], summary: {} };
    const data = [];
    const summary = { currency: '', totaldebit: 0, totalcredit: 0, closingbalance: 0 };

    const currency = currencies.find((currency) => currency.id === basecurrency)?.symbol || '';
    summary.currency = currency;

    let totalReceipts = 0;

    transactions.forEach((transaction) => {
        if (transaction.type === "receipt" && transaction.ts >= fromts && transaction.ts <= tots) {
            if (cashaccount == 0 || transaction.to == cashaccount) {
                if (subaccount == 0 || transaction.subaccount == subaccount) {
                    const clientname = clients.find((client) => client.id === transaction.from)?.name;
                    const cashaccountname = cashaccounts.find((cash) => cash.id === transaction.to)?.name || "No cash account";
                    const subaccountname = subaccounts.find((sub) => sub.id === transaction.subaccount)?.name || "No subaccount";
                    const date = moment.unix(transaction.ts).format("D/MMM/YYYY");

                    let debitAmount = 0;
                    let creditAmount = '0.00';
                    let debitCurrency = currency;
                    let creditCurrency = currency;

                    if (transaction.currencyid === basecurrency) {
                        debitAmount = toTwoDecimalFloat(parseFloat(transaction.amount));
                    } else {
                        const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
                        const exchangerate = NearestExchangeRate.exchangerate;
                        debitAmount = toTwoDecimalFloat(parseFloat(transaction.amount) * exchangerate);
                    }

                    totalReceipts += debitAmount;

                    data.push({
                        type: 'RCPT',
                        id: transaction.id,
                        ts: transaction.ts,
                        date: date,
                        clientname: clientname,
                        name: transaction.name,
                        cashaccount: cashaccountname,
                        subaccount: subaccountname,
                        debitcurrency: debitCurrency,
                        debitamount: debitAmount,
                        creditcurrency: creditCurrency,
                        creditamount: creditAmount,
                    });

                    summary.totaldebit += debitAmount;
                }
            }
        }
    });

    data.sort((a, b) => a.ts - b.ts);

    summary.closingbalance = totalReceipts;

    let balance = 0;
    data.forEach((entry) => {
        entry.balancecurrency = entry.debitcurrency || entry.creditcurrency;
        entry.balanceamount = toTwoDecimalFloat(balance + entry.debitamount - entry.creditamount);
        balance = entry.balanceamount;
    });

    statementData.data = data;
    statementData.summary = summary;

    return statementData;
}