export const isRTL = (text) => { //20240716
    return /[\u0600-\u06FF]/.test(text);
};

function getChecksum(value) { //20240917
    if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value);
    }
    return value;
}

export function areObjectsEqual(localObject, serverObject) { //20240917
    const changedKeys = [];

    const allKeys = new Set([...Object.keys(localObject), ...Object.keys(serverObject)]);

    for (const key of allKeys) {
        const localValue = localObject[key];
        const serverValue = serverObject[key];

        if (getChecksum(localValue) !== getChecksum(serverValue)) {
            changedKeys.push(key);
        }
    }

    return changedKeys;
}

export const isValidNumber = (value) => !isNaN(Number(value)) //20240716

export function isValidEmail(email) { //20240716
    return /\S+@\S+\.\S+/.test(email);
}

export function numberToWords(amount) { //20240716
    const converter = require('number-to-words');
    const words = converter.toWords(amount).split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
}

export const hasMoreThanTwoDecimals = (number) => { //20240716
    return (number * 1000) % 10 !== 0;
};

export function naturalCompare(a, b) {
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
}

export function toTwoDecimalFloat(amount) {
    return parseFloat(amount.toFixed(2));
}

export const parseAmount = (data) => {
    let parsedData = { currency: '', amount: 0 };

    if (data == null) {
        return parsedData;
    }

    const parts = data.trim().split(/\s+/);
    let digits = "";
    let characters = "";

    for (const part of parts) {
        if (/^[\d.,]+$/.test(part)) {
            digits = part.replace(/,/g, '');
        } else {
            characters += (characters ? " " : "") + part;
        }
    }

    const amount = parseFloat(digits);
    parsedData = {
        currency: characters.trim(),
        amount: isNaN(amount) ? 0 : amount
    };

    return parsedData;
};

export function convertSpacesToUnderscore(str) {
    return str.trim().replace(/\s+/g, '_');
}