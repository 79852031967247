import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import axios from 'axios';
import configData from './Config';

import { getPermission } from './Utils/permissions.js';
import Select from './Components/select.js';
import Title from './Title.js';
import ReactToPrint from 'react-to-print';

import './Collections.css';

const CHECKINTERVAL = 300000; // 300000 ms = 5 minutes

function Collections({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [collections, setCollections] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchCollections = () => {
            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid
            };

            axios.post(configData.CONTROLLERURL + configData.GETCOLLECTIONS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log(res.data)
                if (res.data instanceof Array) {
                    setCollections(res.data);
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
            });
        };

        fetchCollections();
        const interval = setInterval(fetchCollections, CHECKINTERVAL);
        return () => clearInterval(interval);
    }, [refresh]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addCollectionButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddCollection />, modaltype: 'small', modaltitle: 'Add Collection' }));
    }

    const addPaymentButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddPayment />, modaltype: 'small', modaltitle: 'Add Payment' }));
    }

    const removeButton = (collection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveCollection collection={collection} />, modaltype: 'small', modaltitle: 'Remove Collection' }));
    }

    const convertToTransactionReceipt = (collection, transaction) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ConvertTransactionReceipt collection={collection} transaction={transaction} />, modaltype: 'large', modaltitle: 'Add Receipt' }));
    }

    const covertToTransactionPayment = (collection, transaction) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ConvertTransactionPayment collection={collection} transaction={transaction} />, modaltype: 'large', modaltitle: 'Add Payment' }));
    }

    const editCollectionButton = (collection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditCollection collection={collection} />, modaltype: 'small', modaltitle: 'Edit Collection' }));
    }

    const editPaymentButton = (collection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditPayment collection={collection} />, modaltype: 'small', modaltitle: 'Edit Payment' }));
    }

    const viewCollectionButton = (collection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewCollection collection={collection} />, modaltype: 'small', modaltitle: 'View Collection' }));
    }

    const viewPaymentButton = (collection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewPayment collection={collection} />, modaltype: 'small', modaltitle: 'View Payment' }));
    }

    function AddCollection() {
        const [at, setAt] = useState(new Date());
        const [atts, setAtts] = useState(0);
        const [clientid, setClientid] = useState('');
        const [amount, setAmount] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [note, setNote] = useState('');
        const [description, setDescription] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setAtts(Math.floor(new Date(at).getTime() / 1000));
        }, [at]);

        const addCollection = (atts, clientid, note, description, amount, currencyid) => {
            console.log('Trying to add collection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Collections', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!clientid || amount == 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedNote = note.trim();
            const trimmedDescription = description.trim();
            const clientName = project.clients.find((client) => clientid == client.id)?.name;
            const currencySymbol = project.currencies.find((currency) => currencyid == currency.id)?.symbol;
            const currencyName = project.currencies.find((currency) => currencyid == currency.id)?.name;

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                atts: atts,
                clientid: clientid,
                clientname: clientName,
                note: trimmedNote,
                description: trimmedDescription,
                amount: amount,
                currencyid: currencyid,
                currencysymbol: currencySymbol,
                currencyname: currencyName,
            }

            axios.post(configData.CONTROLLERURL + configData.ADDCOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add colleciton data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        data.status = 'pending';
                        setCollections([...collections, data]);
                        handleCloseModal();
                        setRefresh(!refresh);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={at}
                                onChange={(date) => setAt(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Client</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.clients} itemid={clientid} setItemid={setClientid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Amount</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Note (for collector)</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Note"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addCollection(atts, clientid, note, description, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function AddPayment() {
        const [at, setAt] = useState(new Date());
        const [atts, setAtts] = useState(0);
        const [supplierid, setSupplierid] = useState('');
        const [amount, setAmount] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [note, setNote] = useState('');
        const [description, setDescription] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setAtts(Math.floor(new Date(at).getTime() / 1000));
        }, [at]);

        const addPayment = (atts, supplierid, note, description, amount, currencyid) => {
            console.log('Trying to add payment');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Collections', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!supplierid || amount == 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedNote = note.trim();
            const trimmedDescription = description.trim();
            const supplierName = project.suppliers.find((supplier) => supplierid == supplier.id)?.name;
            const currencySymbol = project.currencies.find((currency) => currencyid == currency.id)?.symbol;
            const currencyName = project.currencies.find((currency) => currencyid == currency.id)?.name;

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                atts: atts,
                supplierid: supplierid,
                suppliername: supplierName,
                note: trimmedNote,
                description: trimmedDescription,
                amount: amount,
                currencyid: currencyid,
                currencysymbol: currencySymbol,
                currencyname: currencyName,
            }
            
            axios.post(configData.CONTROLLERURL + configData.ADDPAYMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add collection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        data.status = 'pending';
                        setCollections([...collections, data]);
                        handleCloseModal();
                        setRefresh(!refresh);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={at}
                                onChange={(date) => setAt(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Supplier</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.suppliers} itemid={supplierid} setItemid={setSupplierid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Amount</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Note (for collector)</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Note"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addPayment(atts, supplierid, note, description, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditCollection({ collection }) {
        const [id, setId] = useState('');

        const [at, setAt] = useState(new Date());
        const [atts, setAtts] = useState(0);

        const [clientid, setClientid] = useState('');
        const [note, setNote] = useState('');
        const [description, setDescription] = useState('');
        const [amount, setAmount] = useState('');
        const [currencyid, setCurrencyid] = useState([]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(collection.id);
            setAt(new Date(collection.atts * 1000))
            setAtts(collection.atts);
            setClientid(collection.clientid);
            setNote(collection.note);
            setDescription(collection.description);
            setAmount(collection.amount);
            setCurrencyid(collection.currencyid);
        }, [collection]);

        useEffect(() => {
            setAtts(Math.floor(new Date(at).getTime() / 1000));
        }, [at]);

        const updateCollection = (id, atts, clientid, note, description, amount, currencyid) => {
            console.log('Trying to update collection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Collections', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!clientid || amount == 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedNote = note.trim();
            const trimmedDescription = description.trim();
            const clientName = project.clients.find((client) => clientid == client.id)?.name;
            const currencySymbol = project.currencies.find((currency) => currencyid == currency.id)?.symbol;
            const currencyName = project.currencies.find((currency) => currencyid == currency.id)?.name;

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                atts: atts,
                clientid: clientid,
                clientname: clientName,
                note: trimmedNote,
                description: trimmedDescription,
                amount: amount,
                currencyid: currencyid,
                currencysymbol: currencySymbol,
                currencyname: currencyName,
                status: 'pending'
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATECOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update collection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        let newCollections = collections.map(collection =>
                            collection.id === data.id ? data : collection
                        )
                        setCollections(newCollections);
                        handleCloseModal();
                        setRefresh(!refresh);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={at}
                                onChange={(date) => setAt(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Client</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.clients} itemid={clientid} setItemid={setClientid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Amount</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Note (for collector)</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Note"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateCollection(id, atts, clientid, note, description, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(collection)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditPayment({ collection }) {
        const [id, setId] = useState('');

        const [at, setAt] = useState(new Date());
        const [atts, setAtts] = useState(0);

        const [supplierid, setSupplierid] = useState('');
        const [note, setNote] = useState('');
        const [description, setDescription] = useState('');
        const [amount, setAmount] = useState('');
        const [currencyid, setCurrencyid] = useState([]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(collection.id);
            setAt(new Date(collection.atts * 1000))
            setAtts(collection.atts);
            setSupplierid(collection.supplierid);
            setNote(collection.note);
            setDescription(collection.description);
            setAmount(collection.amount);
            setCurrencyid(collection.currencyid);
        }, [collection]);

        useEffect(() => {
            setAtts(Math.floor(new Date(at).getTime() / 1000));
        }, [at]);

        const updateCollection = (id, atts, supplierid, note, description, amount, currencyid) => {
            console.log('Trying to update collection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Collections', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!supplierid || amount == 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedNote = note.trim();
            const trimmedDescription = description.trim();
            const supplierName = project.suppliers.find((client) => supplierid == client.id)?.name;
            const currencySymbol = project.currencies.find((currency) => currencyid == currency.id)?.symbol;
            const currencyName = project.currencies.find((currency) => currencyid == currency.id)?.name;

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                atts: atts,
                supplierid: supplierid,
                suppliername: supplierName,
                note: trimmedNote,
                description: trimmedDescription,
                amount: amount,
                currencyid: currencyid,
                currencysymbol: currencySymbol,
                currencyname: currencyName,
                status: 'pending'
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEPAYMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update collection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        let newCollections = collections.map(collection =>
                            collection.id === data.id ? data : collection
                        )
                        setCollections(newCollections);
                        handleCloseModal();
                        setRefresh(!refresh);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={at}
                                onChange={(date) => setAt(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Supplier</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.suppliers} supplierid={supplierid} setItemid={setSupplierid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Amount</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <Select currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Note (for collector)</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Note"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateCollection(id, atts, supplierid, note, description, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(collection)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ConvertTransactionReceipt({ collection, transaction }) {
        const [collectionid, setCollectionid] = useState('');
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimestamp] = useState(0);

        const [name, setName] = useState('');

        const [selectedclient, setSelectedclient] = useState('');
        const [selectedaccount, setSelectedaccount] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setCollectionid(collection.collectionid);
            setName(transaction.name);
            setSelectedclient(transaction.clientid);
            setSelectedaccount(transaction.selectedaccount);
            setAmount(transaction.amount);
            setCurrencyid(transaction.currencyid)
        }, [transaction]);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const addReceipt = () => {
            console.log('Trying to add transaction');
            setResult(null);

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Transactions', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !selectedclient || !selectedsubaccount || !selectedaccount || !currencyid || amount === 0) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                name: trimmedName,
                from: selectedclient,
                to: selectedaccount,
                subaccount: selectedsubaccount,
                beneficiaryid: '',
                amount: amount,
                currencyid: currencyid,
                type: 'receipt'
            }

            axios.post(configData.CONTROLLERURL + configData.ADDTRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_TRANSACTION',
                            transaction: data
                        });
                        removeCollection(collectionid);
                        handleCloseModal();
                        setRefresh(!refresh);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const removeCollection = (id) => {
            console.log('Trying to remove collection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Collections', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove collection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        let newCollections = collections.filter(collection => collection.id !== id)
                        setCollections(newCollections);
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Client</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.clients} itemid={selectedclient} setItemid={(clientId) => setSelectedclient(clientId)} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.subaccounts} itemid={selectedsubaccount} setItemid={setSelectedSubaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Cash Account</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.cashaccounts} itemid={selectedaccount} setItemid={setSelectedaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Amount</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addReceipt()} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ConvertTransactionPayment({ collection, transaction }) {
        const [collectionid, setCollectionid] = useState('');
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimestamp] = useState(0);

        const [name, setName] = useState('');

        const [selectedexpense, setSelectedexpense] = useState('');
        const [selectedsupplier, setSelectedsupplier] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [selectedaccount, setSelectedaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setCollectionid(collection.collectionid);
            setName(transaction.name);
            setSelectedsupplier(transaction.supplierid);
            setSelectedaccount(transaction.selectedaccount);
            setAmount(transaction.amount);
            setCurrencyid(transaction.currencyid)
        }, [transaction]);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const addPayment = () => {
            console.log('Trying to add transaction');
            setResult(null);

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Transactions', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !selectedaccount || !selectedexpense || !selectedsupplier || !selectedsubaccount || amount === 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                name: trimmedName,
                from: selectedaccount,
                to: selectedexpense,
                beneficiaryid: selectedsupplier,
                subaccount: selectedsubaccount,
                amount: amount,
                currencyid: currencyid,
                type: 'payment'
            }
            
            axios.post(configData.CONTROLLERURL + configData.ADDTRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_TRANSACTION',
                            transaction: data
                        });
                        removeCollection(collectionid);
                        handleCloseModal();
                        setRefresh(!refresh);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const removeCollection = (id) => {
            console.log('Trying to remove collection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Collections', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove collection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        let newCollections = collections.filter(collection => collection.id !== id)
                        setCollections(newCollections);
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Cash Account</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.cashaccounts} itemid={selectedaccount} setItemid={setSelectedaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Expense Account</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.expenseaccounts} itemid={selectedexpense} setItemid={setSelectedexpense} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Supplier</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.suppliers} itemid={selectedsupplier} setItemid={(supplierId) => setSelectedsupplier(supplierId)} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.subaccounts} itemid={selectedsubaccount} setItemid={setSelectedSubaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Amount</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addPayment()} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveCollection({ collection }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(collection.id);
        }, [collection]);

        const removeCollection = (id) => {
            console.log('Trying to remove collection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Collections', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove collection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        let newCollections = collections.filter(collection => collection.id !== id)
                        setCollections(newCollections);
                        handleCloseModal();
                        setRefresh(!refresh);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeCollection(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewCollection({ collection }) {
        const [id, setId] = useState('');
        const [ts, setTs] = useState('');
        const [atts, setAtts] = useState('');
        const [completedts, setCompletedts] = useState('');
        const [clientid, setClientid] = useState('');
        const [note, setNote] = useState('');
        const [description, setDescription] = useState('');
        const [amount, setAmount] = useState('');
        const [currencyid, setCurrencyid] = useState('');

        const printRef = useRef();

        useEffect(() => {
            setId(collection.id)
            setTs(collection.ts);
            setAtts(collection.atts);
            setCompletedts(collection.completedts);
            setClientid(collection.clientid);
            setNote(collection.note);
            setDescription(collection.description);
            setAmount(collection.amount);
            setCurrencyid(collection.currencyid);
        }, [collection]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Collection ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Created</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(ts).format('dddd, D MMM YYYY hh:mm:ss A')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Due</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(atts).format('dddd')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Completed</div>
                        </div>
                        <div className='modal_rowsection'>{completedts ? moment.unix(completedts).format('dddd, D MMM YYYY hh:mm:ss A') : 'Not completed'}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Client Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.clients.find((client) => client.id === collection.clientid)?.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Amount</div>
                        </div>
                        <div className='modal_rowsection'>{project.currencies.find((currency) => currency.id === collection.currencyid)?.symbol + ' ' + parseFloat(collection.amount).toLocaleString('en-US')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_description'>{description}</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Note</div>
                        </div>
                        <div className='modal_rowsection'>{note}</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => convertToTransactionReceipt({ collectionid: id }, { ts: '', name: description, clientid: clientid, subaccount: '', cashaccounts: project.cashaccounts, amount: amount, currencyid: currencyid })}>Convert to transaction receipt</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewPayment({ collection }) {
        const [id, setId] = useState('');
        const [ts, setTs] = useState('');
        const [atts, setAtts] = useState('');
        const [completedts, setCompletedts] = useState('');
        const [supplierid, setSupplierid] = useState('');
        const [note, setNote] = useState('');
        const [description, setDescription] = useState('');
        const [amount, setAmount] = useState('');
        const [currencyid, setCurrencyid] = useState('');

        const printRef = useRef();

        useEffect(() => {
            setId(collection.id)
            setTs(collection.ts);
            setAtts(collection.atts);
            setCompletedts(collection.completedts);
            setSupplierid(collection.supplierid);
            setNote(collection.note);
            setDescription(collection.description);
            setAmount(collection.amount);
            setCurrencyid(collection.currencyid);
        }, [collection]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Payment ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(ts).format('dddd, D MMM YYYY hh:mm:ss A')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Due</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(atts).format('dddd')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Completed</div>
                        </div>
                        <div className='modal_rowsection'>{completedts ? moment.unix(completedts).format('dddd, D MMM YYYY hh:mm:ss A') : 'Not completed'}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Supplier</div>
                        </div>
                        <div className='modal_rowsection'>{project.suppliers.find((supplier) => supplier.id === collection.supplierid)?.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Amount</div>
                        </div>
                        <div className='modal_rowsection'>{project.currencies.find((currency) => currency.id === collection.currencyid)?.symbol + ' ' + parseFloat(collection.amount).toLocaleString('en-US')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_description'>{description}</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Note</div>
                        </div>
                        <div className='modal_rowsection'>{note}</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => covertToTransactionPayment({ collectionid: id }, { ts: '', name: description, supplierid: supplierid, subaccount: '', cashaccounts: project.cashaccounts, amount: amount, currencyid: currencyid })}>Convert to transaction payment</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='collections'>
            <Title text='Collections' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addCollectionButton}>Add Collection</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={addPaymentButton}>Add Payment</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Created</th>
                        <th>Due</th>
                        <th>Completed</th>
                        <th>Client</th>
                        <th>Note</th>
                        <th>Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        collections.sort((a, b) => b.ts - a.ts).map((collection, index) => {
                            const isDue = moment().unix() < moment.unix(collection.atts).endOf('day').unix();
                            const currencySymbol = project.currencies.find((currency) => currency.id === collection.currencyid)?.symbol;

                            if (collection.type == 'receipt') {
                                const clientName = project.clients.find((client) => client.id === collection.clientid)?.name;
                                return (
                                    <tr key={'collection' + index} className='collections_receipt'>
                                        <td><div className='table_button' onClick={() => editCollectionButton(collection)}>Edit</div></td>
                                        <td><div className='table_button' onClick={() => viewCollectionButton(collection)}>View</div></td>
                                        <td><div>{moment.unix(collection.ts).format('dddd, D MMM YYYY hh:mm:ss A')}</div></td>
                                        <td><div>{moment.unix(collection.atts).format('dddd')}</div></td>
                                        <td><div>{collection.completedts ? moment.unix(collection.completedts).format('dddd, D MMM YYYY hh:mm:ss A') : null}</div></td>
                                        <td><div>{clientName}</div></td>
                                        <td><div>{collection.note}</div></td>
                                        <td><div>{currencySymbol + ' ' + parseFloat(collection.amount).toLocaleString('en-US')}</div></td>
                                        {collection.status === 'pending' && isDue && <td><div className='collections_statuspending'>pending</div></td>}
                                        {collection.status === 'pending' && !isDue && <td><div className='collections_statusnotdue'>not due</div></td>}
                                        {collection.status === 'received' && <td><div className='collections_statusreceived'>received</div></td>}
                                    </tr>
                                );
                            }
                            if (collection.type == 'payment') {
                                const supplierName = project.suppliers.find((supplier) => supplier.id === collection.supplierid)?.name;
                                return (
                                    <tr key={'collection' + index} className='collections_payment'>
                                        <td><div className='table_button' onClick={() => editPaymentButton(collection)}>Edit</div></td>
                                        <td><div className='table_button' onClick={() => viewPaymentButton(collection)}>View</div></td>
                                        <td><div>{moment.unix(collection.ts).format('dddd, D MMM YYYY hh:mm:ss A')}</div></td>
                                        <td><div>{moment.unix(collection.atts).format('dddd')}</div></td>
                                        <td><div>{collection.completedts ? moment.unix(collection.completedts).format('dddd, D MMM YYYY hh:mm:ss A') : null}</div></td>
                                        <td><div>{supplierName}</div></td>
                                        <td><div>{collection.note}</div></td>
                                        <td><div>{currencySymbol + ' ' + parseFloat(collection.amount).toLocaleString('en-US')}</div></td>
                                        {collection.status === 'pending' && isDue && <td><div className='collections_statuspending'>pending</div></td>}
                                        {collection.status === 'pending' && !isDue && <td><div className='collections_statusnotdue'>not due</div></td>}
                                        {collection.status === 'received' && <td><div className='collections_statusreceived'>received</div></td>}
                                    </tr>
                                );
                            }
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Collections;