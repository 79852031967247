import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import ReactToPrint from 'react-to-print';

import { inspectionsremovalprotection, emsreadingsremovalprotection, emstanksremovalprotection, emsmetersremovalprotection, accesscontrolremovalprotection } from './Utils/removeprotection.js';
import { getPermission } from './Utils/permissions.js';
import Title from './Title';

import './Assets.css';

function Assets({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredassets, setFilteredassets] = useState([]);
    const [search, setSearch] = useState('');

    const assetTypes = [
        { value: "accesscontrol", label: "Access Control" },
        { value: "boilersystem", label: "Boiler System" },
        { value: "commonareas", label: "Common Areas" },
        { value: "firextinguisher", label: "Fire Extinguisher" },
        { value: "generator", label: "Generator" },
        { value: "lift", label: "Lift" },
        { value: "meter", label: "Meter" },
        { value: "tank", label: "Tank" },
        { value: "filtrationsystem", label: "Filtration System" },
        { value: "videophone", label: "Video Phone" }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    useEffect(() => {
        const filtered = project.assets.filter((asset) =>
            asset.name.toLowerCase().includes(search.toLowerCase()) ||
            asset.location.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredassets(filtered);
    }, [project, search]);

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddAsset />, modaltype: 'small', modaltitle: 'Add Asset' }));
    }

    const editButton = (asset) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditAsset asset={asset} />, modaltype: 'small', modaltitle: 'Edit Asset' }));
    }

    const removeButton = (asset) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveAsset asset={asset} />, modaltype: 'small', modaltitle: 'Remove Asset' }));
    }

    const viewButton = (asset) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewAsset asset={asset} />, modaltype: 'small', modaltitle: 'View Asset' }));
    }

    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!project || !project.assets || project.assets.length === 0) {
                setResult('No data to export');
                return;
            }

            const importedContent = JSON.stringify(project.assets, null, 2);

            navigator.clipboard.writeText(importedContent)
                .then(() => setResult('Data copied to clipboard'))
                .catch(err => setResult('Error copying data to clipboard: ', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [newEntries, setNewEntries] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewEntries([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            if (isJSON) {
                const requiredAttributes = ["id", "type", "name", "location"];
                const isValidData = data.every(entry =>
                    requiredAttributes.every(attr => entry.hasOwnProperty(attr))
                );

                if (isValidData) {
                    const newEntries = data.filter(entry => {
                        return !project.assets.some(asset => asset.id === entry.id);
                    });

                    if (newEntries.length > 0) {
                        setNewEntries(newEntries);
                        setResult('Data is valid. New entries found: ' + newEntries.length);
                    }
                    else {
                        setResult(data.length + " entries found. No new data.");
                    }
                }
                else {
                    setResult('Invalid data: Missing required attributes.');
                }
            }

            if (!isJSON) {
                setResult('Invalid format');
            }
        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');

            if (newEntries.length == 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true);
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                assets: newEntries
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTASSETS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_ASSETS',
                            assets: newEntries
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            })

            setResult('Import successful');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function AddAsset() {
        const [type, setType] = useState('');
        const [name, setName] = useState('');
        const [location, setLocation] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            if (assetTypes.length > 0) {
                setType(assetTypes[0].value);
            }
        }, [assetTypes]);

        const addAsset = (type, name, location) => {
            console.log('Trying to add asset');
            setResult(null)

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Assets', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!type || !name) {
                setResult('Missing data');
                return;
            }

            const trimmedName = name.trim();
            const trimmedLocation = location.trim();

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                type: type,
                name: trimmedName,
                location: trimmedLocation
            }

            axios.post(configData.CONTROLLERURL + configData.ADDASSET, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add asset data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_ASSET',
                            asset: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }


        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <select
                                className='modal_select'
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                {assetTypes.map((asset, index) => (
                                    <option key={index} value={asset.value}>
                                        {asset.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Location</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal_actions">
                    {result && <div className='modal_result'>{result}</div>}
                    <div className="modal_buttons">
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addAsset(type, name, location)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditAsset({ asset }) {
        const [id, setId] = useState('');
        const [type, setType] = useState('');
        const [name, setName] = useState('');
        const [location, setLocation] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(asset.id)
            setType(asset.type)
            setName(asset.name)
            setLocation(asset.location)
        }, [asset]);

        const updateAsset = (id, type, name, location) => {
            console.log('Trying to update asset');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Assets', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!type, !name) {
                setResult('Missing data');
                return;
            }

            const trimmedName = name.trim();
            const trimmedLocation = location.trim();

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type,
                name: trimmedName,
                location: trimmedLocation
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEASSET, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update asset data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_ASSET',
                            asset: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <select
                                className='modal_select'
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                {assetTypes.map((asset, index) => (
                                    <option key={index} value={asset.value}>
                                        {asset.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Location</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal_actions">
                    {result && <div className='modal_result'>{result}</div>}
                    <div className="modal_buttons">
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateAsset(id, type, name, location)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(asset)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveAsset({ asset }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(asset.id)
        }, [asset]);

        const removeAsset = (id) => {
            console.log('Trying to remove asset');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Assets', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const inspectionData = inspectionsremovalprotection(project.inspections, id, 'assets');
            if (inspectionData.code) {
                setResult(inspectionData.message);
                return
            }

            const emsreadingsData = emsreadingsremovalprotection(project.readings, id, 'assets');
            if (emsreadingsData.code) {
                setResult(emsreadingsData.message);
                return
            }

            const emstanksData = emstanksremovalprotection(project.tanks, id, 'assets');
            if (emstanksData.code) {
                setResult(emstanksData.message);
                return
            }

            const emsmetersData = emsmetersremovalprotection(project.meters, id, 'assets');
            if (emsmetersData.code) {
                setResult(emsmetersData.message);
                return
            }

            const accesscontrolData = accesscontrolremovalprotection(project.accesscontrols, id, 'assets');
            if (accesscontrolData.code) {
                setResult(accesscontrolData.message);
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEASSET, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove asset data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_ASSET',
                            assetid: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className="modal_actions">
                        {result && <div className='modal_result'>{result}</div>}
                        <div className="modal_buttons">
                            <div className='modal_buttoncontainer'>
                                <button className="modal_button" onClick={() => removeAsset(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            </div>
                            <div className='modal_buttoncontainer'>
                                <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewAsset({ asset }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [location, setLocation] = useState('');

        const printRef = useRef();

        useEffect(() => {
            setId(asset.id);
            setName(asset.name);
            setLocation(asset.location);
        }, [asset]);

        return (
            <div className='modal'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                        <div className='modal_rowsection'>{name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Location</div>
                        </div>
                        <div className='modal_rowsection'>{location}</div>
                    </div>
                </div>
                <div className="modal_action">
                    <div className="modal_buttons">
                        <div className="modal_buttoncontainer">
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className="modal_buttoncontainer">
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='assets'>
            <Title text='Assets' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Asset</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Location</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredassets.map((asset, index) => (
                            <tr key={'asset' + index}>
                                <td><div className='table_button' onClick={() => editButton(asset)}>Edit</div></td>
                                <td><div className='table_button' onClick={() => viewButton(asset)}>View</div></td>
                                <td><div>{asset.type}</div></td>
                                <td><div>{asset.name}</div></td>
                                <td><div>{asset.location}</div></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Assets;