import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import ReactToPrint from 'react-to-print';

import { getPermission } from './Utils/permissions.js';
import Title from './Title.js';
import Select from './Components/select.js';

import './AccessControls.css';

function AccessControls({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredcontrols, setFilteredcontrols] = useState([]);
    const [search, setSearch] = useState('');

    const assetTypes = [
        { value: "ble", label: "Bluetooth Low Energy" },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.accesscontrols.filter((accesscontrol) =>
            accesscontrol.id.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredcontrols(filtered);
    }, [project, search]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddAccessControl />, modaltype: 'small', modaltitle: 'Add Access Control' }));
    }

    const editButton = (accesscontrol) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditAccessControl accesscontrol={accesscontrol} />, modaltype: 'small', modaltitle: 'Edit Access Control' }));
    }

    const removeButton = (accesscontrol) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveAccessControl accesscontrol={accesscontrol} />, modaltype: 'small', modaltitle: 'Remove Access Control' }));
    }

    const viewButton = (accesscontrol) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewAccessControl accesscontrol={accesscontrol} />, modaltype: 'small', modaltitle: 'View Access Control' }));
    }

    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Import' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!project || !project.accesscontrols || project.accesscontrols.length === 0) {
                setResult('No data to export');
                return;
            }

            const importedContent = JSON.stringify(project.accesscontrols, null, 2);

            navigator.clipboard.writeText(importedContent)
                .then(() => setResult('Data copied to clipboard'))
                .catch(err => setResult('Error copying data to clipboard: ', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [newEntries, setNewEntries] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewEntries([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            if (isJSON) {
                console.log(data)
                const requiredAttributes = ["id", "assetid", "type", "uuid", "suuid", "cuuid"];
                const isValidData = data.every(entry =>
                    requiredAttributes.every(attr => entry.hasOwnProperty(attr))
                );

                if (isValidData) {
                    const invalidAssets = data.filter(entry => {
                        const assetExists = project.assets.some(asset => asset.id === entry.assetid);
                        return !assetExists;
                    });
                    if (invalidAssets.length > 0) {
                        const assetIdsNotFound = invalidAssets.map(entry => entry.assetid).join(', ');
                        setResult('Asset not found, id: ' + assetIdsNotFound);
                        return;
                    }

                    const newEntries = data.filter(entry => {
                        return !project.accesscontrols.some(ac => ac.id === entry.id);
                    });

                    if (newEntries.length > 0) {
                        setNewEntries(newEntries);
                        setResult('Data is valid. New entries found: ' + newEntries.length);
                    }
                    else {
                        setResult(data.length + " entries found. No new data.");
                    }
                }
                else {
                    setResult('Invalid data: Missing required attributes.');
                }
            }

            if (!isJSON) {
                setResult('Invalid format');
            }
        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');

            if (newEntries.length == 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true);
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                accesscontrols: newEntries
            };

            axios.post(configData.CONTROLLERURL + configData.IMPORTACCESSCONTROLS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import data received');
                console.log(res.data);
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_ACCESSCONTROLS',
                            accesscontrols: newEntries
                        });
                        handleCloseModal();
                    } else {
                        setResult(res.data.data);
                    }
                } else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

            setResult('Data imported successfully.');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>
                                {loading ? 'Loading...' : 'Import'}
                            </button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function AddAccessControl() {
        const [assetid, setAssetid] = useState('');
        const [type, setType] = useState('');
        const [uuid, setUuid] = useState('');
        const [suuid, setSuuid] = useState('');
        const [cuuid, setCuuid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            if (assetTypes.length > 0) {
                setType(assetTypes[0].value);
            }
        }, [assetTypes]);

        const addAccessControl = (assetid, type, uuid, suuid, cuuid) => {
            console.log('Trying to add access control');
            setResult(null)

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Access Contol', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!assetid || !type || !uuid) {
                setResult('Missing data');
                return;
            }

            const trimmedUUID = uuid.trim();
            const trimmedSUUID = suuid.trim();
            const trimmedCUUID = cuuid.trim();

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                assetid: assetid,
                type: type,
                uuid: trimmedUUID,
                suuid: trimmedSUUID,
                cuuid: trimmedCUUID
            }

            axios.post(configData.CONTROLLERURL + configData.ADDACCESSCONTROL, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add access control data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_ACCESSCONTROL',
                            accesscontrol: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }


        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <select
                                className='modal_select'
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                {assetTypes.map((asset, index) => (
                                    <option key={index} value={asset.value}>
                                        {asset.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Asset Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.assets} itemid={assetid} setItemid={setAssetid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>UUID</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="UUID"
                                value={uuid}
                                onChange={(e) => setUuid(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Service UUID</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Service UUID"
                                value={suuid}
                                onChange={(e) => setSuuid(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Characteristic UUID</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Characteristic UUID"
                                value={cuuid}
                                onChange={(e) => setCuuid(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addAccessControl(assetid, type, uuid, suuid, cuuid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditAccessControl({ accesscontrol }) {
        const [id, setId] = useState();
        const [assetid, setAssetid] = useState('');
        const [type, setType] = useState('');
        const [uuid, setUuid] = useState('');
        const [suuid, setSuuid] = useState('');
        const [cuuid, setCuuid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(accesscontrol.id)
            setAssetid(accesscontrol.assetid)
            setType(accesscontrol.type)
            setUuid(accesscontrol.uuid)
            setSuuid(accesscontrol.suuid)
            setCuuid(accesscontrol.cuuid)
        }, [accesscontrol]);

        const updateAccessControl = (id, assetid, type, uuid, suuid, cuuid) => {
            console.log('Trying to update access control');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Access Control', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!assetid, !type, !uuid) {
                setResult('Missing data');
                return;
            }

            const trimmedUUID = uuid.trim();
            const trimmedSUUID = suuid.trim();
            const trimmedCUUID = cuuid.trim();

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                assetid: assetid,
                type: type,
                uuid: trimmedUUID,
                suuid: trimmedSUUID,
                cuuid: trimmedCUUID
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEACCESSCONTROL, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update access control data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_ACCESSCONTROL',
                            accesscontrol: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <select
                                className='modal_select'
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                {assetTypes.map((asset, index) => (
                                    <option key={index} value={asset.value}>
                                        {asset.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Asset Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.assets} itemid={assetid} setItemid={setAssetid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>UUID</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="UUID"
                                value={uuid}
                                onChange={(e) => setUuid(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Service UUID</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Service UUID"
                                value={suuid}
                                onChange={(e) => setSuuid(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Characteristic UUID</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Characteristic UUID"
                                value={cuuid}
                                onChange={(e) => setCuuid(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateAccessControl(id, assetid, type, uuid, suuid, cuuid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(accesscontrol)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveAccessControl({ accesscontrol }) {
        const [id, setId] = useState(accesscontrol.id);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const removeAsset = (id) => {
            console.log('Trying to remove access control');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Access Control', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEACCESSCONTROL, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove access control data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_ACCESSCONTROL',
                            accesscontrolid: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeAsset(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewAccessControl({ accesscontrol }) {
        const [id, setId] = useState();
        const [assetid, setAssetid] = useState('');
        const [type, setType] = useState('');
        const [uuid, setUuid] = useState('');
        const [suuid, setSuuid] = useState('');
        const [cuuid, setCuuid] = useState('');

        useEffect(() => {
            setId(accesscontrol.id)
            setAssetid(accesscontrol.assetid)
            setType(accesscontrol.type)
            setUuid(accesscontrol.uuid)
            setSuuid(accesscontrol.suuid)
            setCuuid(accesscontrol.cuuid)
        }, [accesscontrol]);

        const printRef = useRef();

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Access Control Type</div>
                        </div>
                        <div className='modal_rowsection'>{assetTypes.find(asset => asset.value === type)?.label}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Asset Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.assets.find((asset) => asset.id == assetid)?.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>UUID</div>
                        </div>
                        <div className='modal_rowsection'>{uuid}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Service UUID</div>
                        </div>
                        <div className='modal_rowsection'>{suuid}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Characteristic UUID</div>
                        </div>
                        <div className='modal_rowsection'>{cuuid}</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='accesscontrol'>
            <Title text='Access Control' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Access Control</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>AssetName</th>
                        <th>Type</th>
                        <th>UUID</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredcontrols.map((accesscontrol, index) => (
                            <tr key={'accesscontrol' + index}>
                                <td><div className='table_button' onClick={() => editButton(accesscontrol)}>Edit</div></td>
                                <td><div className='table_button' onClick={() => viewButton(accesscontrol)}>View</div></td>
                                <td><div>{project.assets.find((asset) => asset.id === accesscontrol.assetid)?.name}</div></td>
                                <td><div>{accesscontrol.type}</div></td>
                                <td><div>{accesscontrol.uuid}</div></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default AccessControls;