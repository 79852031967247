import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';

function User({ state, setState }) {
    const [{ }, dispatch] = useStateValue();
    const [option, setOption] = useState(null);

    const [newusername, setNewusername] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [result, setResult] = useState('');

    useEffect(() => {
        setNewusername(state.user.username);
    }, [state.user]);

    const handleChangePassword = () => {
        console.log('Trying to change password');
        setResult(null)

        if (newPassword !== confirmPassword) {
            setResult('Passwords dont\'t match');
            return;
        }

        const data = {
            userid: state.user.userid,
            usertoken: state.user.usertoken,
            newpassword: newPassword
        }

        axios.post(configData.CONTROLLERURL + configData.CHANGEPASSWORD, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Change password data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setResult(res.data.data)
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error')
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        })
    };

    const handleChangeUsername = () => {
        console.log('Trying to change username');
        setResult(null)

        const data = {
            userid: state.user.userid,
            newusername: newusername,
        }

        axios.post(configData.CONTROLLERURL + configData.SAVEPROFILE, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Change username data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    dispatch({
                        type: 'ADD_USERNAME',
                        username: newusername
                    });
                    setResult(res.data.data)
                }
                else {
                    setResult(res.data)
                }
            }
            else {
                setResult('Error')
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        })
    };

    return (
        <div className='modal_body'>

            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <button className='modal_button' onClick={() => setOption('Change Password')}>Change Password</button>
                </div>
                <div className='modal_rowsection'>
                    <button className='modal_button' onClick={() => setOption('Change Username')}>Change Username</button>
                </div>
            </div>

            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <p className='label'>Id: {state.user.userid}</p>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <p className='label'>Username: {state.user.username}</p>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <p className='label'>Email: {state.user.useremail}</p>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <p className='label'>Subscription ID: {state.user.subscriptionid}</p>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <p className='label'>Subscription Plan: {state.user.subscriptionplan}</p>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <p className='label'>Subscription Start: {state.user.subscriptionstart}</p>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <p className='label'>Subscription End: {state.user.subscriptionend}</p>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <p className='label'>
                        Subscription Days Left: {
                            (() => {
                                const today = new Date();
                                const endDate = new Date(state.user.subscriptionend);
                                const timeDiff = endDate - today;
                                const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                                return daysLeft > 0 ? daysLeft : "Expired";
                            })()
                        }
                    </p>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <p className='label'>Subscription Max Users: {state.user.subscriptionmaxusers}</p>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <p className='label'>Subscription Max Projects: {state.user.subscriptionmaxprojects}</p>
                </div>
            </div>


            {option === 'Change Password' && (
                <div className='profile_password-inputs'>
                    <input
                        type='password'
                        placeholder='New Password'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className='input'
                    />
                    <input
                        type='password'
                        placeholder='Confirm Password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className='input'
                    />
                    <button onClick={handleChangePassword} className='button'>Save Password</button>
                </div>
            )}

            {option === 'Change Username' && (
                <div className='profile_password-inputs'>
                    <input
                        type='text'
                        placeholder='New Username'
                        value={newusername}
                        onChange={(e) => setNewusername(e.target.value)}
                        className='input'
                    />
                    <button onClick={handleChangeUsername} className='button'>Save Username</button>
                </div>
            )}

            {result && <div className='result'>{result}</div>}
        </div>
    );
}

export default User;