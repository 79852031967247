import moment from 'moment';

export function journalentryremovalprotection(journalentries, id, option) {
    let journalentry;

    switch (option) {
        case 'subaccount':
            journalentry = journalentries.find(journal => journal.subaccount == id);
            if (journalentry) {
                const date = moment.unix(journalentry.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Journal Entry found for this subaccount: ' + date + ' Journal Entry ID: ' + journalentry.id };
            } else {
                return { code: false, message: '' };
            }
        case 'chartofaccounts':
            journalentry = journalentries.find(journal => {
                return journal.rows.some(row => row.accountid === id);
            });
            if (journalentry) {
                const date = moment.unix(journalentry.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Journal Entry found for this account: ' + date + ', Journal Entry ID: ' + journalentry.id };
            } else {
                return { code: false, message: '' };
            }
        case 'clients':
            journalentry = journalentries.find(journal => {
                return journal.rows.some(row => row.accountid === id);
            });
            if (journalentry) {
                const date = moment.unix(journalentry.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Journal Entry found for this client: ' + date + ', Journal Entry ID: ' + journalentry.id };
            } else {
                return { code: false, message: '' };
            }
        case 'suppliers':
            journalentry = journalentries.find(journal => {
                return journal.rows.some(row => row.accountid === id);
            });
            if (journalentry) {
                const date = moment.unix(journalentry.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Journal Entry found for this supplier: ' + date + ', Journal Entry ID: ' + journalentry.id };
            } else {
                return { code: false, message: '' };
            }
        case 'currency':
            journalentry = journalentries.find(journal => journal.currencyid == id);
            if (journalentry) {
                const date = moment.unix(journalentry.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Journal Entry found for this currency: ' + date + ' Journal Entry ID: ' + journalentry.id };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function transactionsremovalprotection(transactions, id, option) {
    let transaction;

    switch (option) {
        case 'subaccount':
            transaction = transactions.find(transaction => transaction.subaccount == id);
            if (transaction) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Transaction found for this subaccount: ' + date + ' Transaction ID: ' + transaction.id };
            } else {
                return { code: false, message: '' };
            }
        case 'chartofaccounts':
            transaction = transactions.find(transaction => transaction.to == id);
            if (transaction) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Transaction found for this account: ' + date + ' Transaction ID: ' + transaction.id };
            } else {
                return { code: false, message: '' };
            }
        case 'cashaccounts':
            transaction = transactions.find(transaction => transaction.from === id || transaction.to === id);
            if (transaction) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Transaction found for this cashaccount: ' + date + ' Transaction ID: ' + transaction.id };
            } else {
                return { code: false, message: '' };
            }
        case 'clients':
            transaction = transactions.find(transaction => transaction.from == id);
            if (transaction) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Transaction found for this client: ' + date + ' Transaction ID: ' + transaction.id };
            } else {
                return { code: false, message: '' };
            }
        case 'suppliers':
            transaction = transactions.find(transaction => transaction.beneficiaryid == id);
            if (transaction) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Transaction found for this supplier: ' + date + ' Transaction ID: ' + transaction.id };
            } else {
                return { code: false, message: '' };
            }
        case 'currency':
            transaction = transactions.find(transaction => transaction.currencyid == id);
            if (transaction) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Transaction found for this currency: ' + date + ' Transaction ID: ' + transaction.id };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function inspectionsremovalprotection(inspections, id, option) {
    let inspection;

    switch (option) {
        case 'assets':
            inspection = inspections.find(inspection => inspection.assetid == id);
            if (inspection) {
                const date = moment.unix(inspection.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Inspection found for this asset: ' + date + ' Inspection ID: ' + inspection.id };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function reconciliationsremovalprotection(reconciliations, id, option) {
    let reconciliation;

    switch (option) {
        case 'cashaccounts':
            reconciliation = reconciliations.find(reconciliation => reconciliation.cashaccountid == id);
            if (reconciliation) {
                const date = moment.unix(reconciliation.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Reconciliation found for this cashaccount: ' + date + ' Reconciliation ID: ' + reconciliation.id };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function interaccounttransfersremovalprotection(interaccounttransfers, id, option) {
    let interaccounttransfer;

    switch (option) {
        case 'cashaccounts':
            interaccounttransfer = interaccounttransfers.find(transfer => transfer.from == id || transfer.to == id);
            if (interaccounttransfer) {
                const date = moment.unix(interaccounttransfer.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Interaccount transfer found for this cashaccount: ' + date + ' Reconciliation ID: ' + interaccounttransfer.id };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function emsreadingsremovalprotection(readings, id, option) {
    let reading;

    switch (option) {
        case 'clients':
            reading = readings.find(read => {
                return read.rows.some(row => row.clientid === id);
            });
            if (reading) {
                const date = moment.unix(reading.ts).format('D/MMM/YYYY');
                return { code: true, message: 'EMS Reading found for this client: ' + date + ', EMS Reading ID: ' + reading.id };
            } else {
                return { code: false, message: '' };
            }
        case 'assets':
            reading = readings.find(read => read.assetid == id);
            if (reading) {
                const date = moment.unix(reading.ts).format('D/MMM/YYYY');
                return { code: true, message: 'EMS Reading found for this asset: ' + date + ', EMS Reading ID: ' + reading.id };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function emstanksremovalprotection(tanks, id, option) {
    let tank;

    switch (option) {
        case 'assets':
            tank = tanks.find(tan => tan.assetid == id);
            if (tank) {
                return { code: true, message: 'EMS Tank found for this asset, EMS Tank ID: ' + tank.id };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function emsmetersremovalprotection(meters, id, option) {
    let meter;

    switch (option) {
        case 'assets':
            meter = meters.find(met => met.assetid == id);
            if (meter) {
                return { code: true, message: 'EMS Meter found for this asset, EMS Meter ID: ' + meter.id };
            } else {
                return { code: false, message: '' };
            }
        case 'clients':
            meter = meters.find(met => met.clientid === id);
            if (meter) {
                return { code: true, message: 'EMS Meter found for this client, EMS Meter ID: ' + meter.id };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function purchaseinvoicesremovalprotection(purchaseinvoices, id, option) {
    let purchaseinvoice;

    switch (option) {
        case 'suppliers':
            purchaseinvoice = purchaseinvoices.find(invoice => invoice.supplierid == id);
            if (purchaseinvoice) {
                const date = moment.unix(purchaseinvoice.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Purchase Invoice found for this supplier: ' + date + ', Purchase Invoice ID: ' + purchaseinvoice.id };
            } else {
                return { code: false, message: '' };
            }
        case 'currency':
            purchaseinvoice = purchaseinvoices.find(invoice => invoice.currencyid == id);
            if (purchaseinvoice) {
                const date = moment.unix(purchaseinvoice.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Purchase Invoice found for this currency: ' + date + ', Purchase Invoice ID: ' + purchaseinvoice.id };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function contractsremovalprotection(contracts, id, option) {
    let contract;

    switch (option) {
        case 'suppliers':
            contract = contracts.find(con => con.supplierid == id);
            if (contract) {
                return { code: true, message: 'Contract found for this supplier, Contract ID: ' + contract.id };
            } else {
                return { code: false, message: '' };
            }
        case 'currency':
            contract = contracts.find(con => con.currencyid == id);
            if (contract) {
                return { code: true, message: 'Contract found for this currency, Contract ID: ' + contract.id };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function cashaccountsremovalprotection(cashaccounts, id, option) {
    let cashaccount;

    switch (option) {
        case 'currency':
            cashaccount = cashaccounts.find(account => account.currencyid == id);
            if (cashaccount) {
                const name = cashaccount?.name;
                return { code: true, message: 'Cash account found for this currency, Cashaccount Name: ' + name };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function subaccountsremovalprotection(subaccounts, id, option) {
    let subaccount;

    switch (option) {
        case 'currency':
            subaccount = subaccounts.find(account => account.currencyid == id);
            if (subaccount) {
                const name = subaccount?.name;
                return { code: true, message: 'Subaccount found for this currency, Subaccount Name: ' + name };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function exchangeratesremovalprotection(exchangerates, id, option) {
    let exchangerate;

    switch (option) {
        case 'currency':
            exchangerate = exchangerates.find(rate => rate.fromcurrencyid == id || rate.tocurrencyid == id);
            if (exchangerate) {
                const date = moment.unix(exchangerate.ts).format('D/MMM/YYYY');
                return { code: true, message: 'Exchange rate found for this currency: ' + date + ' Exchage Rate ID: ' + exchangerate.id };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function incomeaccountsremovalprotection(incomeaccounts, id, option) {
    let incomeaccount;

    switch (option) {
        case 'currency':
            incomeaccount = incomeaccounts.find(account => account.currencyid == id);
            if (incomeaccount) {
                const name = incomeaccount?.name;
                return { code: true, message: 'Income account found for this currency, Income Account name: ' + name };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function expenseaccountsremovalprotection(expenseaccounts, id, option) {
    let expenseaccount;

    switch (option) {
        case 'currency':
            expenseaccount = expenseaccounts.find(account => account.currencyid == id);
            if (expenseaccount) {
                const name = expenseaccount?.name;
                return { code: true, message: 'Expense account found for this currency, Expense Account name: ' + name };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}

export function accesscontrolremovalprotection(accesscontrols, id, option) {
    let accesscontrol;

    switch (option) {
        case 'assets':
            accesscontrol = accesscontrols.find(ac => ac.id == id);
            if (accesscontrol) {
                const uuid = accesscontrol?.uuid;
                return { code: true, message: 'Access control device found, Access Control UUID: ' + uuid };
            } else {
                return { code: false, message: '' };
            }
        default:
            return { code: true, message: 'Data not found' }
    }
}