import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { isValidNumber, hasMoreThanTwoDecimals, parseAmount } from './Utils/textparse.js';

import { getPermission } from './Utils/permissions.js';
import Select from './Components/select.js';

import Title from './Title';

import './PurchaseInvoices.css';

function PurchaseInvoices({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredpurchaseinvoices, setFilteredPurchaseInvoices] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.purchaseinvoices.filter((invoice) =>
            invoice.name.toLowerCase().includes(search.toLowerCase()) ||
            invoice.id.includes(search) ||
            invoice.amount.includes(search)
        );

        setFilteredPurchaseInvoices(filtered);
    }, [project, search]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddPurchaseInvoice />, modaltype: 'large', modaltitle: 'Add Purchase Invoice' }));
    }

    const editButton = (purchaseinvoice) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditPurchaseInvoice purchaseinvoice={purchaseinvoice} mode='edit' />, modaltype: 'large', modaltitle: 'Edit Purchase Invoice' }));
    }

    const viewButton = (purchaseinvoice) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewPurchaseInvoice purchaseinvoice={purchaseinvoice} />, modaltype: 'large', modaltitle: 'View Purchase Invoice' }));
    }

    const cloneButton = (purchaseinvoice) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditPurchaseInvoice purchaseinvoice={purchaseinvoice} mode='clone' />, modaltype: 'large', modaltitle: 'Clone Purchase Invoice' }));
    }

    const removeButton = (purchaseinvoice) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemovePurchaseInvoice purchaseinvoice={purchaseinvoice} />, modaltype: 'small', modaltitle: 'Remove Purchase Invoice' }));
    }
    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!project || !project.purchaseinvoices || project.purchaseinvoices.length === 0) {
                setResult('No data to export');
                return;
            }

            const importedContent = JSON.stringify(project.purchaseinvoices, null, 2);

            navigator.clipboard.writeText(importedContent)
                .then(() => setResult('Data copied to clipboard.'))
                .catch(err => setResult('Error copying data to clipboard:', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [newEntries, setNewEntries] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewEntries([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            if (isJSON) {
                setResult('Invalid format')
            }

            if (!isJSON) {
                const requiredAttributes = ["Date", "Supplier", "Name", "Subaccount", "Amount"];
                let rows = importText.split('\n').map(row => row.split('\t'));
                const headers = rows.shift().map(header => header.trim());

                const missingHeaders = requiredAttributes.filter(attr => !headers.includes(attr));

                if (headers.length === 0 || headers.length !== requiredAttributes.length || missingHeaders.length > 0) {
                    setResult(`Invalid headers detected. Missing headers: ${missingHeaders.join(', ')}`);
                    return;
                }

                if (rows.length === 0) {
                    setResult('No data rows detected.');
                    return;
                }

                data = rows.map(row => {
                    let entry = {};
                    headers.forEach((header, index) => {
                        entry[header] = row[index]?.trim() || '';
                    });
                    return entry;
                });

                const convertedEntries = [];

                for (let i = 0; i < data.length; i++) {
                    const entry = data[i];
                    let convertedEntry = {};

                    const matchingSupplier = project.suppliers.find(supplier => supplier.name.toLowerCase() === entry['Supplier'].toLowerCase());
                    if (!matchingSupplier) {
                        setResult("Supplier not found for \"" + entry['Supplier'] + "\" at row " + i);
                        break;
                    }

                    const matchingSubaccount = project.subaccounts.find(subaccount => subaccount.name.toLowerCase() === entry['Subaccount'].toLowerCase());
                    if (!matchingSubaccount) {
                        setResult("Subaccount not found for \"" + entry['Subaccount'] + "\" at row " + i);
                        break;
                    }

                    const parsedAmount = parseAmount(entry['Amount']);
                    if (!parsedAmount.currency) {
                        console.log("Currency not found in amount:", parsedAmount.amount);
                        break;
                    }
                    const matchingCurrency = project.currencies.find(currency =>
                        currency.name.toLowerCase() === parsedAmount.currency.toLowerCase() ||
                        currency.symbol.toLowerCase() === parsedAmount.currency.toLowerCase()
                    );
                    if (!matchingCurrency) {
                        setResult("Currency not found for \"" + parsedAmount.currency + "\" at row " + i);
                        break;
                    }

                    convertedEntry = {
                        ts: Math.floor(new Date(entry['Date']).getTime() / 1000),
                        supplierid: matchingSupplier.id,
                        name: entry['Name'],
                        subaccount: matchingSubaccount.id,
                        amount: parsedAmount.amount,
                        currencyid: matchingCurrency.id
                    };
                    convertedEntries.push(convertedEntry);
                }
                console.log(convertedEntries)
                if (convertedEntries.length === data.length) {
                    setNewEntries(convertedEntries);
                    setResult(`Data is valid. Entries found: ${convertedEntries.length}`);
                }
            }
        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');
            
            if (newEntries.length == 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true);
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                purchaseinvoices: newEntries
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTPURCHASEINVOICES, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_PURCHASEINVOICES',
                            purchaseinvoices: newEntries
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

            setResult('Data imported successfully.');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function NewItem({ item, index, handleNameChange, handleQuantityChange, handleCostChange, handleRemoveItem }) {
        let itemNet = item.itemquantity * item.itemcost
        if (hasMoreThanTwoDecimals(itemNet)) {
            itemNet = parseFloat(itemNet.toFixed(2));
        }

        return (
            <tr>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        value={item.itemname}
                        onChange={(e) => handleNameChange(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="number"
                        value={item.itemquantity}
                        onChange={(e) => handleQuantityChange(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="number"
                        value={item.itemcost}
                        onChange={(e) => handleCostChange(e, index)}
                    />
                </td>
                <td>
                    {itemNet.toLocaleString('en-US')}
                </td>
                <td>
                    <div className='modal_removeitembutton' onClick={() => handleRemoveItem(index)}>&minus;</div>
                </td>
            </tr>
        );
    }

    function AddPurchaseInvoice() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');

        const [currencies, setCurrencies] = useState([]);
        const [currencyid, setCurrencyid] = useState('');

        const [name, setName] = useState('');

        const [items, setItems] = useState([{ itemname: '', itemquantity: 0, itemcost: 0 }]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setSuppliers(project.suppliers);
            setCurrencies(project.currencies);
        }, [project]);

        const handleAddItem = () => {
            setItems([...items, { itemname: '', itemquantity: 0, itemcost: 0 }])
        };

        const handleNameChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemname = selectedItem;
            setItems(updatedItems);
        };

        const handleQuantityChange = (event, index) => {
            const selectedItem = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedItems = [...items];
            updatedItems[index].itemquantity = selectedItem;
            setItems(updatedItems);
        };

        const handleCostChange = (event, index) => {
            const selectedItem = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedItems = [...items];
            updatedItems[index].itemcost = selectedItem;
            setItems(updatedItems);
        };

        const handleRemoveItem = (indexToRemove) => {
            if (items.length > 1) {
                const updateItems = items.filter((_, index) => index !== indexToRemove);
                setItems(updateItems);
            }
        };

        const totalAmount = items.reduce((total, item) => total + (item.itemquantity || 0) * (item.itemcost || 0), 0);

        const addPurchaseinvoice = (timestamp, supplierid, subaccount, currencyid, name, items, amount) => {
            console.log('Trying to add purchase invoice');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Purchase Invoices', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !supplierid || !currencyid || !subaccount) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                supplierid: supplierid,
                subaccount: subaccount,
                currencyid: currencyid,
                name: trimmedname,
                items: items,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.ADDPURCHASEINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add purchase invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_PURCHASEINVOICE',
                            purchaseinvoice: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        let totalNet = items.reduce((sum, item) => sum + (item.itemquantity * item.itemcost || 0), 0);
        if (hasMoreThanTwoDecimals(totalNet)) {
            totalNet = parseFloat(totalNet.toFixed(2));
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Supplier</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <Select data={suppliers} itemid={supplierid} setItemid={setSupplierid} />
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <Select data={project.subaccounts} itemid={selectedsubaccount} setItemid={setSelectedSubaccount} />
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <input
                            className='modal_input'
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <table className='modal_table'>
                        <thead><tr><th>Item</th><th>Quantity</th><th>Cost</th><th>Net</th><th></th></tr></thead>
                        <tbody>
                            {items.map((item, index) => (
                                <NewItem
                                    key={'item' + index}
                                    item={item}
                                    index={index}
                                    handleNameChange={handleNameChange}
                                    handleQuantityChange={handleQuantityChange}
                                    handleCostChange={handleCostChange}
                                    handleRemoveItem={handleRemoveItem}
                                />
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td><div>Total</div></td>
                                <td></td>
                                <td></td>
                                <td><div>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {totalNet.toLocaleString('en-US')}</div></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                    <div className="modal_actions">
                        <div className='modal_buttons'>
                            <div className='modal_buttoncontainer'>
                                <div className='modal_button' onClick={handleAddItem}>Add item</div>
                            </div>
                            <div className='modal_buttoncontainer'></div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addPurchaseinvoice(timestamp, supplierid, selectedsubaccount, currencyid, name, items, totalAmount)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditPurchaseInvoice({ purchaseinvoice, mode }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');

        const [currencies, setCurrencies] = useState([]);
        const [currencyid, setCurrencyid] = useState('');

        const [name, setName] = useState('');

        const [items, setItems] = useState([{ itemname: '', itemquantity: 0, itemcost: 0 }]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            if (purchaseinvoice) {
                const purchaseInvoiceCopy = {
                    ...purchaseinvoice,
                    items: Array.isArray(purchaseinvoice.items) ? purchaseinvoice.items.map(item => ({ ...item })) : []
                };

                setId(purchaseInvoiceCopy.id);
                setSelecteddate(new Date(purchaseInvoiceCopy.ts * 1000));
                setSuppliers(project.suppliers);
                setSupplierid(purchaseInvoiceCopy.supplierid);
                setSelectedSubaccount(purchaseInvoiceCopy.subaccount);
                setCurrencies(project.currencies);
                setCurrencyid(purchaseInvoiceCopy.currencyid);
                setName(purchaseInvoiceCopy.name);
                setItems(purchaseInvoiceCopy.items);
            }
        }, [purchaseinvoice]);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const handleAddItem = () => {
            setItems([...items, { itemname: '', itemquantity: 0, itemcost: 0 }])
        };

        const handleNameChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemname = selectedItem;
            setItems(updatedItems);
        };

        const handleQuantityChange = (event, index) => {
            const selectedItem = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedItems = [...items];
            updatedItems[index].itemquantity = selectedItem;
            setItems(updatedItems);
        };

        const handleCostChange = (event, index) => {
            const selectedItem = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedItems = [...items];
            updatedItems[index].itemcost = selectedItem;
            setItems(updatedItems);
        };

        const handleRemoveItem = (indexToRemove) => {
            if (items.length > 1) {
                const updateItems = items.filter((_, index) => index !== indexToRemove);
                setItems(updateItems);
            }
        };

        const updatePurchaseInvoice = (id, timestamp, supplierid, subaccount, currencyid, name, items, amount) => {
            console.log('Trying to update purcahse invoice');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Purchase Invoices', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !supplierid || !currencyid || !subaccount) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: timestamp,
                supplierid: supplierid,
                subaccount: subaccount,
                currencyid: currencyid,
                name: trimmedname,
                items: items,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEPURCHASEINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update purchase invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_PURCHASEINVOICE',
                            purchaseinvoice: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const addPurchaseinvoice = (timestamp, supplierid, subaccount, currencyid, name, items, amount) => {
            console.log('Trying to add purchase invoice');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Purchase Invoices', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !supplierid || !currencyid || !subaccount) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                supplierid: supplierid,
                subaccount: subaccount,
                currencyid: currencyid,
                name: trimmedname,
                items: items,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.ADDPURCHASEINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add purchase invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_PURCHASEINVOICE',
                            purchaseinvoice: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        let totalNet = items.reduce((sum, item) => sum + (item.itemquantity * item.itemcost || 0), 0);
        if (hasMoreThanTwoDecimals(totalNet)) {
            totalNet = parseFloat(totalNet.toFixed(2));
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Supplier</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <Select data={suppliers} itemid={supplierid} setItemid={setSupplierid} />
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <Select data={project.subaccounts} itemid={selectedsubaccount} setItemid={setSelectedSubaccount} />
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <input
                            className='modal_input'
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <table className='modal_table'>
                        <thead><tr><th>Item</th><th>Quantity</th><th>Cost</th><th>Net</th><th></th></tr></thead>
                        <tbody>
                            {
                                items.map((item, index) => (
                                    <NewItem
                                        key={'item' + index}
                                        item={item}
                                        index={index}
                                        handleNameChange={handleNameChange}
                                        handleQuantityChange={handleQuantityChange}
                                        handleCostChange={handleCostChange}
                                        handleRemoveItem={handleRemoveItem}
                                    />
                                ))
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td><div>Total</div></td>
                                <td></td>
                                <td></td>
                                <td><div>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {totalNet.toLocaleString('en-US')}</div></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                    <div className="modal_actions">
                        <div className='modal_buttons'>
                            <div className='modal_buttoncontainer'>
                                <div className='modal_button' onClick={handleAddItem}>Add item</div>
                            </div>
                            <div className='modal_buttoncontainer'></div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            {mode === 'edit' && <button className="modal_button" onClick={() => updatePurchaseInvoice(id, timestamp, supplierid, selectedsubaccount, currencyid, name, items, totalNet)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>}
                            {mode === 'clone' && <button className="modal_button" onClick={() => addPurchaseinvoice(timestamp, supplierid, selectedsubaccount, currencyid, name, items, totalNet)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>}
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(purchaseinvoice)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemovePurchaseInvoice({ purchaseinvoice }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(purchaseinvoice.id)
        }, [purchaseinvoice]);

        const removePurchaseInvoice = (id) => {
            console.log('Trying to remove purchase invoice');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Purchase Invoices', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEPURCHASEINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove purchase invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_PURCHASEINVOICE',
                            purchaseinvoiceid: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removePurchaseInvoice(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewPurchaseInvoice({ purchaseinvoice }) {
        const [id, setId] = useState('');
        const [timestamp, setTimestamp] = useState(0);

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');
        const [subaccounts, setSubaccounts] = useState([]);
        const [subaccount, setSubaccount] = useState('');

        const [currencies, setCurrencies] = useState([]);
        const [currencyid, setCurrencyid] = useState('');

        const [name, setName] = useState('');

        const [items, setItems] = useState([]);

        const printRef = useRef();

        useEffect(() => {
            setId(purchaseinvoice.id);
            setTimestamp(purchaseinvoice.ts);
            setSuppliers(project.suppliers);
            setSupplierid(purchaseinvoice.supplierid);
            setSubaccounts(project.subaccounts)
            setSubaccount(purchaseinvoice.subaccount)
            setCurrencies(project.currencies);
            setCurrencyid(purchaseinvoice.currencyid);
            setName(purchaseinvoice.name);
            setItems(purchaseinvoice.items);
        }, [purchaseinvoice]);

        let totalNet = items.reduce((sum, item) => sum + (item.itemquantity * item.itemcost || 0), 0);
        if (hasMoreThanTwoDecimals(totalNet)) {
            totalNet = parseFloat(totalNet.toFixed(2));
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(timestamp).format('DD MMMM YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Supplier</div>
                        </div>
                        <div className='modal_rowsection'>{suppliers.find(supplier => supplier.id === supplierid)?.name || 'Supplier not found'}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                        <div className='modal_rowsection'>{subaccounts.find(sub => sub.id === subaccount)?.name || 'Subaccount not found'}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                        <div className='modal_rowsection'>
                            <div className='modal_rowsection'>{name}</div>
                        </div>
                    </div>
                    <table className='modal_table'>
                        <thead><tr><th>Item</th><th>Quantity</th><th>Cost</th><th>Net</th></tr></thead>
                        <tbody>
                            {
                                items.map((item, index) => {
                                    let itemNet = item.itemquantity * item.itemcost
                                    if (hasMoreThanTwoDecimals(itemNet)) {
                                        itemNet = parseFloat(itemNet.toFixed(2));
                                    }
                                    return (
                                        <tr key={'item' + index}>
                                            <td>{item.itemname}</td>
                                            <td>{item.itemquantity}</td>
                                            <td>{currencies.find(item => item.id === currencyid, 10)?.symbol + ' ' + parseFloat(item.itemcost).toLocaleString('en-US')}</td>
                                            <td>{currencies.find(item => item.id === currencyid, 10)?.symbol + ' ' + itemNet.toLocaleString('en-US')}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {totalNet.toLocaleString('en-US')}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => cloneButton(purchaseinvoice)}>Clone</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='purchaseinvoices'>
            <Title text='Purchase Invoices' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Purchase Invoice</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>ID</th><th>Supplier</th><th>Name</th><th>Amount</th></tr></thead>
                <tbody>
                    {
                        filteredpurchaseinvoices.sort((a, b) => b.ts - a.ts).map((purchaseinvoice, index) => {
                            let supplierName = project.suppliers.find(supplier => supplier.id === purchaseinvoice.supplierid)?.name || '';
                            const currency = project.currencies.find(item => item.id === purchaseinvoice.currencyid, 10)?.symbol || '';
                            const amount = parseFloat(purchaseinvoice.amount).toLocaleString('en-US');

                            return (
                                <tr key={'purchaseinvoice' + index}>
                                    <td><div className='table_button' onClick={() => editButton(purchaseinvoice, index)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(purchaseinvoice, index)}>View</div></td>
                                    <td><div>{moment.unix(purchaseinvoice.ts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{purchaseinvoice.id}</div></td>
                                    <td><div>{supplierName}</div></td>
                                    <td><div>{purchaseinvoice.name}</div></td>
                                    <td><div>{currency} {amount}</div></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default PurchaseInvoices;